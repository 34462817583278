export function actionTypesFor(entity: string): {
  filter: () => { type: string },
  filterDone: (payload) => { type: string, payload: any },
  filterError: () => { type: string },
  save: (payload) => { type: string, payload: any },
  saveDone: () => { type: string },
  saveError: () => { type: string },
  get: (payload) => { type: string, payload: string },
  getDone: (payload) => { type: string, payload: any },
  set: (payload) => { type: string, payload: any },
  delete: (payload) => { type: string, payload: any },
  deleteDone: () => { type: string },
  deleteError: () => { type: string },
  setFilter: (payload) => { type: string, payload: any }
} {
  return {
    filter: () => ({ type: 'FILTER_' + entity.toUpperCase() }),
    filterDone: payload => ({ type: 'FILTER_' + entity.toUpperCase() + '_DONE', payload }),
    filterError: () => ({ type: 'FILTER_' + entity.toUpperCase() + '_ERROR' }),
    save: payload => ({ type: 'SAVE_' + entity.toUpperCase(), payload }),
    saveDone: () => ({ type: 'SAVE_' + entity.toUpperCase() + '_DONE' }),
    saveError: () => ({ type: 'SAVE_' + entity.toUpperCase() + '_ERROR' }),
    get: payload => ({ type: 'GET_' + entity.toUpperCase(), payload }),
    getDone: payload => ({ type: 'GET_' + entity.toUpperCase() + '_DONE', payload }) ,
    set: payload => ({ type: 'SET_' + entity.toUpperCase(), payload }),
    delete: payload => ({ type: 'DELETE_' + entity.toUpperCase(), payload }),
    deleteDone: () => ({ type: 'DELETE_' + entity.toUpperCase() + '_DONE' }),
    deleteError: () => ({ type: 'DELETE_' + entity.toUpperCase() + '_ERROR' }),
    setFilter: payload => ({ type: 'SET_' + entity.toUpperCase() + '_FILTER', payload })
  };
}


