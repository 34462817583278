<div class="dialog">
  <h2>{{ title }}</h2>
  <table class="full-width">
    <thead>
      <tr>
        <th>Meldevariable</th>
        <th>Wert</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let key of objKeys(previewList$ | async)">
        <td>{{ key }}</td>
        <td>{{ (previewList$ | async)[key] }}</td>
        <mat-divider></mat-divider>
      </tr>
    </tbody>
  </table>

  <div mat-dialog-actions class="button-container pull-right sticky-buttons">
    <button mat-stroked-button (click)="dialogRef.close()" tabindex="1" color="accent">
      Schließen
    </button>
  </div>
</div>
