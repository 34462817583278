import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Invalidate } from 'app/login/action/login.actions';
import { selectAccessToken } from 'app/login/reducers/login.reducer';
import { NotifyService } from 'app/notify/notify.service';
import { State } from 'app/reducers';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { selectUpdating } from 'app/update/reducers/update.reducer';

/**
 * `HttpInterceptor` für das Fehlerhandling.
 */
@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {

  updating$: Observable<boolean>;

  constructor(
    private notify: NotifyService,
    private router: Router,
    private store: Store<State>
  ) {
    this.updating$ = this.store.select(selectUpdating);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
    | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

    return next.handle(request).pipe(
      catchError (err => {
        if (!err) {
          return;
        }

        if (typeof err.error === 'string'
          && (err.error.startsWith('Response status 400 with reason') || err.error.startsWith('400 BAD_REQUEST'))
        ) {
          this.notify.showNotification(err.error.match('\"(.*)\"')[1], 'notify-danger');
          return throwError(err);
        }

        if (!(err instanceof HttpErrorResponse)) {
          this.notify.showNotification('Es ist ein unbekannter Fehler aufgetreten.', 'notify-danger');
        }

        switch ((<HttpErrorResponse>err).status) {
          case 400:
            this.store.select(selectAccessToken)
              .pipe(
                take(1)
              ).subscribe(token => {
                // Fehler nur anzeigen, wenn man eingeloggt ist
                // Ansonsten handelt es sich um falsche Login-Daten
                if (token) {
                  this.notify.showNotification('Die Anfrage war ungültig.', 'notify-danger');
                }
              });
            break;
          case 401:
            this.store.select(selectAccessToken)
              .pipe(
                take(1)
              ).subscribe(token => {
                let msg = '';
                if (token) {
                  msg = 'Ihre Sitzung ist abgelaufen.';
                  this.store.dispatch(new Invalidate());
                } else if (err.error.error === 'unauthorized_client') {
                  msg = 'Sie sind nicht autorisiert.';
                } else {
                  msg = 'Sie müssen sich zuerst einloggen.';
                  this.store.dispatch(new Invalidate());
                }
                this.notify.showNotification(msg, 'notify-danger');
                this.router.navigate(['/login']);
              });
            break;
          case 403:
            this.notify.showNotification('Sie besitzen nicht die notwendigen Rechte.',
              'notify-danger'
            );
            break;
          case 404:
            this.notify.showNotification('Das Ziel wurde nicht gefunden.',
              'notify-danger'
            );
            break;
          case 500:
            this.notify.showNotification('Es ist ein interner Serverfehler aufgetreten.',
              'notify-danger'
            );
            break;
          default:
            this.updating$.pipe(take(1)).subscribe(isUpdating => {
              isUpdating
                ? console.log('Fehlermeldung unterdrückt, da die Anwendung gerade aktualisiert wird.')
                : this.notify.showNotification('Es ist ein unbekannter Fehler aufgetreten.', 'notify-danger')
              ;
            });
        }

        return throwError(err);
      })
    );
  }
}
