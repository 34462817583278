import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { FormArrayHelperService } from 'app/shared/formArrayHelper.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { MatSelectHelperService } from '../../shared/matSelectHelper.service';
import { usersSelectors } from '../reducers/users.reducer';
import { UserRoles, Users } from '../users';
import { CustomSaveUser, usersActions } from '../actions/users.actions';

const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-zA-ZäöüßÄÖÜ]).{8,}$/;

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss']
})
export class UsersFormComponent implements OnInit {

  form: FormGroup;
  user$: Observable<Users>;
  curUserObject: Users;
  filter: any;
  roles = UserRoles;
  objKeys = Object.keys;
  objValues = Object.values;

  constructor(
    public store: Store<State>,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    public matSelectHelper: MatSelectHelperService,
    public formHelper: FormArrayHelperService
  ) {
    this.form = this.fb.group({
      id: '',
      firstname: '',
      lastname: '',
      email: ['', Validators.email],
      username: ['', Validators.required],
      hashedClientPassword: '',
      roles: '',
      plainPassword: '',
    });

    this.route.params
      .pipe(
        tap(params => this.filter = params),
        filter(params => params.id),
        map(params => params.id),
        distinctUntilChanged()
      )
      .subscribe(id => this.store.dispatch(usersActions.get(id)));

    this.route.params
      .pipe(
        filter(params => !params.id),
        distinctUntilChanged()
      )
      .subscribe(() => this.store.dispatch(usersActions.set({})));

    this.user$ = this.store.select(usersSelectors.selectCurrent);

    this.user$.subscribe(data => {
      if (data) {
        this.form.patchValue(data);
        this.curUserObject = _.cloneDeep(data);
        if (this.isNewEntry()) {
          this.setPasswordRequiredPattern();
        } else {
          this.attachValidatorToPasswordControl(null);
        }
      }
    });
  }

  ngOnInit() {
    if (this.filter.id) {
      this.form.controls.username.disable();
    }
  }

  onSave() {
    this.store.dispatch(new CustomSaveUser({ ...this.curUserObject, ...this.form.value }, false));
  }

  isNewEntry(): boolean {
    return !!this.curUserObject && !this.curUserObject.id;
  }

  onPasswordChange(evt: any): void {
    if (this.isNewEntry()) {
      return;
    }

    if (evt.target.value.length > 0) {
      this.setPasswordPattern();
    } else {
      this.attachValidatorToPasswordControl(null);
    }
  }

  setPasswordRequiredPattern(): void {
    this.attachValidatorToPasswordControl([
      Validators.required,
      Validators.pattern(PASSWORD_PATTERN)
    ]);
  }

  setPasswordPattern(): void {
    this.attachValidatorToPasswordControl([
      Validators.pattern(PASSWORD_PATTERN)
    ]);
  }

  attachValidatorToPasswordControl(newValidator: ValidatorFn | ValidatorFn[]): void {
    const control = this.form.get('plainPassword');
    control.clearValidators();
    if (!!newValidator) {
      control.setValidators(newValidator);
    }
    control.updateValueAndValidity();
  }
}
