import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CrudHelperService } from '@kdo/ng-crud';
import { NotifyService } from 'app/notify/notify.service';
import {
  CHECK_FOR_UPDATE,
  CheckForUpdate,
  CheckForUpdateDone,
  CheckForUpdateError,
  CHECK_FOR_UPDATE_DONE,
  TriggerUpdate,
  TRIGGER_UPDATE,
  TriggerUpdateDone,
  TriggerUpdateError,
  FetchServerStatus,
  FETCH_SERVER_STATUS,
  FetchServerStatusSuccess,
  TRIGGER_UPDATE_DONE,
  TRIGGER_UPDATE_ERROR,
  FETCH_SERVER_STATUS_SUCCESS,
} from '../actions/update.actions';
import { switchMap, map, catchError, tap, filter, concatMap, withLatestFrom, delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { State } from 'app/reducers';
import { Store } from '@ngrx/store';
import { selectLoggedIn, selectAuthorities } from 'app/login/reducers/login.reducer';
import { selectUpdating } from '../reducers/update.reducer';
import { UpdateService } from '../update.service';
import { Invalidate } from 'app/login/action/login.actions';

@Injectable()
export class UpdateEffects {
  constructor(
    private helper: CrudHelperService,
    private actions$: Actions,
    private notify: NotifyService,
    private router: Router,
    private store: Store<State>,
    private update: UpdateService,
  ) { }

  readonly checkForUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<CheckForUpdate>(CHECK_FOR_UPDATE),
    withLatestFrom(this.store.select(selectLoggedIn), this.store.select(selectUpdating), this.store.select(selectAuthorities)),
    filter(hasAuths => hasAuths[3].includes('ADMIN_BEREICH_LOKAL')),
    map(conditions => conditions[1] && !conditions[2]),
    switchMap(loggedIn => loggedIn ? this.helper.get<any>('updates/check')
      .pipe(
        map(payload => new CheckForUpdateDone({ updatesPresent: payload.updatesPresent })),
        catchError(() => of(new CheckForUpdateError()))
      ) : of(new CheckForUpdateError())
    ))
  );

  readonly checkForUpdateDone$ = createEffect(() => this.actions$.pipe(
    ofType<CheckForUpdateDone>(CHECK_FOR_UPDATE_DONE),
    filter(res => res.payload.updatesPresent),
    tap(() => {
      this.notify.showNotification('Es ist ein neues Update verfügbar', '', 'Jetzt aktualisieren', 60000)
        .onAction().subscribe(() => this.router.navigate(['update']));
    }),
  ), { dispatch: false });

  readonly triggerUpdate$ = createEffect(() => this.actions$.pipe(
    ofType<TriggerUpdate>(TRIGGER_UPDATE),
    concatMap(() => this.helper.save('updates/update', {})
      .pipe(
        map(() => new TriggerUpdateDone()),
        catchError(err => of(new TriggerUpdateError(err)))
      )
    ))
  );

  readonly triggerUpdateDone$ = createEffect(() => this.actions$.pipe(
    ofType<TriggerUpdateDone>(TRIGGER_UPDATE_DONE),
    tap(() => this.notify.showNotification('Update erfolgreich gestartet')),
    delay(5000),
    map(() => new FetchServerStatus())
  ));

  readonly triggerUpdateError$ = createEffect(() => this.actions$.pipe(
    ofType<TriggerUpdateError>(TRIGGER_UPDATE_ERROR),
    tap(() => this.notify.showNotification('Es ist ein Fehler beim Starten des Updates aufgetreten', 'notify-danger'))
  ), { dispatch: false });

  readonly fetchServerStatus$ = createEffect(() => this.actions$.pipe(
    ofType<FetchServerStatus>(FETCH_SERVER_STATUS),
    delay(5000),
    switchMap(() => this.update.pingServer()
      .pipe(
        map(() => new FetchServerStatusSuccess()),
        catchError(() => of(new FetchServerStatus()))
      )
    )
  ));

  readonly fetchServerStatusSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<FetchServerStatusSuccess>(FETCH_SERVER_STATUS_SUCCESS),
    tap(() => this.notify.showNotification('Die Anwendung wurde erfolgreich aktualisiert')),
    tap(() => this.router.navigate(['/'])),
    map(() => new Invalidate()),
    tap(() => location.reload())
  ));

}
