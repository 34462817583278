<mat-sidenav-container>
  <mat-sidenav
    #sidenav
    [mode]="mode"
    [opened]="opened"
    *ngIf="loggedIn$ | async"
  >
    <mat-nav-list role="navigation">
      <a mat-list-item
        routerLinkActive="active"
        routerLink="/startseite"
        (click)="isSmallScreen() ? toggle() : false"
      >
        <mat-icon mat-list-icon class="material-icons">home</mat-icon>
        <h3 mat-line>Startseite</h3>
      </a>
      <div *ngIf="(authorities$ | async)?.includes('ERFASSUNG')">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
            <mat-panel-title>
              Anträge
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a mat-list-item
            routerLinkActive="active"
            routerLink="/antrag"
            (click)="isSmallScreen() ? toggle() : false"
          >
            <mat-icon mat-list-icon class="material-icons">inbox</mat-icon>
            <h3 mat-line>Neuer Antrag</h3>
          </a>
        </mat-expansion-panel>
      </div>

      <div *ngIf="(authorities$ | async)?.includes('DATEN_UEBERMITTELN')">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
            <mat-panel-title>
              Datendienste
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a mat-list-item
            routerLinkActive="active"
            routerLink="/import"
            (click)="isSmallScreen() ? toggle() : false"
          >
            <mat-icon mat-list-icon class="material-icons">import_export</mat-icon>
            <h3 mat-line>Import / Export</h3>
          </a>

          <a mat-list-item
            routerLinkActive="active"
            routerLink="/meldedaten"
            (click)="isSmallScreen() ? toggle() : false"
          >
            <mat-icon mat-list-icon class="material-icons">assignment</mat-icon>
            <h3 mat-line>Meldedaten</h3>
          </a>
        </mat-expansion-panel>
      </div>

      <div *ngIf="(authorities$ | async)?.includes('ADMIN_BEREICH_LOKAL')">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
            <mat-panel-title>
              Einstellungen
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngIf="(authorities$ | async)?.includes('BENUTZER_VERWALTEN')">
            <a mat-list-item
              routerLink="/benutzer"
              routerLinkActive="active"
              (click)="isSmallScreen() ? toggle() : false"
            >
              <mat-icon mat-list-icon class="material-icons">person</mat-icon>
              <h3 mat-line>Benutzer</h3>
            </a>
          </div>

          <a mat-list-item
            routerLinkActive="active"
            routerLink="/konfiguration"
            (click)="isSmallScreen() ? toggle() : false"
          >
            <mat-icon mat-list-icon class="material-icons">settings</mat-icon>
            <h3 mat-line>Konfiguration</h3>
          </a>

          <a mat-list-item
            routerLinkActive="active"
            routerLink="/traeger"
            (click)="isSmallScreen() ? toggle() : false"
          >
            <mat-icon mat-list-icon class="material-icons">group</mat-icon>
            <h3 mat-line>Träger-ID</h3>
          </a>
        </mat-expansion-panel>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <ng-content></ng-content>

</mat-sidenav-container>
