<div fxLayout="row" fxLayout.xs="column">
  <div fxFlex="33%" class="form-hint-area">
    <h3 class="form-title">Allgemeine Daten</h3>
    <span class="form-hint" *ngIf="antraege.length > 0">
      Zu diesem THP gehörende Leistungsanträge
      <ul>
        <li *ngFor="let antrag of form.getRawValue().antraege">
          {{ antrag.thvbID }}
        </li>
      </ul>
    </span>
    <div class="form-info-box" [hidden]="currentInfoText === ''" [innerHTML]="currentInfoText"></div>
  </div>
  <div fxFlex="66%" fxLayout="column">
    <form [formGroup]="form" [ngClass]="isInPopup ? '' : 'form-container'">
      <mat-form-field
        class="full-width"
        appearance="fill"
      >
        <input matInput
          id="thvbID"
          formControlName="thvbID"
          placeholder="THP (ID)"
        >
        <mat-label>THP (ID)</mat-label>
        <mat-error>Es muss eine ID für den Teilhabeplan vergeben werden.</mat-error>
      </mat-form-field>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
        <mat-form-field
          appearance="fill"
          ngClass="small"
          ngClass.xs=""
        >
          <mat-select
            required
            id="art"
            formControlName="art"
            placeholder="Art des THP"
            (focus)="onFocus($event, 'art')"
            (blur)="onBlur()"
          >
            <mat-option *ngFor="let art of object.keys(teilhabeplanArten)" [value]="art">{{ teilhabeplanArten[art] }}</mat-option>
          </mat-select>
          <mat-label>Art des THP</mat-label>
          <mat-error>Es muss eine Art des Teilhabeplans angegeben werden.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
          <input matInput
            required
            [matDatepicker]="erstellDatumPicker"
            min="2019-01-01"
            id="erstellDatum"
            (focus)="onFocus($event)"
            (blur)="onBlur()"
            formControlName="erstellDatum"
            placeholder="TT.MM.JJJJ"
          >
          <mat-label>Erstelldatum</mat-label>
          <mat-error *ngIf="form.controls.erstellDatum.hasError('required')">
            Es muss ein Datum eingegeben werden, an dem der Teilhabeplan erstellt wurde.
          </mat-error>
          <button mat-icon-button matSuffix
            class="delete-date"
            [disabled]="form.controls.erstellDatum.disabled"
            (click)="form.controls.erstellDatum.reset()"
          ><i class="material-icons">clear</i></button>
          <mat-datepicker-toggle matSuffix [for]="erstellDatumPicker"></mat-datepicker-toggle>
          <mat-datepicker #erstellDatumPicker></mat-datepicker>
        </mat-form-field>
      </div>

      <mat-form-field
        appearance="fill"
        class="full-width"
      >
        <textarea matInput
          matTextareaAutosize
          matAutosizeMinRows="3"
          placeholder="Kommentar"
          id="kommentar"
          formControlName="kommentar"
        ></textarea>
        <mat-label>Kommentar</mat-label>
        <mat-hint align="end">{{ form.getRawValue().kommentar.length || 0 }} / 255</mat-hint>
        <mat-error *ngIf="form.controls.kommentar.hasError('maxlength')">
          Der Kommentar darf aus maximal 255 Zeichen bestehen.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" ngClass="full-width">
        <input matInput
          [matDatepicker]="endeDatumPicker"
          id="endeDatum"
          [min]="form.getRawValue().erstellDatum"
          formControlName="endeDatum"
          placeholder="TT.MM.JJJJ"
        >
        <mat-label>Faktisches Enddatum</mat-label>
        <mat-error *ngIf="form.controls.endeDatum.hasError('required')">
          Es muss ein Anpassungsdatum des Teilhabeplans eingegeben werden.
        </mat-error>
        <mat-error *ngIf="form.controls.endeDatum.hasError('matDatepickerMin')">
          Das faktische Enddatum darf nicht vor dem Erstelldatum liegen.
        </mat-error>
        <button mat-icon-button matSuffix
          class="delete-date"
          [disabled]="form.controls.endeDatum.disabled"
          (click)="form.controls.endeDatum.reset()"
        ><i class="material-icons">clear</i></button>
        <mat-datepicker-toggle matSuffix [for]="endeDatumPicker"></mat-datepicker-toggle>
        <mat-datepicker #endeDatumPicker></mat-datepicker>
      </mat-form-field>

      <div class="form-container"
        formArrayName="anpassungen"
        (mouseenter)="onFocus($event, 'anpassungen')"
        (mouseleave)="onBlur()"
        (focusin)="onFocus($event, 'anpassungen')"
        (focusout)="onBlur()"
      >
        <div class="form-spacer-large"></div>
        <div *ngIf="form.getRawValue()?.anpassungen?.length > 0">
          <div *ngFor="let item of anpassungen.controls; let i = index">
            <div [formGroupName]="i">
              <div fxLayout="row"
                fxLayout.xs="column"
                fxLayoutGap="10px"
                [matBadge]="item.value.nr"
                matBadgePosition="before"
                matBadgeColor="primary"
              >
                <mat-form-field appearance="fill" ngClass="no-vertical-margin full-width" ngClass.xs="">
                  <input matInput
                    [matDatepicker]="datumPicker"
                    [min]="form.getRawValue().erstellDatum"
                    (dateChange)="sortFieldsByDate()"
                    placeholder="TT.MM.JJJJ"
                    id="datum"
                    formControlName="datum"
                  >
                  <mat-label>Anpassungsdatum</mat-label>
                  <mat-error *ngIf="hasError(anpassungen.controls[i]?.controls?.datum, 'matDatepickerMin')">
                    Ein Anpassungsdatum darf nicht vor dem Erstelldatum eines Teilhabeplans liegen.
                  </mat-error>
                  <mat-error *ngIf="hasError(anpassungen.controls[i]?.controls?.datum, 'required')">
                    Es muss ein Anpassungsdatum des Teilhabeplans eingegeben werden.
                  </mat-error>
                  <button mat-icon-button matSuffix
                    class="delete-date"
                    [disabled]="anpassungen.controls[i].controls.datum.disabled"
                    (click)="anpassungen.controls[i].controls.datum.reset()"
                  ><i class="material-icons">clear</i></button>
                  <mat-datepicker-toggle matSuffix [for]="datumPicker"></mat-datepicker-toggle>
                  <mat-datepicker #datumPicker></mat-datepicker>
                </mat-form-field>
                <button mat-icon-button
                  (click)="deleteFrom(anpassungen, i)"
                  color="warn"
                  class="delete"
                >
                  <i class="material-icons">delete_forever</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="form-spacer-small"></div>
        <button mat-stroked-button
          class="no-margin"
          color="primary"
          [disabled]="anpassungsDatumCondition()"
          (click)="addToFormArray(anpassungen, false)"
        >
          <i class="material-icons">add</i> Anpassungsdatum hinzufügen
        </button>
        <div class="form-spacer-large"></div>
      </div>
    </form>
  </div>
</div>

<div class="form-spacer-medium"></div>

<div class="pull-right">
  <button mat-raised-button
    color="primary"
    [disabled]="!form.valid"
    (click)="onSave()"
  >
    Speichern
  </button>
  <button mat-stroked-button
    color="warn"
    [disabled]="!curIntegrationPlanObject.id"
    (click)="onDelete()"
  >
    Löschen
  </button>
  <button mat-stroked-button
    *ngIf="isInPopup"
    (click)="closePopupIfPristine()"
  >
    Abbrechen
  </button>
  <button mat-stroked-button
    color="primary"
    class="loading-button"
    [disabled]="!curIntegrationPlanObject.id"
    (click)="download(integrationPlanId ? integrationPlanId : filter.id, 'Teilhabeplan.pdf')"
  >
    <mat-spinner *ngIf="downloading$ | async" mode="indeterminate" diameter="15"></mat-spinner>
    PDF-Export
  </button>
  <button mat-stroked-button
    *ngIf="!isInPopup"
    color="primary"
    (click)="navigateBack()"
  >
    <mat-icon class="material-icons">chevron_left</mat-icon>
    Zurück zur Liste der Teilhabepläne
  </button>
</div>
