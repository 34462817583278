<mat-card class="title-card">
  <mat-card-header>
    <h1>Benutzerverwaltung</h1>
  </mat-card-header>
</mat-card>

<mat-card class="no-title" *ngIf="(dataSource$ | async).data?.length > 0">
  <mat-progress-bar
    *ngIf="(loading$ | async)"
    mode="indeterminate"
  ></mat-progress-bar>

  <mat-card-content class="table">
    <mat-table #table
      [dataSource]="(dataSource$ | async)"
      matSort
      (matSortChange)="list.toggleSort('benutzer', $event, filter)"
      matSortActive="{{ (sortBy$ | async).active }}"
      matSortDirection="{{ (sortBy$ | async).direction }}"
      matSortDisableClear=true
    >
      <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header aria-label="Sortierung für Benutzername"> Benutzername </mat-header-cell>
        <mat-cell *matCellDef="let row" class="clickable" [routerLink]="['/benutzer/', row.id]">
          {{ row.username }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef aria-label="Sortierung für Rolle"> Rolle </mat-header-cell>
        <mat-cell *matCellDef="let row" class="clickable" [routerLink]="['/benutzer/', row.id]">
          <span *ngFor="let entry of row.roles; let lastItem = last">
            {{ userRoles[entry] + (lastItem ? '' : ',') }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell class="small pull-right" *matHeaderCellDef> Aktionen </mat-header-cell>
        <mat-cell class="small pull-right" *matCellDef="let row">
          <button mat-icon-button
            *ngIf="row.id !== (ownId$ | async)"
            matTooltip="Entfernen"
            matTooltipPosition="left"
            (click)="onDelete(row.id, row.username)"
            aria-label="Element entfernen"
          >
            <mat-icon>
              <i class="material-icons delete">delete_forever</i>
            </mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-card-content>
  <mat-card-footer>
    <mat-paginator
      [length]="(paginatorOps$ | async).length"
      [pageIndex]="(paginatorOps$ | async).pageIndex"
      [pageSize]="(paginatorOps$ | async).pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="pageEvent = $event; list.onPaginateChange('benutzer', $event, filter)"
      showFirstLastButtons
    >
    </mat-paginator>
  </mat-card-footer>
</mat-card>

<mat-card *ngIf="!((dataSource$ | async).data?.length > 0)">
  <p> Es konnten keine Einträge gefunden werden. </p>
</mat-card>

<mat-card class="button-card">
  <mat-card-content>
    <button mat-raised-button
      color="primary"
      [routerLink]="['/benutzer/', 'neu']"
    >
      <i class="material-icons">add</i> Neuen Benutzer hinzufügen
    </button>
  </mat-card-content>
</mat-card>
