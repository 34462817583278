import {
  Component,
  EventEmitter,
  ChangeDetectionStrategy,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { Observable } from 'rxjs';
import { selectLoggedIn, selectUsername } from 'app/login/reducers/login.reducer';
import { selectUpdateAvailable } from 'app/update/reducers/update.reducer';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Logout } from 'app/login/action/logout.actions';
import { MatDialog } from '@angular/material/dialog';
import { UserDetailsComponent } from './user-details/user-details.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent {

  loggedIn$: Observable<boolean>;
  username$: Observable<string>;
  version$: Observable<string>;
  updateAvailable$: Observable<boolean>;
  @Output() toggleSidenav: EventEmitter<void> = new EventEmitter();

  constructor(
    private store: Store<State>,
    private http: HttpClient,
    protected matDialog: MatDialog,
  ) {
    this.loggedIn$ = this.store.select(selectLoggedIn);
    this.username$ = this.store.select(selectUsername);
    this.updateAvailable$ = this.store.select(selectUpdateAvailable);
    this.version$ = environment.production
      ? this.http.get(this.getBase() + 'version', { responseType: 'text' })
      : new Observable(subscriber => subscriber.next('dev-local'));
  }

  toggle() {
    this.toggleSidenav.emit();
  }

  public getBase() {
    let href = '';
    const base = document.getElementsByTagName('base');
    if (base.length > 0) {
      href = base[0].getAttribute('href').substr(1) + '/';
    }
    return href;
  }

  showForm(): void {
    this.matDialog.open(UserDetailsComponent, {
      maxWidth: '70vw',
      width: '70%',
    });
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }
}
