import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { ActivatedRoute } from '@angular/router';
import { MatSelectHelperService } from 'app/shared/matSelectHelper.service';
import { FormArrayHelperService } from 'app/shared/formArrayHelper.service';
import { filter, distinctUntilChanged, take } from 'rxjs/operators';
import { usersActions, CustomSaveUser } from 'app/users/actions/users.actions';
import { usersSelectors } from 'app/users/reducers/users.reducer';
import { Observable } from 'rxjs';
import { Users, UserRoles } from 'app/users/users';
import * as _ from 'lodash';
import { selectOwnUserId } from 'app/login/reducers/login.reducer';

const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-zA-ZäöüßÄÖÜ]).{8,}$/;

@Component({
  selector: 'app-user-details',
  templateUrl: 'user-details.component.html'
})

export class UserDetailsComponent implements OnInit {

  form: FormGroup;
  user$: Observable<Users>;
  ownId$: Observable<string>;
  curUserObject: Users;
  filter: any;
  roles = UserRoles;
  objKeys = Object.keys;
  objValues = Object.values;

  constructor(
    public dialogRef: MatDialogRef<UserDetailsComponent>,
    public store: Store<State>,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    public matSelectHelper: MatSelectHelperService,
    public formHelper: FormArrayHelperService,
  ) {
    this.form = this.fb.group({
      id: '',
      firstname: '',
      lastname: '',
      email: ['', Validators.email],
      hashedClientPassword: '',
      roles: { value: '', disabled: true },
      plainPassword: ['', Validators.pattern(PASSWORD_PATTERN)],
    });
  }

  ngOnInit() {
    this.ownId$ = this.store.select(selectOwnUserId);
    this.ownId$.pipe(take(1)).subscribe(id => this.store.dispatch(usersActions.get(id)));

    this.route.params
      .pipe(
        filter(params => !params.id),
        distinctUntilChanged()
      )
      .subscribe(() => this.store.dispatch(usersActions.set({})));

    this.user$ = this.store.select(usersSelectors.selectCurrent);

    this.user$.subscribe(data => {
      if (data) {
        this.form.patchValue(data);
        this.curUserObject = _.cloneDeep(data);
      }
    });

    this.form.valueChanges.subscribe(() => this.markFormGroupTouched(this.form));
  }

  markFormGroupTouched(group: FormGroup) {
    (<any>Object).values(group.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onSave() {
    this.store.dispatch(new CustomSaveUser({ ...this.curUserObject, ...this.form.value }, true));
    this.dialogRef.close();
  }
}
