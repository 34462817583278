<div class="dialog">
  <h2>{{ title }}</h2>
  <p>{{ message }}</p>

  <div mat-dialog-actions class="button-container">
    <button mat-button (click)="dialogRef.close(false)" tabindex="1">
      {{ buttonTitleDeny }}
    </button>
    <button mat-button (click)="dialogRef.close(true)" tabindex="2" [color]="color">
      {{ buttonTitleConfirm }}
    </button>
  </div>
</div>
