import { Action } from '@ngrx/store';
import { Importer } from 'app/importer/importer';
import { HttpResponse } from '@angular/common/http';

export const SET_SELECTED = '[Importer] Set Selected';
export const IMPORT_EXCEL = '[Importer] Import Excel File';
export const IMPORT_EXCEL_DONE = '[Importer] Import Excel File Done';
export const IMPORT_EXCEL_ERROR = '[Importer] Import Excel File Error';
export const EXPORT_EXCEL = '[Importer] Export Excel File';
export const EXPORT_EXCEL_DONE = '[Importer] Export Excel File Done';
export const EXPORT_EXCEL_ERROR = '[Importer] Export Excel File Error';
export const DOWNLOAD_FILE = '[Importer] Download File ';
export const DOWNLOAD_FILE_DONE = '[Importer] Download File Done';
export const DOWNLOAD_FILE_ERROR = '[Importer] Download File Error';
export const GET_IMPORT_AUTHORITY = '[Importer] Get Import Authority';
export const GET_IMPORT_AUTHORITY_DONE = '[Importer] Get Import Authority Done';
export const GET_IMPORT_AUTHORITY_ERROR = '[Importer] Get Import Authority Error';

export interface ExportExcelPayload {
  from: string;
  to: string;
}

export class SetSelectedImport implements Action {
  readonly type = SET_SELECTED;
  constructor(public payload: Importer) { }
}

export class GetImportAuthority implements Action {
  readonly type = GET_IMPORT_AUTHORITY;
}

export class GetImportAuthorityDone implements Action {
  readonly type = GET_IMPORT_AUTHORITY_DONE;
  constructor(public payload: any) { }
}

export class GetImportAuthorityError implements Action {
  readonly type = GET_IMPORT_AUTHORITY_ERROR;
}

export class ImportExcel implements Action {
  readonly type = IMPORT_EXCEL;
  constructor(public file: any) { }
}

export class ImportExcelDone implements Action {
  readonly type = IMPORT_EXCEL_DONE;
  constructor(public payload: any) { }
}

export class ImportExcelError implements Action {
  readonly type = IMPORT_EXCEL_ERROR;
  constructor(public res: HttpResponse<any>) {}
}

export class DownloadFile implements Action {
  readonly type = DOWNLOAD_FILE;
  constructor(public id: string, public entity: string, public fileName: string) { }
}

export class DownloadFileDone implements Action {
  readonly type = DOWNLOAD_FILE_DONE;
  constructor(public fileName: string, public blob: Blob) {}
}

export class DownloadFileError implements Action {
  readonly type = DOWNLOAD_FILE_ERROR;
}

export class ExportExcel implements Action {
  readonly type = EXPORT_EXCEL;
  constructor(public payload: ExportExcelPayload) { }
}

export class ExportExcelDone implements Action {
  readonly type = EXPORT_EXCEL_DONE;
  constructor(public fileName: string, public blob: HttpResponse<Blob>) {}
}

export class ExportExcelError implements Action {
  readonly type = EXPORT_EXCEL_ERROR;
}
