import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IntegrationPlan {
  id?: string;
  thvbID?: string;
  erstellDatum?: string;
  art?: string;
  endeDatum?: string;
  kommentar?: string;
  antraege?: {
    thvbID?: string;
    id?: string;
  }[];
  anpassungen?: any;
}

export enum TeilhabeplanArten {
  NICHT_TRAEGERUEBERGREIFEND = 'Nicht trägerübergreifend',
  TRAEGERUEBERGREIFEND_OHNE_KONFERENZ = 'Trägerübergreifend ohne Konferenz',
  TRAEGERUEBERGREIFEND_MIT_KONFERENZ = 'Trägerübergreifend mit Konferenz',
}

export interface IntegrationPlanFilterOptions {
  page?: number;
  size?: number;
  filter?: any[];
  sort?: string;
  id?: string;
  von?: string;
  bis?: string;
}

export const AnpassungFormGroup = {
  nr: '',
  datum: ''
};

export const AntragFormGroup = {
  id: ''
};

export class IntegrationPlanDataSource extends DataSource<any> {
  constructor(private data) {
    super();
  }

  connect(): Observable<any[]> {
    return merge(this.data).pipe(
      map(() => {
        return this.data.slice();
      })
    );
  }

  disconnect() {}
}
