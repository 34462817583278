import {merge as observableMerge, Observable } from 'rxjs';
import { AbstractControl } from '@angular/forms';


export class CustomValidators {

  /**
   * Validatorzuweisung mit Abhängigkeiten.
   * Fügt control nur validator an, wenn dependency true zurück gibt.
   *
   * Beispielverwendung:
   * CustomValidators.dependentValidator(
   *     this.form.controls['clearPassword'],
   *     this.form.controls['name'],
   *     (control: AbstractControl[]) => {
   *         return !!control[0].value;
   *     },
   *     Validators.required
   * );
   *
   * In dependency erhält man grundsätzlich ein Control-Array, auch wenn man nur
   * ein Control als Abhängigkeit übergibt. Werden mehrere Controls hinterlegt,
   * wird ein Observable.merge verwendet.
   *
   * NOTE: Dieser Validator lässt sich nicht direkt zu control.validator hinzufügen,
   * sondern muss separat aufgerufen werden.
   *
   * @param  {AbstractControl}                        control           Zu validierendes Control
   * @param  {AbstractControl|Array<AbstractControl>} dependentControls Abhängig Controls
   * @param  {AbstractControl[]}                      dependency        Abhängigkeitsfunktion
   *                                                                    Boolean erwartet
   * @param  {ValidatorFn}                            validator         Auszuführender Validator
   */
  public static dependentValidator(control: AbstractControl,
                                   dependentControls: AbstractControl|Array<AbstractControl>,
                                   dependency: (control: AbstractControl[]) => {},
                                   validator: any): void {
    // Sicherstellen, dass wir immer ein Control-Array haben
    const controls: Array<AbstractControl> = dependentControls.constructor !== [].constructor ?
      [<AbstractControl>dependentControls] :
      <AbstractControl[]>dependentControls;

    // Observables der valueChanges sammeln, um diese zu mergen
    const observables: Observable<String>[] = controls.map((element: AbstractControl) => {
      return element.valueChanges;
    });

    // Auf alle valueChanges hören
    observableMerge(...observables).subscribe(() => {
      if (dependency(controls)) {
        control.setValidators(validator);
        control.updateValueAndValidity();
        return;
      }

      if (control.validator) {
        control.validator = undefined;
        control.updateValueAndValidity();
      }
    });
  }
}
