export class AppSettings {
  /**
   * Zwischenspeicher der bereits geladenen Basis-URL.
   * Sollte nie direkt verwendet werden. Stattdessen immer über den
   * `baseUrlGetter()` arbeiten.
   */
  static baseUrl: string;
}

export function baseUrlGetter(): Promise<string> {
  return new Promise((resolve) => {
    if (AppSettings.baseUrl) {
      resolve(AppSettings.baseUrl);
      return;
    }

    const base = document.querySelector('base');
    const baseUrl = ensureEndsWith(base && base.href || '', '/');

    fetch(baseUrl + 'config.json', { method: 'get' })
      .then(res => res.json())
      .then(json => {
        AppSettings.baseUrl = ensureEndsWith(json.restUrl, '/');
        resolve(AppSettings.baseUrl);
      })
      .catch(() => {
        AppSettings.baseUrl = location.protocol + '//' + location.host + '/';
        resolve(AppSettings.baseUrl);
      });
  });
}

export function ensureEndsWith(value: string, endsWith: string, caseSensitive = false): string {
  if (caseSensitive) {
    return value?.endsWith(endsWith) ? value : value + endsWith;
  } else {
    return value?.toLocaleLowerCase().endsWith(endsWith.toLocaleLowerCase()) ? value : value + endsWith;
  }
}

export function baseCrudUrlGetter(): Promise<string> {
  return new Promise(resolve =>
    (baseUrlGetter() as Promise<string>)
      .then(val => resolve(val + 'rest/'))
  );
}

export function tokenGetter(): string|Promise<string> {
  return JSON.parse(localStorage.getItem('login')).accessToken;
}
