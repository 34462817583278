import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { CrudHelperService } from '@kdo/ng-crud';
import { Store } from '@ngrx/store';
import { responsibilitySelectors } from 'app/responsibility/reducers/responsibility.reducer';
import { NotifyService } from 'app/notify/notify.service';
import { State } from 'app/reducers';
import { effectsFor, CrudEffects } from 'app/effects/crud.effects';
import {
  GetOwnID,
  GET_OWN_ID,
  GetOwnIDDone,
  GetOwnIDError,
  GET_OWN_ID_ERROR,
  CustomFilterResponsibility,
  CUSTOM_FILTER_RESPONSIBILITY
} from '../actions/responsibility.actions';
import { map, switchMap, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

const crud: CrudEffects = effectsFor(
  'responsibility',
  'thvbtraeger',
  'traeger',
  responsibilitySelectors
);

@Injectable()
export class ResponsibilityEffects {
  constructor(
    private helper: CrudHelperService,
    private actions$: Actions,
    private notify: NotifyService,
    private store: Store<State>,
    private router: Router
  ) {

  }

  readonly getOwnId$ = createEffect(() => this.actions$.pipe(
    ofType<GetOwnID>(GET_OWN_ID),
    switchMap(() => this.helper.get<any>('thvbtraeger/hasownid')),
    map(payload => new GetOwnIDDone(payload)),
    catchError(res => of(new GetOwnIDError(res)))
  ));

  readonly getOwnIdError$ = createEffect(() => this.actions$.pipe(
    ofType<GetOwnIDError>(GET_OWN_ID_ERROR),
    tap(() => this.notify.showNotification('Fehler beim Laden der eigenen ID', 'notify-danger'))
  ), { dispatch: false });

  readonly filter$ = createEffect(() => this.actions$.pipe(
    ofType<CustomFilterResponsibility>(CUSTOM_FILTER_RESPONSIBILITY),
    withLatestFrom(this.store.select(responsibilitySelectors.selectListSettings)),
    switchMap(options =>
      this.helper.getAll<any>(options[0].showAll ? 'thvbtraeger?showall=true' : 'thvbtraeger', options[1])
        .pipe(
          map(payload => ({ type: 'FILTER_RESPONSIBILITY_DONE', payload })),
          catchError(() => of({ type: 'FILTER_RESPONSIBILITY_ERROR' }))
        )
    )
  ));

  readonly deleteDone$ = createEffect(() => this.actions$.pipe(
    ofType<any>('DELETE_RESPONSIBILITY_DONE'),
    tap(() => this.notify.showNotification('Der Eintrag wurde erfolgreich gelöscht.')),
    map(() => new CustomFilterResponsibility(true))
  ));

  readonly get$ = createEffect(() => this.actions$.pipe(crud.get(this.helper)));
  readonly save$ = createEffect(() => this.actions$.pipe(crud.save(this.helper)));
  readonly saveDone$ = createEffect(() => this.actions$.pipe(crud.saveDone(this.store, this.router, this.notify)));
  readonly delete$ = createEffect(() => this.actions$.pipe(crud.deleteMe(this.helper)));
}
