import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CrudHelperService } from '@kdo/ng-crud';
import { Store } from '@ngrx/store';
import { usersSelectors } from 'app/users/reducers/users.reducer';
import { NotifyService } from 'app/notify/notify.service';
import { State } from 'app/reducers';
import { from, of } from 'rxjs';
import { effectsFor, CrudEffects } from 'app/effects/crud.effects';
import {
  DeleteUser,
  DELETE_USER,
  DeleteUserDone,
  DeleteUserError,
  DELETE_USER_DONE,
  usersActions,
  CustomSaveUser,
  CUSTOM_SAVE_USER,
  CustomSaveUserDone,
  CUSTOM_SAVE_USER_DONE,
} from '../actions/users.actions';
import {
  switchMap,
  map,
  tap,
  withLatestFrom,
  catchError,
  concatMap
} from 'rxjs/operators';

const crud: CrudEffects = effectsFor(
  'users',
  'users',
  'benutzer',
  usersSelectors
);

@Injectable()
export class UsersEffects {
  constructor(
    private helper: CrudHelperService,
    private actions$: Actions,
    private notify: NotifyService,
    private store: Store<State>,
    private router: Router,
  ) {

  }

  readonly delete$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteUser>(DELETE_USER),
    switchMap(payload => this.helper.delete('users/' + payload.id).pipe(
      map(res => res.status === 204 ? new DeleteUserDone(res) : new DeleteUserError()),
      catchError(() => of(new DeleteUserError()))
    ))
  ));

  readonly customSave$ = createEffect(() => this.actions$.pipe(
    ofType<CustomSaveUser>(CUSTOM_SAVE_USER),
    concatMap(res => this.helper.save('users', res.payload).pipe(
      map(() => new CustomSaveUserDone(res.savingOwnUser)),
      catchError(() => of({ type: 'SAVE_USERS_ERROR' }))
    )),
  ));

  readonly deleteDone$ = createEffect(() => this.actions$.pipe(
    ofType<DeleteUserDone>(DELETE_USER_DONE),
    tap(() => this.notify.showNotification('Der Eintrag wurde erfolgreich gelöscht.')),
    tap(() => this.router.navigate(['benutzer'])),
    map(() => usersActions.filter())
  ));

  readonly customSaveDone$ = createEffect(() => this.actions$.pipe(
    ofType<CustomSaveUserDone>(CUSTOM_SAVE_USER_DONE),
    withLatestFrom(this.store.select(usersSelectors.selectListSettings)),
    tap(() => this.notify.showNotification('Der Eintrag wurde erfolgreich gespeichert.')),
    filter(payload => !payload[0].savingOwnUser),
    switchMap(([_action, listSettings]) =>
      from(this.router.navigate(['benutzer', { ...listSettings }]))
    ),
    map(routerResult => routerResult
      ? ({ type: 'NO_ACTION' })
      : ({ type: 'FILTER_BENUTZER' })
    )
  ));

  readonly get$ = createEffect(() => this.actions$.pipe(crud.get(this.helper)));
  readonly filter$ = createEffect(() => this.actions$.pipe(crud.filterMe(this.store, this.helper)));
}
