import { Component, ViewChild } from '@angular/core';
import { IntegrationPlanInnerFormComponent } from './inner-form/integration-plan-inner-form.component';

@Component({
  selector: 'app-integration-plan',
  templateUrl: './integration-plan-form.component.html',
  styleUrls: ['./integration-plan-form.component.scss'],
})
export class IntegrationPlanFormComponent {

  @ViewChild(IntegrationPlanInnerFormComponent, { static: true }) innerForm: IntegrationPlanInnerFormComponent;

  constructor () { }

}
