import { LoginGuard } from 'app/login/login.guard';
import { IntegrationPlanFormComponent } from 'app/integration-plan/form/integration-plan-form.component';
import { IntegrationPlanInnerFormComponent } from './form/inner-form/integration-plan-inner-form.component';

const ROUTE_PREFIX = 'teilhabeplan';
export const ROUTES = [
  {
    path: `${ROUTE_PREFIX}`,
    component: IntegrationPlanFormComponent,
    canActivate: [LoginGuard],
    canDeactivate: ['CanDeactivateIntegrationPlan']
  },
  {
    path: `${ROUTE_PREFIX}/neu`,
    component: IntegrationPlanFormComponent,
    canActivate: [LoginGuard],
    canDeactivate: ['CanDeactivateIntegrationPlan']
  },
  {
    path: `${ROUTE_PREFIX}/:id`,
    component: IntegrationPlanFormComponent,
    canActivate: [LoginGuard],
    canDeactivate: ['CanDeactivateIntegrationPlan']
  },
  {
    path: `${ROUTE_PREFIX}/inner`,
    component: IntegrationPlanInnerFormComponent,
    canActivate: [LoginGuard],
    canDeactivate: ['CanDeactivateIntegrationPlan']
  }
];
