import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule, RouterStateSnapshot } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ROUTES } from './configuration.routes';

import { configurationReducer } from 'app/configuration/reducers/configuration.reducer';
import { ConfigurationEffects } from 'app/configuration/effects/configuration.effects';
import { ConfigurationFormComponent } from 'app/configuration/form/configuration-form.component';
import { MaterialModule } from 'app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

const components = [
  ConfigurationFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule.forChild(ROUTES),
    RouterModule,
    StoreModule.forFeature('configuration', configurationReducer),
    EffectsModule.forFeature([ConfigurationEffects])
  ],
  declarations: [...components],
  exports: [...components],
  providers: [
    {
      provide: 'CanDeactivateConfiguration',
      useValue: (component: ConfigurationFormComponent, nextState: RouterStateSnapshot) =>
        (component.form.dirty && nextState.url)
          ? window.confirm('Sind Sie sicher, dass Sie das Formular verlassen möchten?'
            + ' Ungesicherte Änderungen gehen dabei verloren.')
          : true
    },
  ]
})
export class ConfigurationModule { }
