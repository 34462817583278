import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { PreviewReportData } from 'app/report-data/actions/report-data.actions';
import { Observable } from 'rxjs';
import { selectPreviewList } from 'app/report-data/reducers/report-data.reducer';

@Component({
  selector: 'app-preview-form',
  templateUrl: './preview-form.component.html',
  styleUrls: ['./preview-form.component.scss'],
})
export class PreviewFormComponent {

  title: string;
  previewList$: Observable<any>;
  objKeys = Object.keys;

  constructor(
    private store: Store<State>,
    public dialogRef: MatDialogRef<PreviewFormComponent>,
  ) { }

  setContent(content: any) {
    this.store.dispatch(new PreviewReportData(content));
    this.previewList$ = this.store.select(selectPreviewList);
  }
}
