import { Injectable } from '@angular/core';
import { Observable , from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { baseCrudUrlGetter } from 'app/config-getter';

@Injectable()
export class ImporterService {

  constructor(
    private http: HttpClient,
  ) { }

  uploadFile(data: any, endpoint: string): Observable<any> {
    return from(baseCrudUrlGetter()).pipe(
      mergeMap(baseUrlAsync => {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', '*/*');
        headers = headers.set('X-KDO-NO-CONTENT-TYPE', 'true');
        return this.http.post(baseUrlAsync + endpoint, data, { headers });
      })
    );
  }

  getAuthority(): Observable<any> {
    return from(baseCrudUrlGetter()).pipe(
      mergeMap(baseUrlAsync => {
        return this.http.get(baseUrlAsync + 'sysconf/configuration');
      })
    );
  }
}
