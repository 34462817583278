import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Injectable()
export class KlagenValidators {
  form: FormGroup;

  constructor() { }

  setForm(form: FormGroup): void {
    this.form = form;
  }

  disableButtonAddKlagen(): boolean {
    return this.klagen
      && this.klagen.length > 0
        ? !this.klagen.value[this.klagen.length - 1].datumErledigung
          || !this.klagen.value[this.klagen.length - 1].erledigungsart
        : false;
  }

  get klagen(): FormArray {
    return this.form.get('klagen') as FormArray;
  }
}
