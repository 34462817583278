import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NotifyService } from 'app/notify/notify.service';
import { from } from 'rxjs';
import { tap, concatMap, map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import {
  ImportExcel,
  IMPORT_EXCEL,
  ImportExcelDone,
  ImportExcelError,
  IMPORT_EXCEL_DONE,
  DownloadFile,
  DOWNLOAD_FILE,
  DownloadFileDone,
  DownloadFileError,
  DOWNLOAD_FILE_DONE,
  ExportExcel,
  EXPORT_EXCEL,
  ExportExcelDone,
  ExportExcelError,
  EXPORT_EXCEL_DONE,
  GetImportAuthority,
  GET_IMPORT_AUTHORITY,
  GetImportAuthorityDone,
  GetImportAuthorityError,
} from '../actions/importer.actions';
import { of } from 'rxjs';
import { ImporterService } from '../importer.service';
import { baseCrudUrlGetter } from 'app/config-getter';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DownloadHelperService } from '@kdo/ng-crud';

@Injectable()
export class ImporterEffects {
  constructor(
    private actions$: Actions,
    private notify: NotifyService,
    private importer: ImporterService,
    private http: HttpClient,
    private downloadHelper: DownloadHelperService,
  ) { }

    readonly importExcel$ = createEffect(() => this.actions$.pipe(
      ofType<ImportExcel>(IMPORT_EXCEL),
      concatMap(payload =>
        this.importer.uploadFile(payload.file, 'excel/import').pipe(
          map(res => new ImportExcelDone(res)),
          catchError(err => of(new ImportExcelError(err)))
        )
      )
    ));

    readonly getImportAuthority$ = createEffect(() => this.actions$.pipe(
      ofType<GetImportAuthority>(GET_IMPORT_AUTHORITY),
      concatMap(() =>
        this.importer.getAuthority().pipe(
          map(res => new GetImportAuthorityDone(res)),
          catchError(() => of(new GetImportAuthorityError()))
        )
      )
    ));

    readonly importExcelDone$ = createEffect(() => this.actions$.pipe(
      ofType<ImportExcelDone>(IMPORT_EXCEL_DONE),
      tap(() => this.notify.showNotification('Import erfolgreich')),
    ), { dispatch: false });

    readonly downloadFile$ = createEffect(() => this.actions$.pipe(
      ofType<DownloadFile>(DOWNLOAD_FILE),
      switchMap(action =>
        from(baseCrudUrlGetter()).pipe(
          mergeMap(baseUrlAsync =>
            this.http.get(baseUrlAsync + 'export/pdf/' + action.entity + '/' + action.id,
              { responseType: 'blob', observe: 'response' }
            )
            .pipe(
              map(val => new DownloadFileDone(this.getFilename(val), val.body)),
              catchError(() => of(new DownloadFileError()))
            )
          )
        )
      )
    ));

    readonly downloadFileDone$ = createEffect(() => this.actions$.pipe(
      ofType<DownloadFileDone>(DOWNLOAD_FILE_DONE),
      tap(action => {
        this.downloadHelper.downloadFile(action.blob, action.fileName);
      })
    ), { dispatch: false });

    readonly exportExcel$ = createEffect(() => this.actions$.pipe(
      ofType<ExportExcel>(EXPORT_EXCEL),
      switchMap(action =>
        from(baseCrudUrlGetter()).pipe(
          mergeMap(baseUrlAsync =>
            this.http.post(baseUrlAsync + 'excel/export', {},
              {
                responseType: 'blob',
                observe: 'response',
                params: {
                  from: action.payload.from,
                  to: action.payload.to
                },
              }
            )
            .pipe(
              map(val => new ExportExcelDone('Export_THVB_' + action.payload.from + '-' + action.payload.to + '.xlsx', val)),
              catchError(() => of(new ExportExcelError()))
            )
          )
        )
      )
    ));

    readonly exportExcelDone$ = createEffect(() => this.actions$.pipe(
      ofType<ExportExcelDone>(EXPORT_EXCEL_DONE),
      tap(action => {
        this.downloadHelper.downloadFile(action.blob.body, action.fileName);
      })
    ), { dispatch: false });

  /**
   * Extrahiert Dateinamen aus dem `Content-Disposition`-Response-Header
   * @param req HttpResponse mit Content-Disposition Header
   */
  getFilename(req: HttpResponse<any>): string {
    return req.headers.get('Content-Disposition').split(';')[1].split('filename=')[1].trim();
  }
}
