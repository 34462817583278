import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { State } from 'app/reducers';
import { selectLoggedIn } from 'app/login/reducers/login.reducer';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private store: Store<State>, private router: Router) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(selectLoggedIn).pipe(
      tap(val => !val
        && this.router.navigate(['login'], { queryParams: { redirect: state.url } })
      )
    );
  }
}
