import { NgControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';

/**
 * Enabled bzw. disabled ein FormControl abhängig von einer Bedingung dynamisch mit Angular.
 * Wird verwendet, da das `[disabled]`-Attribut zu `changed after checked`-Fehlern führen kann
 * Quelle: https://netbasal.com/disabling-form-controls-when-working-with-reactive-forms-in-angular-549dd7b42110
 */

@Directive({
  selector: '[appDisableControl]'
})
export class DisableControlDirective {

  @Input() set appDisableControl(condition: boolean) {
    if (this.ngControl && this.ngControl.control) {
      const action = condition ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
  }

  constructor(private ngControl: NgControl) { }
}
