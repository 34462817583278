import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Injectable()
export class ErstattungsantragValidators {
  form: FormGroup;

  constructor() { }

  setForm(form: FormGroup): void {
    this.form = form;
  }

  disableButtonAddErstattungsantrag(): boolean {
    return this.erstattungsantrag
      && this.erstattungsantrag.length > 0
        ? !this.erstattungsantrag.value[this.erstattungsantrag.length - 1].datumErledigung
          || !this.erstattungsantrag.value[this.erstattungsantrag.length - 1].erledigungsart
        : false;
  }

  get erstattungsantrag(): FormArray {
    return this.form.get('erstattungsantrag') as FormArray;
  }

}
