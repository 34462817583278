import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Injectable()
export class GutachtenValidators {
  form: FormGroup;

  constructor() { }

  setForm(form: FormGroup): void {
    this.form = form;
  }

  disableButtonAddGutachten(): boolean {
    // Wenn datumFeststellungZustaendigkeit ausgefüllt ist, muss mindestens eine Leistungsgruppe ausgewählt werden
    const noLeistungsgruppeSelected = this.form.getRawValue().datumFeststellungZustaendigkeit
        ? !this.form.getRawValue().leistungenMedizinischeReha
        && !this.form.getRawValue().leistungenSozialeTeilhabe
        && !this.form.getRawValue().leistungenTeilhabeArbeitsleben
        && !this.form.getRawValue().leistungenTeilhabeBildung
      : false
    ;
    return this.gutachten.length >= 5
        // Wenn datumAuftrag nicht ausgefüllt ist
      || (this.gutachten.length > 0 ? !this.getLastEntryValue(this.gutachten, 'datumAuftrag') : false)
        // Wenn datumVorliegen gefüllt ist, aber keine Leistungsgruppe ausgewählt ist
      || (this.gutachten.length > 0 && this.getLastEntryValue(this.gutachten, 'datumVorliegen')
          ? !this.getLastEntryValue(this.gutachten, 'leistungenMedizinischeReha')
            && !this.getLastEntryValue(this.gutachten, 'leistungenTeilhabeArbeitsleben')
            && !this.getLastEntryValue(this.gutachten, 'leistungenTeilhabeBildung')
            && !this.getLastEntryValue(this.gutachten, 'leistungenSozialeTeilhabe')
          : false
        )
      || noLeistungsgruppeSelected
    ;
  }

  // Wenn datumVorliegen ausgefüllt ist, muss mindestens eine Leistungsgruppe ausgewählt sein
  atLeastOneLeistungsgruppeSelected(i: number): boolean {
    return (<FormGroup>this.gutachten.controls[i]).controls.datumVorliegen.value
      && !(<FormGroup>this.gutachten.controls[i]).controls.leistungenMedizinischeReha.value
      && !(<FormGroup>this.gutachten.controls[i]).controls.leistungenTeilhabeArbeitsleben.value
      && !(<FormGroup>this.gutachten.controls[i]).controls.leistungenTeilhabeBildung.value
      && !(<FormGroup>this.gutachten.controls[i]).controls.leistungenSozialeTeilhabe.value;
  }

  // Gibt das Value eines gewünschten FormControls aus der letzten FormGroup eines FormArrays wieder
  getLastEntryValue(array: FormArray, control: string) {
    return array.length > 0 ? (<FormGroup>array.controls[array.length - 1]).controls[control].value : undefined;
  }

  get gutachten(): FormArray {
    return this.form.get('gutachten') as FormArray;
  }
}
