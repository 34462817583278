import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatPaginatorIntlGer } from './shared/german-paginator-intl';
import { NgModule } from '@angular/core';
import { MomentUtcDateAdapter } from './moment-utc-date-adapter';

const modules = [
  MatButtonModule, MatButtonToggleModule,
  MatCheckboxModule, MatCardModule,
  MatSidenavModule, MatToolbarModule,
  MatTableModule, MatPaginatorModule,
  MatInputModule, MatSortModule,
  MatDialogModule, MatTooltipModule,
  MatProgressSpinnerModule, MatListModule,
  MatSnackBarModule, MatIconModule,
  MatMenuModule, MatExpansionModule,
  MatProgressBarModule, MatSelectModule,
  MatDatepickerModule, MatNativeDateModule,
  MatBadgeModule, MatMomentDateModule,
  MatRadioModule
];

@NgModule({
  imports: modules,
  exports: modules,
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlGer },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class MaterialModule { }
