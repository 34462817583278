import { AUTH_OPTIONS } from './auth-options.token';
import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpHandler } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HttpSentEvent } from '@angular/common/http';
import { HttpHeaderResponse } from '@angular/common/http';
import { HttpProgressEvent } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { HttpUserEvent } from '@angular/common/http';

/**
 * `HttpInterceptor` für das Anhängen der Tokens an einen HTTP-Request
 * mit der Klasse `HttpClient`.
 */
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  authScheme: string;
  ignoreHeaderName: string;
  headerName: string;
  throwNoTokenError: boolean;

  tokenGetter: () => string | Promise<String>;

  constructor(@Inject(AUTH_OPTIONS) config: any) {
    this.tokenGetter = config.tokenGetter;
    this.ignoreHeaderName = config.ignoreHeaderName || 'X-KDO-NO-AUTH';
    this.headerName = config.headerName || 'Authorization';
    this.authScheme =
      config.authScheme || config.authScheme === ''
        ? config.authScheme
        : 'Bearer ';
    this.throwNoTokenError = config.throwNoTokenError || false;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent
    | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (request.headers.has('X-KDO-NO-AUTH')) {
      return next.handle(request);
    }
    const token: any = this.tokenGetter();

    if (token instanceof Promise) {
      return from(token)
        .pipe(
          mergeMap((asyncToken: string) => this.handleInterception(asyncToken, request, next))
        );
    }

    return this.handleInterception(token, request, next);
  }

  handleInterception(
    token: string,
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    if (!token && this.throwNoTokenError) {
      throw new Error('Could not get token from tokenGetter function.');
    }

    request = request.clone({
      setHeaders: {
        [this.headerName]: `${this.authScheme}${token}`
      }
    });

    return next.handle(request);
  }
}
