<app-integration-plan-inner
  *ngIf="data.thpButtonPressed"
  [data]="data"
  [integrationPlanId]="data.integrationPlanId"
  [dialogRef]="dialogRef"
></app-integration-plan-inner>

<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" class="popup-search-entry" *ngIf="!data.thpButtonPressed">
  <mat-form-field appearance="fill" class="full-width">
    <input matInput
      placeholder="Suche"
      [value]="filter.suche ? filter.suche : ''"
      (keyup)="search($event, false)"
      (keyup.enter)="search($event, true)"
      (keydown.enter)="$event.preventDefault()"
    >
    <mat-label>Teilhabeplan-ID</mat-label>
  </mat-form-field>
  <button mat-raised-button
    color="primary"
    (click)="search(currentSearchEvent, true)"
    class="search-button"
  >
    <mat-icon class="material-icons">search</mat-icon>
  </button>
</div>

<mat-card-content class="table integration-plan-table" *ngIf="!data.thpButtonPressed && (dataSourceIntegrationPlan$ | async).length">
  <mat-table #table
    [dataSource]="dataSourceIntegrationPlan$"
    matSort
    matSortActive="{{ sortByIntegrationPlan.active }}"
    matSortDirection="{{ sortByIntegrationPlan.direction }}"
    (matSortChange)="toggleSort($event)"
    matSortDisableClear=true
  >
    <ng-container matColumnDef="thvbID">
      <mat-header-cell *matHeaderCellDef mat-sort-header aria-label="Sortierung für Teilhabeplan-ID"> Teilhabeplan-ID </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.thvbID }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="erstellDatum">
      <mat-header-cell *matHeaderCellDef mat-sort-header aria-label="Sortierung für Erstelldatum des THP"> Erstelldatum des THP </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.erstellDatum }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell class="small pull-right" *matHeaderCellDef> Aktionen </mat-header-cell>
      <mat-cell class="small pull-right" *matCellDef="let row">
        <button mat-icon-button
          (click)="selectIntegrationPlan(row)"
          matTooltip="Übernehmen"
          matTooltipPosition="left"
        >
          <mat-icon color="primary">
            <i class="material-icons">move_to_inbox</i>
          </mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumnsThp"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumnsThp;"></mat-row>
  </mat-table>
</mat-card-content>
<mat-card-footer *ngIf="!data.thpButtonPressed">
  <mat-paginator
    *ngIf="!((dataSourceIntegrationPlan$ | async).length > 0)"
    [hidePageSize]="true"
    [length]="(paginatorOpsIntegrationPlan$ | async).length"
    [pageIndex]="(paginatorOpsIntegrationPlan$ | async).pageIndex"
    [pageSize]="(paginatorOpsIntegrationPlan$ | async).pageSize"
    (page)="pageEvent = $event; onPaginateChange($event)"
  >
  </mat-paginator>
  <div class="form-spacer-small"></div>
  <div class="pull-right">
    <button mat-stroked-button
      color="primary"
      (click)="closeDialog()"
    >
      Abbrechen
    </button>
  </div>
</mat-card-footer>
<mat-card-content class="no-margin" *ngIf="!data.thpButtonPressed && !((dataSourceIntegrationPlan$ | async).length > 0)">
  <p> Es konnten keine Einträge gefunden werden. </p>
</mat-card-content>
