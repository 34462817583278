import { Action } from '@ngrx/store';

export const LOGIN = '[Login] Login';
export const LOGIN_DONE = '[Login] Login Done';
export const LOGIN_ERROR = '[Login] Login Error';
export const INVALIDATE = '[Login] Invalidate';

export interface LoginPayload {
  username: string;
  password: string;
  redirect?: string;
}
export class Login implements Action {
  readonly type = LOGIN;
  constructor(public payload: LoginPayload) {}
}

export interface LoginDonePayload {
  username: string;
  accessToken: string;
  authorities: any;
  redirect?: string;
}
export class LoginDone implements Action {
  readonly type = LOGIN_DONE;
  constructor(public payload: LoginDonePayload) {}
}

export class Invalidate implements Action {
  readonly type = INVALIDATE;
  constructor() {}
}

export class LoginError implements Action {
  readonly type = LOGIN_ERROR;
  constructor(public payload: string) {}
}
export type LoginActions = Login
  | LoginDone
  | LoginError
  | Invalidate
;
