<div class="dialog">
  <mat-list role="list">
    <mat-list-item role="listitem" *ngFor="let backup of backups$ | async">
      <h4 mat-line>{{ backup.backupName }}</h4>
      <p mat-line> {{ backup.createdAt | date:'dd.MM.y - h:mm:ss' }} </p>
      <button mat-stroked-button
        class="match-width"
        *ngIf="!(restoring$ | async)"
        color="primary"
        (click)="selectBackup(backup)"
      >
        Wiederherstellen
      </button>
    </mat-list-item>
  </mat-list>

  <div mat-dialog-actions class="button-container pull-right">
    <button mat-button (click)="dialogRef.close()" tabindex="1" color="primary">
      Schließen
    </button>
  </div>
</div>


