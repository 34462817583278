import { LoginGuard } from 'app/login/login.guard';
import { RequestFormComponent } from 'app/request/form/request-form.component';
import { AuthorityGuard } from 'app/login/authority.guard';

const ROUTE_PREFIX = 'antrag';
export const ROUTES = [
  {
    path: `${ROUTE_PREFIX}`,
    redirectTo: `${ROUTE_PREFIX}/neu`,
    pathMatch: 'full',
    canDeactivate: ['CanDeactivateRequest'],
    data: { requiredAuthority: 'ERFASSUNG' }
  },
  {
    path: `${ROUTE_PREFIX}/neu`,
    component: RequestFormComponent,
    canDeactivate: ['CanDeactivateRequest'],
    canActivate: [LoginGuard, AuthorityGuard],
    data: { requiredAuthority: 'ERFASSUNG' },
  },
  {
    path: `${ROUTE_PREFIX}/:id`,
    component: RequestFormComponent,
    canDeactivate: ['CanDeactivateRequest'],
    canActivate: [LoginGuard, AuthorityGuard],
    data: { requiredAuthority: 'ERFASSUNG' },
  }
];
