import { IntegrationPlan, IntegrationPlanFilterOptions } from 'app/integration-plan/integration-plan';
import { createFeatureSelector } from '@ngrx/store';
import { selectorsFor, reducersFor } from 'app/reducers/crud.reducer';
import { SET_INTEGRATION_PLAN_FILTER, SetIntegrationPlanFilter } from 'app/integration-plan/actions/integration-plan.actions';
import * as _ from 'lodash';
import { PaginatorOptions } from '@kdo/ng-crud';

export interface IntegrationPlanState {
  list: any[];
  currentEntity: any;
  listSettings: IntegrationPlanFilterOptions;
  paginatorOptions: PaginatorOptions;
  loading: boolean;
 }

const initialState: IntegrationPlanState = {
  list: [],
  currentEntity: {},
  listSettings: {
    page: 0,
    sort: encodeURIComponent(JSON.stringify({
      active: 'thvbID',
      direction: 'asc',
    })),
    size: 10,
    id: '',
    von: '',
    bis: '',
  },
  paginatorOptions: {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
  },
  loading: false
};

export function integrationPlanReducer(state = initialState, action) {
  const crudReduced = reducersFor<IntegrationPlan>('integrationPlan', state, action);

  if (crudReduced !== false) {
    return crudReduced;
  }

  switch (action.type) {
    case SET_INTEGRATION_PLAN_FILTER:
      return setFilter(state, action);

    default:
      return state;
  }
}

function setFilter(state: IntegrationPlanState, action: SetIntegrationPlanFilter): IntegrationPlanState {

  const mergedSettings = _.merge({}, initialState.listSettings, action.payload);

  return {
    ..._.cloneDeep(state),
    listSettings: _.isEmpty(action.payload) ? initialState.listSettings : mergedSettings
  };
}

// Selectors
export const selectIntegrationPlan = createFeatureSelector<IntegrationPlanState>('integrationPlan');
export const integrationPlanSelectors = selectorsFor<IntegrationPlan>(selectIntegrationPlan);
