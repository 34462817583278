import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

const germanRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length === 0 || pageSize === 0) { return `0 von ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  if ((startIndex + 1) === endIndex) {
    return `Seite ${page + 1}: Eintrag ${endIndex} von ${length}.`;
  } else {
    return `Seite ${page + 1}: Einträge ${startIndex + 1} - ${endIndex} von ${length}.`;
  }
};

@Injectable()
export class MatPaginatorIntlGer extends MatPaginatorIntl {
  itemsPerPageLabel = 'Einträge pro Seite';
  nextPageLabel = 'Nächste Seite';
  previousPageLabel = 'Vorherige Seite';
  getRangeLabel = germanRangeLabel;
}
