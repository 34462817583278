import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ROUTES } from './importer.routes';

import { importerReducer } from 'app/importer/reducers/importer.reducer';
import { ImporterEffects } from 'app/importer/effects/importer.effects';
import { ImporterFormComponent } from 'app/importer/form/importer-form.component';
import { MaterialModule } from 'app/material.module';
import { ImporterService } from './importer.service';
import { FlexLayoutModule } from '@angular/flex-layout';

const components = [
  ImporterFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule.forChild(ROUTES),
    RouterModule,
    StoreModule.forFeature('importer', importerReducer),
    EffectsModule.forFeature([ImporterEffects])
  ],
  declarations: [...components],
  exports: [...components],
  providers: [ ImporterService ]
})
export class ImporterModule { }
