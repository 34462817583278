import { Action } from '@ngrx/store';

export const LOGOUT = '[Logout] Logout';
export const LOGOUT_DONE = '[Logout] Logout Done';

export class Logout implements Action {
  readonly type = LOGOUT;
  constructor() {}
}

export class LogoutDone implements Action {
  readonly type = LOGOUT_DONE;
  constructor() {}
}

export type LogoutActions = Logout
  | LogoutDone
;
