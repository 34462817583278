import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { Observable } from 'rxjs/Observable';
import { ImportExcel, ExportExcel, GetImportAuthority } from '../actions/importer.actions';
import { selectUploading, selectMessages, selectDownloading, selectShowImport } from '../reducers/importer.reducer';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-importer',
  templateUrl: './importer-form.component.html',
  styleUrls: ['./importer-form.component.scss']
})
export class ImporterFormComponent {
  uploading$: Observable<boolean>;
  downloading$: Observable<boolean>;
  showImport$: Observable<boolean>;
  messages$: Observable<string[]>;
  file: any;
  form: FormGroup;

  constructor(
    private store: Store<State>,
    private fb: FormBuilder,
  ) {
    this.uploading$ = this.store.select(selectUploading);
    this.downloading$ = this.store.select(selectDownloading);
    this.messages$ = this.store.select(selectMessages);
    this.form = this.fb.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
    });

    this.form.valueChanges.subscribe(() => this.markFormGroupTouched(this.form));

    this.store.dispatch(new GetImportAuthority());
    this.showImport$ = this.store.select(selectShowImport);
  }

  markFormGroupTouched(group: FormGroup) {
    (<any>Object).values(group.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onSubmit(): void {
    const formData = new FormData();
    formData.append('file', this.file);
    this.store.dispatch(new ImportExcel(formData));
  }

  setFile(evt: any): void {
    this.file = evt.target.files[0];
  }

  exportFiles(): void {
    this.store.dispatch(new ExportExcel({
      from: this.form.getRawValue().from ? this.form.value.from.format('DD.MM.YYYY') : '',
      to: this.form.getRawValue().to ? this.form.value.to.format('DD.MM.YYYY') : '',
    }));
  }
}
