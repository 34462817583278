import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ROUTES } from './update.routes';
import { updateReducer } from 'app/update/reducers/update.reducer';
import { UpdateEffects } from 'app/update/effects/update.effects';
import { UpdateFormComponent } from 'app/update/form/update-form.component';
import { MaterialModule } from 'app/material.module';
import { UpdateService } from './update.service';

const components = [
  UpdateFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(ROUTES),
    RouterModule,
    StoreModule.forFeature('update', updateReducer),
    EffectsModule.forFeature([UpdateEffects])
  ],
  declarations: [...components],
  exports: [...components],
  providers: [ UpdateService ],
})
export class UpdateModule { }
