import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoginActions, LOGIN_DONE, LOGIN_ERROR, LOGIN, INVALIDATE } from 'app/login/action/login.actions';
import * as _ from 'lodash';
import { LOGOUT_DONE, LogoutActions } from 'app/login/action/logout.actions';

export interface LoginState {
  username: string;
  authorities: string[];
  loading: boolean;
  loginError: string;
  accessToken: string;
  ownId: string;
}

const initialState: LoginState = {
  username: undefined,
  authorities: [],
  loading: false,
  loginError: '',
  accessToken: '',
  ownId: ''
};

export function loginReducer(state = initialState, action: LoginActions|LogoutActions): LoginState {
  switch (action.type) {
    case LOGIN:
      return {
        ..._.cloneDeep(state),
        loading: true
      };

    case LOGIN_DONE:
      return {
        ..._.cloneDeep(state),
        username: action.payload.username,
        accessToken: action.payload.accessToken,
        authorities: action.payload.authorities.authorities,
        ownId: action.payload.authorities.id,
        loading: false,
        loginError: ''
      };

    case LOGIN_ERROR:
      return {
        ..._.cloneDeep(state),
        loginError: action.payload,
        loading: false
      };

    case INVALIDATE:
    case LOGOUT_DONE:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

// Selectors
export const selectLogin = createFeatureSelector<LoginState>('login');
export const selectLoggedIn = createSelector(selectLogin, (state: LoginState) => !!state.accessToken);
export const selectUsername = createSelector(selectLogin, (state: LoginState) => state.username);
export const selectAuthorities = createSelector(selectLogin, (state: LoginState) => state.authorities);
export const selectOwnUserId = createSelector(selectLogin, (state: LoginState) => state.ownId);
export const selectLoading = createSelector(selectLogin, (state: LoginState) => state.loading);
export const selectLoginError = createSelector(selectLogin, (state: LoginState) => state.loginError);
export const selectAccessToken = createSelector(selectLogin, (state: LoginState) => state.accessToken);
