import { Update } from 'app/update/update';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectorsFor } from 'app/reducers/crud.reducer';
import {
  CHECK_FOR_UPDATE_DONE,
  TRIGGER_UPDATE_DONE,
  FETCH_SERVER_STATUS_SUCCESS,
  FETCH_SERVER_STATUS
} from 'app/update/actions/update.actions';
import * as _ from 'lodash';

export interface UpdateState {
  selectedUpdate: Update | null;
  updateAvailable: boolean;
  updating: boolean;
}

const initialState: UpdateState = {
  selectedUpdate: null,
  updateAvailable: false,
  updating: false,
};

export function updateReducer(state = initialState, action) {
  switch (action.type) {

    case CHECK_FOR_UPDATE_DONE:
      return { ..._.cloneDeep(state), updateAvailable: action.payload.updatesPresent };

    case TRIGGER_UPDATE_DONE:
    case FETCH_SERVER_STATUS:
      return { ..._.cloneDeep(state), updating: true };

    case FETCH_SERVER_STATUS_SUCCESS:
      return { ..._.cloneDeep(state), updating: false };

    default:
      return state;
  }
}

// Selectors
export const selectUpdate = createFeatureSelector<UpdateState>('update');
export const updateSelectors = selectorsFor<Update>(selectUpdate);
export const selectUpdateAvailable = createSelector(selectUpdate, state => state.updateAvailable);
export const selectUpdating = createSelector(selectUpdate, state => state.updating);
