import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotifyService {

  constructor(private snackBar: MatSnackBar) { }

  showNotification(msg: string, cssClass?: string, action?: string, duration?: number) {
    return this.snackBar.open(msg, action, {
      panelClass: ['notification', cssClass ? cssClass : undefined],
      duration: duration ? duration : 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }

}
