import { LoginGuard } from 'app/login/login.guard';
import { UsersListComponent } from 'app/users/list/users-list.component';
import { UsersFormComponent } from './form/users-form.component';
import { AuthorityGuard } from 'app/login/authority.guard';

const ROUTE_PREFIX = 'benutzer';
export const ROUTES = [
  {
    path: `${ROUTE_PREFIX}`,
    component: UsersListComponent,
    canActivate: [LoginGuard, AuthorityGuard],
    data: { requiredAuthority: 'BENUTZER_VERWALTEN' }
  },
  {
    path: `${ROUTE_PREFIX}/neu`,
    component: UsersFormComponent,
    canActivate: [LoginGuard, AuthorityGuard],
    data: { requiredAuthority: 'BENUTZER_VERWALTEN' }
  },
  {
    path: `${ROUTE_PREFIX}/:id`,
    component: UsersFormComponent,
    canActivate: [LoginGuard, AuthorityGuard],
    data: { requiredAuthority: 'BENUTZER_VERWALTEN' }
  },
];
