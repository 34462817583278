import { FormArray, FormControl, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';

@Injectable()
export class FormArrayHelperService {
  fb: FormBuilder;

  constructor() {
    this.fb = new FormBuilder();
  }

  /**
   * Leert die FormArrays zunächst und füllt diese anschließend mit Daten
   * @param targetFormArray FormArray, das gefüllt werden soll
   * @param targetFormControl Liste an FormControls mit entsprechenden Daten
   * @param data Liste an FormControls, in die Daten eingelesen werden sollen
   */
  initFormArrayValues(targetFormArray: FormArray, data: any[], paramsToAppend?: any, validators?: any): void {
    if (!data) {
      return;
    }
    this.removeControls(targetFormArray);
    for (const entry of data) {
      this.addNewEntry(
        targetFormArray,
        paramsToAppend ? { ...entry, ...paramsToAppend } : entry,
        validators
      );
    }
  }

  /**
   * Fügt einen neuen Eintrag zu einem FormArray hinzu.
   * Ist in den Daten ein Array, wird dieses ebenfalls als FormArray hinzugefügt
   * @param array Array, zu dem hinzugefügt werden soll
   * @param data Inhalt, der in der FormControl gespeichert werden soll
   * @param validators Optionale Validatoren
   */
  addNewEntry(array: FormArray, data, validators?: any) {
    const fb = new FormBuilder();
    const entry = fb.group({});
    Object.keys(data).forEach(control => {
      if (Array.isArray(data[control])) {
        const dataObjArray = fb.array([]);
        data[control].forEach(dataObj => {
          dataObjArray.push(fb.group(dataObj));
        });
        entry.addControl(control, dataObjArray);
      } else {
        entry.addControl(control, new FormControl(data[control], (validators && validators[control]) ? validators[control] : null));
      }
    });
    array.push(entry);
  }

  /**
   * Entfernt sämtliche FormControls eines FormArrays
   * @param array Array, dessen FormControls entfernt werden sollen
   */
  removeControls(array: FormArray): FormArray {
    while (array.length) {
      array.removeAt(0);
    }
    return array;
  }

  /**
   * Initialisiert alle FormControls für nested FormArrays, falls einige Felder auf dem Server nicht gefüllt sind
   * @param data Objekt mit Daten vom Server
   * @param arrayToFill Name des nested FormArrays, dessen FormControls initialisiert werden sollen
   * @param params Objekt mit Parametern, die den hinzuzufügenden FormControls entsprechen
   */
  initFormControls(data: any, arrayToFill: string, params: any) {
    if (!data) {
      return;
    }

    const clonedData = _.cloneDeep(data);

    clonedData.forEach((entry, dataIndex) => {
      if (!entry[arrayToFill]) {
        return;
      }
      entry[arrayToFill].forEach((array, arrIndex) => {
        clonedData[dataIndex][arrayToFill][arrIndex] = { ...params, ...array };
      });
    });

    return clonedData;
  }

}
