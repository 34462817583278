import { AUTH_OPTIONS } from './auth-options.token';
import { AuthInterceptorService } from './auth-interceptor.service';
import { NgModule, Optional, SkipSelf, Provider, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule()
export class KDOAuthModule {
  constructor(@Optional() @SkipSelf() parentModule: KDOAuthModule) {
    if (parentModule) {
      throw new Error(
        `KDOAuthModule is already loaded.
        It should only be imported in your application's main module.`
      );
    }
  }

  static forRoot(options: {
    kdoOptionsProvider?: Provider;
    config?: {
      tokenGetter?: () => string | Promise<string>,
      headerName?: string,
      ignoreHeaderName?: string,
      authScheme?: string,
      throwNoTokenError?: boolean
    };
  }): ModuleWithProviders<KDOAuthModule> {
    return {
      ngModule: KDOAuthModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptorService,
          multi: true
        },
        options.kdoOptionsProvider ||
        {
          provide: AUTH_OPTIONS,
          useValue: options.config
        }
      ]
    };
  }
}
