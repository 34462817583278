import { actionTypesFor } from 'app/actions/crud.actions';
import { Action } from '@ngrx/store';
import { IRequest, RequestFilterOptions } from 'app/request/request';

export const requestActions = actionTypesFor('request');

export const SET_SELECTED = '[Request] Set Selected';
export const SET_REQUEST_FILTER = '[Request] SetFilter';
export const SAVE_AND_RELOAD_REQUEST = '[Request] Save and reload';
export const SAVE_AND_RELOAD_REQUEST_DONE = '[Request] Save and reload done';
export const SAVE_AND_RELOAD_REQUEST_ERROR = '[Request] Save and reload error';
export const GET_CORRESPONDING_INTEGRATIONPLAN = '[Request] Get corresponding IntegrationPlan';
export const GET_CORRESPONDING_INTEGRATIONPLAN_DONE = '[Request] Get corresponding IntegrationPlan Done';
export const GET_CORRESPONDING_INTEGRATIONPLAN_ERROR = '[Request] Get corresponding IntegrationPlan Error';
export const CLEAR_INTEGRATIONPLAN = '[Request] Clear Current IntegrationPlan';

export class ClearIntegrationPlan implements Action {
  readonly type = CLEAR_INTEGRATIONPLAN;
}

export class SetSelectedRequest implements Action {
  readonly type = SET_SELECTED;
  constructor(public payload: IRequest) { }
}

export class SetRequestFilter implements Action {
  readonly type = SET_REQUEST_FILTER;
  constructor(public payload: RequestFilterOptions) {}
}

export class SaveAndReloadRequest implements Action {
  readonly type = SAVE_AND_RELOAD_REQUEST;
  constructor(
    public payload: any,
    public id: string,
    public thpHinzufuegen?: boolean,
    public neuerThp?: boolean
  ) { }
}

export class SaveAndReloadRequestDone implements Action {
  readonly type = SAVE_AND_RELOAD_REQUEST_DONE;
  constructor(public payload: any, public id: string, public thpHinzufuegen?: boolean, public neuerThp?: boolean) { }
}

export class SaveAndReloadRequestError implements Action {
  readonly type = SAVE_AND_RELOAD_REQUEST_ERROR;
}

export class GetCorrespondingIntegrationPlan implements Action {
  readonly type = GET_CORRESPONDING_INTEGRATIONPLAN;
  constructor(public antrag: any) { }
}

export class GetCorrespondingIntegrationPlanDone implements Action {
  readonly type = GET_CORRESPONDING_INTEGRATIONPLAN_DONE;
  constructor(public payload: any, public antrag: any) { }
}

export class GetCorrespondingIntegrationPlanError implements Action {
  readonly type = GET_CORRESPONDING_INTEGRATIONPLAN_ERROR;
}
