import { Importer } from 'app/importer/importer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CrudState, selectorsFor, reducersFor, initialCrudState } from 'app/reducers/crud.reducer';
import {
  IMPORT_EXCEL,
  IMPORT_EXCEL_DONE,
  IMPORT_EXCEL_ERROR,
  DOWNLOAD_FILE,
  DOWNLOAD_FILE_DONE,
  DOWNLOAD_FILE_ERROR,
  EXPORT_EXCEL,
  EXPORT_EXCEL_DONE,
  EXPORT_EXCEL_ERROR,
  GET_IMPORT_AUTHORITY,
  GET_IMPORT_AUTHORITY_ERROR,
  GET_IMPORT_AUTHORITY_DONE
} from 'app/importer/actions/importer.actions';
import * as _ from 'lodash';

export interface ImporterState extends CrudState<Importer> {
  selectedImport: Importer | null;
  uploading: boolean;
  downloading: boolean;
  messages: string[];
  showImport: boolean;
}

const initialState: ImporterState = {
  selectedImport: null,
  uploading: false,
  downloading: false,
  messages: [],
  showImport: false,
  ...initialCrudState
};

export function importerReducer(state = initialState, action) {
  const crudReduced = reducersFor<Importer>('importer', state, action);

  if (crudReduced !== false) {
    return crudReduced;
  }

  switch (action.type) {
    case GET_IMPORT_AUTHORITY:
    case GET_IMPORT_AUTHORITY_ERROR:
      return { ..._.cloneDeep(state) };

    case GET_IMPORT_AUTHORITY_DONE:
      return { ..._.cloneDeep(state), showImport: action.payload?.show_import ?? false };

    case IMPORT_EXCEL:
      return { ..._.cloneDeep(state), uploading: true };

    case IMPORT_EXCEL_DONE:
      return { ..._.cloneDeep(state), uploading: false, messages: action.payload ? action.payload : [] };

    case IMPORT_EXCEL_ERROR:
      return { ..._.cloneDeep(state), uploading: false };

    case DOWNLOAD_FILE:
    case EXPORT_EXCEL:
      return { ..._.cloneDeep(state), downloading: true };

    case DOWNLOAD_FILE_DONE:
    case DOWNLOAD_FILE_ERROR:
    case EXPORT_EXCEL_DONE:
    case EXPORT_EXCEL_ERROR:
      return { ..._.cloneDeep(state), downloading: false };

    default:
      return state;
  }
}

// Selectors
export const selectImport = createFeatureSelector<ImporterState>('importer');
export const importSelectors = selectorsFor<Importer>(selectImport);
export const selectSelectedImport = createSelector(selectImport, state => state.selectedImport);
export const selectUploading = createSelector(selectImport, state => state.uploading);
export const selectDownloading = createSelector(selectImport, state => state.downloading);
export const selectMessages = createSelector(selectImport, state => state.messages);
export const selectShowImport = createSelector(selectImport, state => state.showImport);
