import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CrudHelperService } from '@kdo/ng-crud';
import { Store } from '@ngrx/store';
import { configurationSelectors } from 'app/configuration/reducers/configuration.reducer';
import { NotifyService } from 'app/notify/notify.service';
import { State } from 'app/reducers';
import { effectsFor, CrudEffects } from 'app/effects/crud.effects';
import { tap, map, switchMap, catchError, concatMap } from 'rxjs/operators';
import {
  configurationActions,
  FilterBackups,
  FILTER_BACKUPS,
  FilterBackupsDone,
  FilterBackupsError,
  RESTORE_FROM_BACKUP,
  RestoreFromBackup,
  RestoreFromBackupDone,
  RestoreFromBackupError,
  RESTORE_FROM_BACKUP_DONE,
  CREATE_NEW_BACKUP,
  CreateNewBackup,
  CreateNewBackupDone,
  CreateNewBackupError,
  CREATE_NEW_BACKUP_DONE,
  RESTORE_FROM_BACKUP_ERROR,
  CREATE_NEW_BACKUP_ERROR
} from '../actions/configuration.actions';
import { of } from 'rxjs';

const crud: CrudEffects = effectsFor(
  'configuration',
  'sysconf',
  'configuration',
  configurationSelectors
);

@Injectable()
export class ConfigurationEffects {
  constructor(
    private helper: CrudHelperService,
    private actions$: Actions,
    private notify: NotifyService,
    private store: Store<State>,
  ) { }

    readonly saveDone$ = createEffect(() => this.actions$.pipe(
      ofType<any>('SAVE_CONFIGURATION_DONE'),
      tap(() => this.notify.showNotification('Die Konfiguration wurde erfolgreich gespeichert.')),
      map(() => configurationActions.get('unique')),
    ));

    readonly filterBackups$ = createEffect(() => this.actions$.pipe(
      ofType<FilterBackups>(FILTER_BACKUPS),
      switchMap(() =>
        this.helper.getAll<any>('db/restore/list')
          .pipe(
            map(payload => new FilterBackupsDone(payload)),
            catchError(() => of(new FilterBackupsError()))
          )
      )
    ));

    readonly restoreFromBackup$ = createEffect(() => this.actions$.pipe(
      ofType<RestoreFromBackup>(RESTORE_FROM_BACKUP),
      concatMap(action =>
        this.helper.save('db/restore/' + action.backupName, {})
          .pipe(
            map(payload => new RestoreFromBackupDone(payload)),
            catchError(() => of(new RestoreFromBackupError()))
          )
      )
    ));

    readonly restoreFromBackupDone$ = createEffect(() => this.actions$.pipe(
      ofType<RestoreFromBackupDone>(RESTORE_FROM_BACKUP_DONE),
      tap(() => this.notify.showNotification('Datenbank wurde zurückgesetzt.'))
    ), { dispatch: false });

    readonly restoreFromBackupError$ = createEffect(() => this.actions$.pipe(
      ofType<RestoreFromBackupError>(RESTORE_FROM_BACKUP_ERROR),
      tap(() => this.notify.showNotification('Es ist ein Fehler beim Zurücksetzen der Datenbank aufgetreten.', 'notify-danger'))
    ));

    readonly createNewBackup$ = createEffect(() => this.actions$.pipe(
      ofType<CreateNewBackup>(CREATE_NEW_BACKUP),
      concatMap(() =>
        this.helper.save('db/backup', {}).pipe(
            map(() => new CreateNewBackupDone()),
            catchError(() => of(new CreateNewBackupError()))
      ))
    ));

    readonly createNewBackupDone$ = createEffect(() => this.actions$.pipe(
      ofType<CreateNewBackupDone>(CREATE_NEW_BACKUP_DONE),
      tap(() => this.notify.showNotification('Backup erfolgreich angelegt.')),
      map(() => new FilterBackups())
    ));

    readonly createNewBackupError$ = createEffect(() => this.actions$.pipe(
      ofType<CreateNewBackupError>(CREATE_NEW_BACKUP_ERROR),
      tap(() => this.notify.showNotification('Es ist ein Fehler beim Erstellen eines Backups aufgetreten.', 'notify-danger'))
    ), { dispatch: false });

  readonly get$ = createEffect(() => this.actions$.pipe(crud.get(this.helper)));
  readonly filter$ = createEffect(() => this.actions$.pipe(crud.filterMe(this.store, this.helper)));
  readonly save$ = createEffect(() => this.actions$.pipe(crud.save(this.helper)));
  readonly delete$ = createEffect(() => this.actions$.pipe(crud.deleteMe(this.helper)));
  readonly deleteDone$ = createEffect(() => this.actions$.pipe(crud.deleteDone(this.notify)));
}
