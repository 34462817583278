import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { Observable } from 'rxjs/Observable';
import { usersActions, DeleteUser, SetUsersFilter } from '../actions/users.actions';
import { usersSelectors } from '../reducers/users.reducer';
import { PaginatorOptions, SortOptions } from '@kdo/ng-crud';
import { UsersDataSource, UserRoles } from '../users';
import { ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import { ListHelperService } from '@kdo/ng-crud';
import * as _ from 'lodash';
import { DialogService } from '../../dialog/dialog.service';
import { selectOwnUserId } from 'app/login/reducers/login.reducer';


@Component({
  selector: 'app-users',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent {

  filter: any;
  displayedColumns = ['username', 'role', 'actions'];
  pageEvent: any;
  userRoles = UserRoles;
  loading$: Observable<boolean>;
  paginatorOps$: Observable<PaginatorOptions>;
  dataSource$: Observable<UsersDataSource>;
  sortBy$: Observable<SortOptions>;
  ownId$: Observable<string>;

  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    protected dialog: DialogService,
    public list: ListHelperService,
  ) {
    this.route.params
      .subscribe(params => {
        this.filter = params;
        store.dispatch(new SetUsersFilter(_.omit(params, ['id', 'filter'])));
        store.dispatch(usersActions.filter());
      });
    this.dataSource$ = store.select(usersSelectors.selectList)
      .pipe(map(res => new UsersDataSource(res)));
    this.paginatorOps$ = store.select(usersSelectors.selectPaginatorOptions);
    this.loading$ = store.select(usersSelectors.getLoading);
    this.sortBy$ = store.select(usersSelectors.selectListSettings).pipe(
      map(settings => JSON.parse(decodeURIComponent(settings.sort)))
    );

    this.ownId$ = this.store.select(selectOwnUserId);
  }

  onDelete(id: string, username: string): void {
    this.dialog
      .confirm('Nutzer "' + username + '" löschen', 'Sind Sie sicher?')
      .pipe(filter(response => !!response))
      .subscribe(() => {
        this.store.dispatch(new DeleteUser(id));
      });
  }

}
