import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CrudHelperService } from '@kdo/ng-crud';
import { Store } from '@ngrx/store';
import { requestSelectors } from 'app/request/reducers/request.reducer';
import { NotifyService } from 'app/notify/notify.service';
import { State } from 'app/reducers';
import { effectsFor, CrudEffects } from 'app/effects/crud.effects';
import { tap, catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  SAVE_AND_RELOAD_REQUEST,
  SaveAndReloadRequest,
  SaveAndReloadRequestDone,
  SaveAndReloadRequestError,
  SAVE_AND_RELOAD_REQUEST_DONE,
  requestActions,
  GetCorrespondingIntegrationPlan,
  GET_CORRESPONDING_INTEGRATIONPLAN,
  GetCorrespondingIntegrationPlanDone,
  GET_CORRESPONDING_INTEGRATIONPLAN_DONE
} from '../actions/request.actions';
import { Router } from '@angular/router';
import { ThvbHelperService } from 'app/shared/thvb-helper.service';

const crud: CrudEffects = effectsFor(
  'request',
  'thvbantrag',
  'antrag',
  requestSelectors
);

@Injectable()
export class RequestEffects {
  constructor(
    private helper: CrudHelperService,
    private thvbHelper: ThvbHelperService,
    private actions$: Actions,
    private notify: NotifyService,
    private store: Store<State>,
    private router: Router,
  ) { }

  readonly saveAndReload$ = createEffect(() => this.actions$.pipe(
    ofType<SaveAndReloadRequest>(SAVE_AND_RELOAD_REQUEST),
    concatMap(res => this.helper.save('thvbantrag', res.payload).pipe(
      map(payload => res.thpHinzufuegen !== undefined && res.neuerThp !== undefined
        ? new SaveAndReloadRequestDone(payload, res.id, res.thpHinzufuegen, res.neuerThp)
        : new SaveAndReloadRequestDone(payload, res.id)),
      catchError(() => of(new SaveAndReloadRequestError()))
    ))
  ));

  readonly saveAndReloadDone$ = createEffect(() => this.actions$.pipe(
    ofType<SaveAndReloadRequestDone>(SAVE_AND_RELOAD_REQUEST_DONE),
    tap(res => res.thpHinzufuegen !== undefined && res.neuerThp !== undefined
      ? this.router.navigate(['antrag/' + res.payload.id],
        { queryParams: { thpHinzufuegen: res.thpHinzufuegen, neuerThp: res.neuerThp, id: res.payload.id } })
      : this.router.navigate(['antrag/' + res.payload.id])
    ),
    tap(() => this.notify.showNotification('Der Eintrag wurde erfolgreich gespeichert.')),
    map(payload => requestActions.get(payload.id)),
  ));

  readonly deleteDone$ = createEffect(() => this.actions$.pipe(
    ofType<any>('DELETE_REQUEST_DONE'),
    tap(() => this.notify.showNotification('Der Eintrag wurde erfolgreich gelöscht.')),
    tap(() => this.router.navigate(['startseite']))
  ), { dispatch: false });

  readonly getCorrespondingIntegrationplan$ = createEffect(() => this.actions$.pipe(
    ofType<GetCorrespondingIntegrationPlan>(GET_CORRESPONDING_INTEGRATIONPLAN),
    switchMap(payload => this.helper.get('thvbteilhabeplan/antrag/' + payload.antrag.id).pipe(
      map(res => new GetCorrespondingIntegrationPlanDone(res, payload.antrag)),
      catchError(() => of({ type: `GET_REQUEST_DONE`, payload }))
    ))
  ));

  readonly getCorrespondingIntegrationPlanDone$ = createEffect(() => this.actions$.pipe(
    ofType<GetCorrespondingIntegrationPlanDone>(GET_CORRESPONDING_INTEGRATIONPLAN_DONE),
    map(payload => payload.antrag),
    map(payload => ({ type: `GET_REQUEST_DONE`, payload }))
  ));

  readonly get$ = createEffect(() => this.actions$.pipe(
    ofType<any>('GET_REQUEST'),
    map(action => action.payload),
    switchMap(payload => this.helper.get<any>('thvbantrag/' + payload)),
    map(payload => new GetCorrespondingIntegrationPlan(payload))
  ));

  readonly filter$ = createEffect(() => this.actions$.pipe(
    ofType<any>('FILTER_REQUEST'),
    withLatestFrom(this.store.select(requestSelectors.selectListSettings)),
    map(([_payload, options]) => options),
    switchMap(options =>
      this.thvbHelper.getAll<any>((options.id || options.von || options.bis) ? 'thvbantrag/suche' : 'thvbantrag', options)
        .pipe(
          map(payload => ({ type: 'FILTER_REQUEST_DONE', payload})),
          catchError(() => of({ type: 'FILTER_REQUEST_ERROR' }))
        )
    )
  ));

  readonly save$ = createEffect(() => this.actions$.pipe(crud.save(this.helper)));
  readonly delete$ = createEffect(() => this.actions$.pipe(crud.deleteMe(this.helper)));
}
