import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, map } from 'rxjs/operators';
import { State } from 'app/reducers';
import { selectAuthorities } from 'app/login/reducers/login.reducer';
import { NotifyService } from '../notify/notify.service';

@Injectable()
export class AuthorityGuard implements CanActivate {
  constructor(
    private store: Store<State>,
    private notify: NotifyService,
    private router: Router
  ) { }

  /**
   * Überprüft, ob ein Nutzer das benötigte Zugriffsrecht auf eine Route besitzt.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(selectAuthorities).pipe(
      tap(auths => {
        if (route.data.requiredAuthority && !auths.includes(route.data.requiredAuthority)) {
          this.notify.showNotification('Sie haben keine Berechtigung, auf diese Seite zuzugreifen.', 'notify-danger');
          this.router.navigate(['fehler']);
        }
      }),
      map(authorities => authorities.includes(route.data.requiredAuthority)),
    );
  }
}
