import { actionTypesFor } from 'app/actions/crud.actions';
import { Action } from '@ngrx/store';
import { Configuration } from 'app/configuration/configuration';

export const configurationActions = actionTypesFor('configuration');

export const SET_SELECTED = '[Configuration] Set Selected';
export const FILTER_BACKUPS = '[Configuration] Filter Backups';
export const FILTER_BACKUPS_DONE = '[Configuration] Filter Backups Done';
export const FILTER_BACKUPS_ERROR = '[Configuration] Filter Backups Error';
export const RESTORE_FROM_BACKUP = '[Configuration] Restore from backup';
export const RESTORE_FROM_BACKUP_DONE = '[Configuration] Restore from Backup Done';
export const RESTORE_FROM_BACKUP_ERROR = '[Configuration] Restore from Backup Error';
export const CREATE_NEW_BACKUP = '[Configuration] Create new Backup';
export const CREATE_NEW_BACKUP_DONE = '[Configuration] Create new Backup Done';
export const CREATE_NEW_BACKUP_ERROR = '[Configuration] Create new Backup Error';

export class SetSelectedConfiguration implements Action {
  readonly type = SET_SELECTED;
  constructor(public payload: Configuration) { }
}

export class FilterBackups implements Action {
  readonly type = FILTER_BACKUPS;
}

export class FilterBackupsDone implements Action {
  readonly type = FILTER_BACKUPS_DONE;
  constructor(public payload: any) { }
}

export class FilterBackupsError implements Action {
  readonly type = FILTER_BACKUPS_ERROR;
}

export class RestoreFromBackup implements Action {
  readonly type = RESTORE_FROM_BACKUP;
  constructor(public backupName: string) { }
}

export class RestoreFromBackupDone implements Action {
  readonly type = RESTORE_FROM_BACKUP_DONE;
  constructor(public res: any) { }
}

export class RestoreFromBackupError implements Action {
  readonly type = RESTORE_FROM_BACKUP_ERROR;
}

export class CreateNewBackup implements Action {
  readonly type = CREATE_NEW_BACKUP;
}

export class CreateNewBackupDone implements Action {
  readonly type = CREATE_NEW_BACKUP_DONE;
}

export class CreateNewBackupError implements Action {
  readonly type = CREATE_NEW_BACKUP_ERROR;
}
