import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { CrudHelperService } from '@kdo/ng-crud';
import { Store } from '@ngrx/store';
import { integrationPlanSelectors } from 'app/integration-plan/reducers/integration-plan.reducer';
import { NotifyService } from 'app/notify/notify.service';
import { State } from 'app/reducers';
import { effectsFor, CrudEffects } from 'app/effects/crud.effects';
import { tap, concatMap, map, catchError, filter, withLatestFrom, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  DeleteIntegrationPlan,
  DELETE_INTEGRATION_PLAN,
  DeleteIntegrationPlanDone,
  DELETE_INTEGRATION_PLAN_DONE,
  SaveIntegrationPlan,
  SAVE_INTEGRATION_PLAN,
  SaveIntegrationPlanDone,
  SAVE_INTEGRATION_PLAN_DONE,
  integrationPlanActions,
  SAVE_INTEGRATION_PLAN_ERROR
} from '../actions/integration-plan.actions';
import { ThvbHelperService } from 'app/shared/thvb-helper.service';

const crud: CrudEffects = effectsFor(
  'integrationPlan',
  'thvbteilhabeplan',
  'integrationPlan',
  integrationPlanSelectors
);

@Injectable()
export class IntegrationPlanEffects {
  constructor(
    private helper: CrudHelperService,
    private thvbHelper: ThvbHelperService,
    private actions$: Actions,
    private notify: NotifyService,
    private store: Store<State>,
    private router: Router
  ) { }

    readonly saveIntegrationPlan$ = createEffect(() => this.actions$.pipe(
      ofType<SaveIntegrationPlan>(SAVE_INTEGRATION_PLAN),
      concatMap(payload => this.helper.save('thvbteilhabeplan', payload.entity).pipe(
        map(res => new SaveIntegrationPlanDone(res)),
        catchError(() => of({ type: SAVE_INTEGRATION_PLAN_ERROR }))
      )),
    ));

    readonly saveIntegrationPlanDone$ = createEffect(() => this.actions$.pipe(
      ofType<SaveIntegrationPlanDone>(SAVE_INTEGRATION_PLAN_DONE),
      tap(() => this.notify.showNotification('Der Teilhabeplan wurde erfolgreich gespeichert.')),
      map(payload => integrationPlanActions.get(payload.entity.id)),
    ));

    readonly deleteIntegrationPlan$ = createEffect(() => this.actions$.pipe(
      ofType<DeleteIntegrationPlan>(DELETE_INTEGRATION_PLAN),
      concatMap(res => this.helper.delete('thvbteilhabeplan/' + res.id).pipe(
        map(() => new DeleteIntegrationPlanDone(res.reload)),
        catchError(() => of({ type: 'DELETE_INTEGRATIONPLAN_ERROR' }))
      )),
    ));

    readonly deleteDone$ = createEffect(() => this.actions$.pipe(
      ofType<DeleteIntegrationPlanDone>(DELETE_INTEGRATION_PLAN_DONE),
      tap(() => this.notify.showNotification('Der Teilhabeplan wurde erfolgreich gelöscht.')),
      filter(payload => payload.reload),
      tap(() => this.router.navigate(['startseite']))
    ), { dispatch: false });

    readonly filterIntegrationPlan$ = createEffect(() => this.actions$.pipe(
      ofType<any>('FILTER_INTEGRATIONPLAN'),
      withLatestFrom(this.store.select(integrationPlanSelectors.selectListSettings)),
      map(([_payload, options]) => options),
      switchMap(options =>
        this.thvbHelper.getAll<any>((options.id || options.von || options.bis) ? 'thvbteilhabeplan/suche' : 'thvbteilhabeplan', options)
          .pipe(
            map(payload => ({ type: 'FILTER_INTEGRATIONPLAN_DONE', payload})),
            catchError(() => of({ type: 'FILTER_INTEGRATIONPLAN_ERROR' }))
          )
      )
    ));

  readonly get$ = createEffect(() => this.actions$.pipe(crud.get(this.helper)));
}
