<div *ngIf="showImport$ | async">
  <mat-card class="title-card">
    <mat-card-header>
      <h1>Excel-Import</h1>
    </mat-card-header>
  </mat-card>

  <mat-card>
    <mat-progress-bar
      *ngIf="(uploading$ | async)"
      mode="indeterminate"
    ></mat-progress-bar>

    <mat-card-content>
      <button mat-stroked-button (click)="fileInput.click()">
        <input hidden
          type="file"
          #fileInput
          (change)="setFile($event)"
          accept=".xlsx"
        />
        <i class="material-icons">attach_file</i>
        Datei auswählen
      </button>

      <div *ngIf="!!file">
        <div class="form-spacer-small"></div>
        <mat-divider></mat-divider>
        <h3>Hochzuladende Datei</h3>
        <mat-list>
          <mat-list-item>
            <mat-icon mat-list-icon>description</mat-icon>
            <h4 mat-line>{{ file.name }}</h4>
            <p mat-line> {{ (file.size / 1024 / 1024).toFixed(2) }} MB </p>
            <button mat-raised-button
              (click)="onSubmit()"
              class="fix-import-button-width"
              [disabled]="!file"
              color="primary"
            >
              <i class="material-icons">publish</i>
              Import starten
            </button>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<mat-card class="title-card" *ngIf="(messages$ | async).length > 0">
  <mat-card-header>
    <h1>Ergebnisse</h1>
  </mat-card-header>
</mat-card>

<mat-card *ngIf="(messages$ | async).length > 0">
  <mat-list>
    <mat-list-item *ngFor="let message of (messages$ | async); let isLastItem = last">
      <mat-icon mat-list-icon color="primary">info</mat-icon>
      <h4 mat-line>{{ message }}</h4>
      <mat-divider *ngIf="!isLastItem"></mat-divider>
    </mat-list-item>
  </mat-list>
</mat-card>

<mat-card class="title-card">
  <mat-card-header>
    <h1>Excel-Export</h1>
  </mat-card-header>
</mat-card>

<mat-card>
  <mat-progress-bar
    *ngIf="(downloading$ | async)"
    mode="indeterminate"
  ></mat-progress-bar>

  <mat-card-content>
    <form [formGroup]="form">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
        <mat-form-field appearance="fill" fxFlex="40%">
          <input matInput
            required
            [matDatepicker]="fromPicker"
            placeholder="TT.MM.JJJJ"
            id="from"
            formControlName="from"
          >
          <mat-label>Datum von</mat-label>
          <button mat-icon-button matSuffix
            class="delete-date"
            [disabled]="form.controls.from.disabled"
            (click)="form.controls.from.reset()"
          ><i class="material-icons">clear</i></button>
          <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
          <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex="40%">
          <input matInput
            required
            [matDatepicker]="toPicker"
            [min]="form.getRawValue().from"
            placeholder="TT.MM.JJJJ"
            id="to"
            formControlName="to"
          >
          <mat-label>Datum bis</mat-label>
          <button mat-icon-button matSuffix
            class="delete-date"
            [disabled]="form.controls.to.disabled"
            (click)="form.controls.to.reset()"
          ><i class="material-icons">clear</i></button>
          <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
          <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
        <button mat-stroked-button
          [disabled]="!form.valid"
          (click)="exportFiles()"
          color="primary"
          fxFlex="20%"
        >
          <i class="material-icons">file_download</i>
          Export
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
