import { Injectable, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'app/shared/components/custom-validators';
import { ErledigungsartGesamtantrag, Weiterleitungsart } from '../request';

@Injectable()
export class AntragValidators implements OnInit {
  form: FormGroup;

  constructor() { }

  /* TODO: Klärungsbedarf: Diese Funktion wird nie aufgerufen, da Injectables
     nicht initialisiert werden. Lediglich bei Components wird `ngOnInit()`
     aufgerufen. Ist die überflüssig? Oder ist das Nicht-Aufrufen ein Bug?
  */
  ngOnInit() {
    // TODO: Es kann immer nur ein DependentValidator je Control gesetzt werden,
    // da dieser immer ALLE Validatoren setzt bzw. entfernt. Hier ist ggf. ein
    // Umbau notwendig.
    CustomValidators.dependentValidator(
      this.form.controls.datumFeststellungZustaendigkeit,
      this.form.controls.budgetBeantragt,
      control => !!control[0].value,
      Validators.required
    );

    CustomValidators.dependentValidator(
      this.form.controls.datumFeststellungZustaendigkeit,
      this.form.controls.antragWeitergeleitet,
      control => !!control[0].value,
      Validators.required
    );

    CustomValidators.dependentValidator(
      this.form.controls.datumFeststellungZustaendigkeit,
      this.form.controls.antragWeitergeleitetP14A3,
      control => !!control[0].value,
      Validators.required
    );
  }

  setForm(form: FormGroup): void {
    this.form = form;

    this.form.valueChanges.subscribe(value => {
      if (value.weiterleitung !== Weiterleitungsart.KEINE) {
        this.form.controls.datumFeststellungZustaendigkeit.disable({emitEvent: false});
        return;
      }

      const entscheidungValueSet = this.isAnyValueSet(this.entscheidung);
      const erstattungsverfahrenValueSet = this.isAnyValueSet(this.erstattungsverfahren);
      const widerspruecheValueSet = this.isAnyValueSet(this.widersprueche);
      const klagenValueSet = this.isAnyValueSet(this.klagen);
      const erstattungsantragValueSet = this.isAnyValueSet(this.erstattungsantrag);
      const gutachtenValueSet = this.isAnyValueSet(this.gutachten);

      const result = entscheidungValueSet
      || erstattungsverfahrenValueSet
      || widerspruecheValueSet
      || klagenValueSet
      || erstattungsantragValueSet
      || gutachtenValueSet;

      if (result) {
        this.form.controls.datumFeststellungZustaendigkeit.disable({emitEvent: false});
        return;
      }

      this.form.controls.datumFeststellungZustaendigkeit.enable({emitEvent: false});
    });
  }

  valueOfWeitergeleitetChanges(weitergeleitet: boolean): void {
    if (weitergeleitet) {
      this.form.patchValue({
        entscheidung: {
          datumEntscheidungGesamtantrag: this.form.getRawValue().datumFeststellungZustaendigkeit,
          erledigungsartGesamtantrag: ErledigungsartGesamtantrag.WEITERLEITUNG_NACH_P14
        }
      });
    } else {
      this.entscheidung.patchValue({ erledigungsartGesamtantrag: '' });
      this.entscheidung.controls.datumEntscheidungGesamtantrag.reset();
    }
  }

  // Wenn datumFeststellungZustaendigkeit ausgefüllt ist, muss mindestens eine Leistungsgruppe ausgewählt sein
  atLeastOneLeistungsgruppeSelected(): boolean {
    return this.form.getRawValue().erstellDatum
      && !this.form.getRawValue().leistungenMedizinischeReha
      && !this.form.getRawValue().leistungenTeilhabeArbeitsleben
      && !this.form.getRawValue().leistungenTeilhabeBildung
      && !this.form.getRawValue().leistungenSozialeTeilhabe;
  }

  // Wird nur für (De-)Aktivierung der Formularbereiche benötigt.
  weiterleitungSelected(): boolean {
    const value = this.form.getRawValue();
    return (
      value.antragWeitergeleitet
      || value.antragWeitergeleitetP14A3
      || value.entscheidung.erledigungsartGesamtantrag === ErledigungsartGesamtantrag.WEITERLEITUNG_NACH_P14
    ) || !value.datumFeststellungZustaendigkeit;
  }

  // Disabled, wenn kein Feststellungsdatum und keine Leistungsgruppe ausgewählt ist
  disableAntragWeitergeleitet(): boolean {
    const value = this.form.getRawValue();

    return (!value.leistungenMedizinischeReha
      && !value.leistungenTeilhabeArbeitsleben
      && !value.leistungenTeilhabeBildung
      && !value.leistungenSozialeTeilhabe
    )
      || !value.datumFeststellungZustaendigkeit;
  }

  private isAnyValueSet(form: AbstractControl): boolean {
    if (form instanceof FormArray) {
      return form.controls.some(c => this.isAnyValueSet(c));
    } else if (form instanceof FormGroup) {
      return Object.keys(form.controls).some(c => this.isAnyValueSet(Reflect.get(form.controls, c)));
    } else {
      return !!form.value;
    }
  }

  get entscheidung(): FormGroup {
    return this.form.controls.entscheidung as FormGroup;
  }

  get erstattungsverfahren(): FormArray {
    return this.form.get('erstattungsverfahren') as FormArray;
  }

  get widersprueche(): FormArray {
    return this.form.get('widersprueche') as FormArray;
  }

  get klagen(): FormArray {
    return this.form.get('klagen') as FormArray;
  }

  get erstattungsantrag(): FormArray {
    return this.form.get('erstattungsantrag') as FormArray;
  }

  get gutachten(): FormArray {
    return this.form.get('gutachten') as FormArray;
  }
}
