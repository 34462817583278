import { Users } from 'app/users/users';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CrudState, selectorsFor, reducersFor } from 'app/reducers/crud.reducer';
import {
  SET_SELECTED,
  SET_USERS_FILTER,
  SetUsersFilter
} from 'app/users/actions/users.actions';
import * as _ from 'lodash';

export interface UsersState extends CrudState<Users> {
  roles: any | null;
  availableRoles: any | null;
}

const initialState: UsersState = {
  roles: [],
  availableRoles: [],
  list: [],
  currentEntity: {},
  listSettings: {
    page: 0,
    sort: encodeURIComponent(JSON.stringify({
      active: 'username',
      direction: 'asc',
    })),
    size: 10
  },
  paginatorOptions: {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
  },
  loading: false
};

export function usersReducer(state = initialState, action) {
  const crudReduced = reducersFor<Users>('users', state, action);

  if (crudReduced !== false) {
    return crudReduced;
  }

  switch (action.type) {
    case SET_SELECTED:
      return { ..._.cloneDeep(state), selectedUsers: action.payload };

    case SET_USERS_FILTER:
      return setFilter(state, action);

    default:
      return state;
  }
}

function setFilter(state: UsersState, action: SetUsersFilter): UsersState {

  const mergedSettings = _.merge({}, initialState.listSettings, action.payload);

  return {
    ..._.cloneDeep(state),
    listSettings: _.isEmpty(action.payload) ? initialState.listSettings : mergedSettings
  };
}

// Selectors
export const selectUsers = createFeatureSelector<UsersState>('users');
export const usersSelectors = selectorsFor<Users>(selectUsers);
export const selectUsersRoles = createSelector(selectUsers, state => state.roles);
export const selectAvailableRoles = createSelector(selectUsers, state => state.availableRoles);
