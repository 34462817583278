import { Injectable, OnInit } from '@angular/core';
import { FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Erledigungsarten, Leistungsarten } from '../request';
import * as moment from 'moment';
import { CustomValidators } from 'app/shared/components/custom-validators';

@Injectable()
export class EntscheidungValidators implements OnInit {
  form: FormGroup;
  erledigungsarten = Erledigungsarten;
  leistungsarten = Leistungsarten;

  constructor() { }

  ngOnInit() {
    CustomValidators.dependentValidator(
      this.entscheidung.controls.datumBewilligungsbescheid,
      this.entscheidung.controls.datumLeistungsbeginn,
      control => !!control[0].value,
      Validators.required
    );
  }

  setForm(form: FormGroup): void {
    this.form = form;
  }

  valueOfErledigungsartGesamtantragChanges(art) {
    if (art === 'VOLLSTAENDIGE_ABLEHNUNG') {
      this.erstattungsverfahren.controls.forEach(control => {
        control.disable();
        control.updateValueAndValidity();
      });
    } else {
      this.erstattungsverfahren.controls.forEach(control => {
        control.enable();
        control.updateValueAndValidity();
      });
    }
    if (!!art) {
      this.entscheidung.controls.datumEntscheidungLMR.disable({ emitEvent: false });
      this.entscheidung.controls.erledigungsartLMR.disable({ emitEvent: false });
      this.entscheidung.controls.datumEntscheidungLTA.disable({ emitEvent: false });
      this.entscheidung.controls.erledigungsartLTA.disable({ emitEvent: false });
      this.entscheidung.controls.datumEntscheidungLTB.disable({ emitEvent: false });
      this.entscheidung.controls.erledigungsartLTB.disable({ emitEvent: false });
      this.entscheidung.controls.datumEntscheidungLST.disable({ emitEvent: false });
      this.entscheidung.controls.erledigungsartLST.disable({ emitEvent: false });
    } else {
      this.toggleDisabled(this.entscheidung.controls.datumEntscheidungLMR, this.form.getRawValue().leistungenMedizinischeReha);
      this.toggleDisabled(this.entscheidung.controls.erledigungsartLMR, this.form.getRawValue().leistungenMedizinischeReha);
      this.toggleDisabled(this.entscheidung.controls.datumEntscheidungLTA, this.form.getRawValue().leistungenTeilhabeArbeitsleben);
      this.toggleDisabled(this.entscheidung.controls.erledigungsartLTA, this.form.getRawValue().leistungenTeilhabeArbeitsleben);
      this.toggleDisabled(this.entscheidung.controls.datumEntscheidungLTB, this.form.getRawValue().leistungenTeilhabeBildung);
      this.toggleDisabled(this.entscheidung.controls.erledigungsartLTB, this.form.getRawValue().leistungenTeilhabeBildung);
      this.toggleDisabled(this.entscheidung.controls.datumEntscheidungLST, this.form.getRawValue().leistungenSozialeTeilhabe);
      this.toggleDisabled(this.entscheidung.controls.erledigungsartLST, this.form.getRawValue().leistungenSozialeTeilhabe);
    }
  }

  toggleDisabled(control: AbstractControl, condition: boolean) {
    if (condition) {
      control.enable({ emitEvent: false });
    } else {
      control.disable({ emitEvent: false });
    }
  }

  /**
   * Wenn im Abschnitt Antrag eine Leistungsgruppe ausgewählt wurde, muss in der
   * Erledigungsmaske ein entsprechendes Erledigungsdatum eingetragen werden.
   */
  disableErledigungsDatum(): boolean {
    const lmr = this.form.getRawValue().leistungenMedizinischeReha;
    const lmrDate = this.form.getRawValue().entscheidung.datumEntscheidungLMR;
    const lmrArt = this.form.getRawValue().entscheidung.erledigungsartLMR;
    const lta = this.form.getRawValue().leistungenTeilhabeArbeitsleben;
    const ltaDate = this.form.getRawValue().entscheidung.datumEntscheidungLTA;
    const ltaArt = this.form.getRawValue().entscheidung.erledigungsartLTA;
    const ltb = this.form.getRawValue().leistungenTeilhabeBildung;
    const ltbDate = this.form.getRawValue().entscheidung.datumEntscheidungLTB;
    const ltbArt = this.form.getRawValue().entscheidung.erledigungsartLTB;
    const lst = this.form.getRawValue().leistungenSozialeTeilhabe;
    const lstDate = this.form.getRawValue().entscheidung.datumEntscheidungLST;
    const lstArt = this.form.getRawValue().entscheidung.erledigungsartLST;
    if (
      (lmr ? !!lmrDate && !!lmrArt : true)
      && (lta ? !!ltaDate && !!ltaArt : true)
      && (ltb ? !!ltbDate && !!ltbArt : true)
      && (lst ? !!lstDate && !!lstArt : true)
    ) {
      return false;
    } else {
      return true;
    }
  }

  erledigungsArtSelected(): boolean {
    return !!this.entscheidung.value.erledigungsartGesamtantrag
      && (this.entscheidung.value.erledigungsartGesamtantrag !== 'VOLLSTAENDIGE_BEWILLIGUNG'
      && this.entscheidung.value.erledigungsartGesamtantrag !== 'NICHT_VOLLSTAENDIGE_ABLEHNUNG')
    ;
  }

  requireDatumEntscheidungGesamtantrag(): boolean {
    const lmr = this.form.getRawValue().leistungenMedizinischeReha;
    const lmrDate = this.form.getRawValue().entscheidung.datumEntscheidungLMR;
    const lmrArt = this.form.getRawValue().entscheidung.erledigungsartLMR;
    const lta = this.form.getRawValue().leistungenTeilhabeArbeitsleben;
    const ltaDate = this.form.getRawValue().entscheidung.datumEntscheidungLTA;
    const ltaArt = this.form.getRawValue().entscheidung.erledigungsartLTA;
    const ltb = this.form.getRawValue().leistungenTeilhabeBildung;
    const ltbDate = this.form.getRawValue().entscheidung.datumEntscheidungLTB;
    const ltbArt = this.form.getRawValue().entscheidung.erledigungsartLTB;
    const lst = this.form.getRawValue().leistungenSozialeTeilhabe;
    const lstDate = this.form.getRawValue().entscheidung.datumEntscheidungLST;
    const lstArt = this.form.getRawValue().entscheidung.erledigungsartLST;
    if (
      lmr && lmrArt &&
      lta && ltaArt &&
      ltb && ltbArt &&
      lst && lstArt
    ) {
      this.entscheidung.controls.datumEntscheidungGesamtantrag.setValidators(Validators.required);
      this.entscheidung.controls.erledigungsartGesamtantrag.setValidators(Validators.required);
      return true;
    } else if (
      (lmr ? !!lmrDate && !!lmrArt : true)
      && (lta ? !!ltaDate && !!ltaArt : true)
      && (ltb ? !!ltbDate && !!ltbArt : true)
      && (lst ? !!lstDate && !!lstArt : true)
    ) {
      this.entscheidung.controls.datumEntscheidungGesamtantrag.enable();
      this.entscheidung.controls.erledigungsartGesamtantrag.enable();
      this.entscheidung.controls.datumEntscheidungGesamtantrag.setValidators(Validators.required);
      this.entscheidung.controls.erledigungsartGesamtantrag.setValidators(Validators.required);
      return true;
    } else {
      this.entscheidung.controls.datumEntscheidungGesamtantrag.disable();
      this.entscheidung.controls.erledigungsartGesamtantrag.disable();
      this.entscheidung.controls.datumEntscheidungGesamtantrag.clearValidators();
      this.entscheidung.controls.erledigungsartGesamtantrag.clearValidators();
      return false;
    }
  }

  getMinDateLeistungsbeginn(): moment.Moment {
    if (moment(this.form.getRawValue().erstellDatum).isBefore('2021-01-01')) {
      return this.form.getRawValue().datumFeststellungZustaendigkeit;
    } else {
      return moment(this.form.getRawValue().erstellDatum).startOf('month');
    }
  }

  disableEntscheidungen(): boolean {
    return this.form.getRawValue().entscheidung.erledigungsartGesamtantrag === 'VOLLSTAENDIGE_BEWILLIGUNG'
      || this.form.getRawValue().entscheidung.erledigungsartGesamtantrag === 'VOLLSTAENDIGE_ABLEHNUNG'
      || this.form.getRawValue().entscheidung.erledigungsartGesamtantrag === 'SONSTIGE'
    ;
  }

  requireDatumErledigung(art: string): boolean {
    let dependentControl: string;
    switch (art) {
      case this.erledigungsarten.LMR:
        dependentControl = this.leistungsarten.LMR;
        break;

      case 'erledigungsartLTA':
        dependentControl = this.leistungsarten.LTA;
        break;

      case 'erledigungsartLTB':
        dependentControl = this.leistungsarten.LTB;
        break;

      case 'erledigungsartLST':
        dependentControl = this.leistungsarten.LST;
        break;
    }
    return !!this.form.getRawValue()[dependentControl]
      ? (!!this.entscheidung.value.datumEntscheidungGesamtantrag || !!this.entscheidung.value[art])
      : false
    ;
  }

  requireArtErledigung(datum: string): boolean {
    let dependentControl: string;
    switch (datum) {
      case 'datumEntscheidungLMR':
        dependentControl = 'erledigungsartLMR';
        break;

      case 'datumEntscheidungLTA':
        dependentControl = this.leistungsarten.LTA;
        break;

      case 'datumEntscheidungLTB':
        dependentControl = this.leistungsarten.LTB;
        break;

      case 'datumEntscheidungLST':
        dependentControl = this.leistungsarten.LST;
        break;
    }

    return !!this.form.getRawValue().entscheidung[datum]
      ? !this.entscheidung.value[dependentControl]
      : false
    ;
  }

  disableBudgetBewilligt(): boolean {
    const lmrArt = this.form.getRawValue().entscheidung.erledigungsartLMR;
    const ltaArt = this.form.getRawValue().entscheidung.erledigungsartLTA;
    const ltbArt = this.form.getRawValue().entscheidung.erledigungsartLTB;
    const lstArt = this.form.getRawValue().entscheidung.erledigungsartLST;

    const artIsBewilligt =
      lmrArt === 'NICHT_VOLLSTAENDIGE_ABLEHNUNG' || lmrArt === 'VOLLSTAENDIGE_BEWILLIGUNG' || lmrArt === 'SONSTIGE'
      || ltaArt === 'NICHT_VOLLSTAENDIGE_ABLEHNUNG' || ltaArt === 'VOLLSTAENDIGE_BEWILLIGUNG' || ltaArt === 'SONSTIGE'
      || ltbArt === 'NICHT_VOLLSTAENDIGE_ABLEHNUNG' || ltbArt === 'VOLLSTAENDIGE_BEWILLIGUNG' || ltbArt === 'SONSTIGE'
      || lstArt === 'NICHT_VOLLSTAENDIGE_ABLEHNUNG' || lstArt === 'VOLLSTAENDIGE_BEWILLIGUNG' || lstArt === 'SONSTIGE'
    ;

    const retVal = this.form.getRawValue().entscheidung.erledigungsartGesamtantrag === 'VOLLSTAENDIGE_ABLEHNUNG'
      || this.form.getRawValue().entscheidung.erledigungsartGesamtantrag === 'WEITERLEITUNG_NACH_P14'
      || !artIsBewilligt
    ;
    if (retVal) {
      (<FormGroup>this.form.controls.entscheidung).controls.budgetBewilligt.patchValue('');
    }
    return retVal;
  }

  /**
   * Gibt das am weitesten in der Zukunft liegende Datum zurück
   */
  getDatumEntscheidungGesamtantragMinDate(): moment.Moment {
    const dates = [this.form.getRawValue().datumFeststellungZustaendigkeit];
    this.gutachten.controls.forEach(control => {
      dates.push(moment(control.value.datumAuftrag));
    });
    return moment.max(dates);
  }

  /**
   * Gibt das am weitesten in der Zukunft liegende Erledigungsdatum zurück
   */
  getMaxErledigungsDatum(): moment.Moment {
    const erledigungDates = [];
    if (this.form.getRawValue().datumFeststellungZustaendigkeit) {
      erledigungDates.push(moment(this.form.getRawValue().datumFeststellungZustaendigkeit));
    }
    if (this.entscheidung.getRawValue().datumEntscheidungLMR) {
      erledigungDates.push(moment(this.entscheidung.getRawValue().datumEntscheidungLMR));
    }
    if (this.entscheidung.getRawValue().datumEntscheidungLTA) {
      erledigungDates.push(moment(this.entscheidung.getRawValue().datumEntscheidungLTA));
    }
    if (this.entscheidung.getRawValue().datumEntscheidungLTB) {
      erledigungDates.push(moment(this.entscheidung.getRawValue().datumEntscheidungLTB));
    }
    if (this.entscheidung.getRawValue().datumEntscheidungLST) {
      erledigungDates.push(moment(this.entscheidung.getRawValue().datumEntscheidungLST));
    }
    return moment.max(erledigungDates);
  }

  get entscheidung(): FormGroup {
    return this.form.controls.entscheidung as FormGroup;
  }

  get erstattungsverfahren(): FormArray {
    return this.form.get('erstattungsverfahren') as FormArray;
  }

  get gutachten(): FormArray {
    return this.form.get('gutachten') as FormArray;
  }
}
