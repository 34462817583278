import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Injectable()
export class ErstattungsverfahrenValidators {
  form: FormGroup;

  constructor() { }

  setForm(form: FormGroup): void {
    this.form = form;
  }

  canBeAdded(): boolean {
    return this.canBeSet()
      && (this.erstattungsverfahren.length ? !!this.getLastEntryValue(this.erstattungsverfahren, 'datumVerfahren') : true);
  }

  canBeSet(): boolean {
    return this.entscheidung.getRawValue().erledigungsartGesamtantrag !== 'VOLLSTAENDIGE_ABLEHNUNG';
  }

  // Gibt das Value eines gewünschten FormControls aus der letzten FormGroup eines FormArrays wieder
  // TODO: Refactorn. Diese Methode existiert mit dem selben Inhalt in drei Dateien.
  private getLastEntryValue(array: FormArray, control: string) {
    return array.length ? (<FormGroup>array.controls[array.length - 1]).controls[control].value : undefined;
  }

  mustBeUnset(): boolean {
    return !this.canBeSet() && !!this.erstattungsverfahren.length;
  }

  get entscheidung(): FormGroup {
    return this.form.controls.entscheidung as FormGroup;
  }

  get erstattungsverfahren(): FormArray {
    return this.form.get('erstattungsverfahren') as FormArray;
  }

}
