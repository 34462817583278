import { Component } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { selectUpdating } from '../reducers/update.reducer';
import { TriggerUpdate } from '../actions/update.actions';

@Component({
  selector: 'app-update',
  templateUrl: './update-form.component.html',
  styleUrls: ['./update-form.component.scss']
})
export class UpdateFormComponent {

  content: string;
  updating$: Observable<boolean>;

  constructor(
    private store: Store<State>,
  ) {
    this.updating$ = this.store.select(selectUpdating);
  }

  triggerUpdate() {
    this.store.dispatch(new TriggerUpdate());
  }
}
