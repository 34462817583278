import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from './reducers';
import { Subscription, timer, Observable } from 'rxjs';
import { CheckForUpdate } from './update/actions/update.actions';
import { selectUpdating } from './update/reducers/update.reducer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  timer: Subscription;
  updating$: Observable<boolean>;

  constructor(
    private store: Store<State>,
  ) {
    // Frage (ergänzend zur Prüfung unmittelbar nach Login) alle 5 Minuten, ob ein Update verfügbar ist
    this.timer = timer(60 * 1000 * 5, 60 * 1000 * 5).subscribe(() => {
      this.store.dispatch(new CheckForUpdate());
    });

    this.updating$ = this.store.select(selectUpdating);
  }
}
