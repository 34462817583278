import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseUrlGetter } from 'app/config-getter';
import { Observable , from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class LoginService {

  constructor(private http: HttpClient) { }

  public token(username: string, password: string): Observable<any> {
    return from(baseUrlGetter())
      .pipe(
        mergeMap(baseUrlAsync => this.http.post<any>(
          baseUrlAsync + 'oauth/token',
          this.createOAuthData(username, password), {
            headers: this.createOAuthHeader()
          }
        ))
      );
  }

  public me(token: string): Observable<string[]> {
    return from(baseUrlGetter())
      .pipe(
        mergeMap(baseUrlAsync => this.http.get<string[]>(
          baseUrlAsync + 'rest/users/me', {
            headers: this.createMeHeader(token)
          }
        ))
      );
  }

  public logout() {
    return from(baseUrlGetter())
      .pipe(
        mergeMap(baseUrlAsync => this.http.post<any>(
          baseUrlAsync + 'rest/users/logout', {
            responseType: 'text'
          }
        ))
      );
  }

  private createOAuthHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic d2ViY2xpZW50OndlYmNsaWVudF9zZWNyZXQ=',
      // "webclient:webclient_secret" als Base64.
      'X-KDO-NO-CONTENT-TYPE': 'true',
      'X-KDO-NO-AUTH': 'true'
    });
  }

  private createMeHeader(token: string) {
    return new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'X-KDO-NO-AUTH': 'true'
    });
  }

  private createOAuthData(username: string, password: string): string {
    const oAuthData = {
      client_id: 'webclient',
      client_secret: 'webclient_secret',
      grant_type: 'password',
      scope: 'thvb-scope',
      username,
      password
    };

    return Object.keys(oAuthData)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(oAuthData[key]))
      .join('&');
  }

}
