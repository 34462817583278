import * as _ from 'lodash';
import { createSelector } from '@ngrx/store';
import { FilterOptions, PaginatorOptions } from '@kdo/ng-crud';

export interface CrudState<T> {
  list: T[];
  currentEntity: any;
  listSettings: FilterOptions;
  paginatorOptions: PaginatorOptions;
  loading: boolean;
}

export const initialCrudState = {
  list: [],
  currentEntity: {},
  listSettings: {
    page: 0,
    filter: [],
    sort: encodeURIComponent(JSON.stringify({
      active: 'name',
      direction: 'asc',
    })),
    size: 10
  },
  paginatorOptions: {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
  },
  loading: false
};

export function reducersFor<T>(entity: string, state: CrudState<T> = initialCrudState, action) {
  switch (action.type) {
    case 'FILTER_' + entity.toUpperCase() + '_DONE':
    return {
      ..._.cloneDeep(state),
      list: action.payload.content,
      paginatorOptions: {
        length: action.payload.totalElements,
        pageIndex: action.payload.number,
        pageSize: action.payload.size,
      },
      loading: false,
    };

    case 'SAVE_' + entity.toUpperCase():
      return { ..._.cloneDeep(state), currentEntity: action.payload, loading: true };

    case 'DELETE_' + entity.toUpperCase():
      return { ..._.cloneDeep(state), loading: true };

    case 'SAVE_' + entity.toUpperCase() + '_DONE':
    case 'SAVE_' + entity.toUpperCase() + '_ERROR':
    case 'DELETE_' + entity.toUpperCase() + '_DONE':
    case 'DELETE_' + entity.toUpperCase() + '_ERROR':
      return { ..._.cloneDeep(state), loading: false };

    case 'FILTER_' + entity.toUpperCase() + '_ERROR':
      return { ..._.cloneDeep(state), loading: false };

    case 'GET_' + entity.toUpperCase():
      return { ..._.cloneDeep(state), currentEntity: {} };

    case 'SET_' + entity.toUpperCase():
    case 'GET_' + entity.toUpperCase() + '_DONE':
      return { ..._.cloneDeep(state), currentEntity: action.payload};

    case 'FILTER_' + entity.toUpperCase():
      return { ...state, loading: true };

    case 'SET_' + entity.toUpperCase() + '_FILTER':
      return setFilter(state, action);

    default:
      return false;
  }
}

function setFilter(state, action) {
  const mergedSettings = _.merge({}, initialCrudState.listSettings, action.payload);

  return {
    ..._.cloneDeep(state),
    listSettings: _.isEmpty(action.payload) ? initialCrudState.listSettings : mergedSettings
  };
}

// Selectors

export interface CrudSelectors {
  selectList: any;
  selectCurrent: any;
  selectListSettings: any;
  selectPaginatorOptions: any;
  getLoading: any;
}

export function selectorsFor<T>(featureSelector): CrudSelectors {
  return {
    selectList: createSelector(featureSelector, (state: CrudState<T>) => state.list),
    selectCurrent: createSelector(featureSelector, (state: CrudState<T>) => state.currentEntity),
    selectListSettings: createSelector(featureSelector, (state: CrudState<T>) => state.listSettings),
    selectPaginatorOptions: createSelector(featureSelector, (state: CrudState<T>) => state.paginatorOptions),
    getLoading: createSelector(featureSelector, (state: CrudState<T>) => state.loading)
  };
}



