<mat-toolbar class="mat-elevation-z1">
  <button
    type="button"
    mat-button
    (click)="toggle()"
    *ngIf="loggedIn$ | async"
    matTooltip="Seitenmenü"
  >
    <mat-icon class="material-icons">menu</mat-icon>
  </button>
  <button mat-button
    [routerLink]="['/']"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{exact: true}"
  >
    <h3>THVB-Erfassungstool (TET)</h3>
  </button>

  <span class="toolbar-spacer"></span>

  <a target="_blank"
    href="https://www.bar-frankfurt.de/fileadmin/dateiliste/THVB/THVB_TET_Benutzerhandbuch.pdf"
    rel="noopener"
  >
    <button mat-button
      *ngIf="loggedIn$ | async"
      class="toolbar-icon"
      matTooltip="Hilfe"
    >
      <mat-icon class="material-icons">help_outline</mat-icon>
    </button>
  </a>

  <button
    mat-button
    class="toolbar-icon"
    *ngIf="loggedIn$ | async"
    [ngClass]="(updateAvailable$ | async) ? 'update-button' : ''"
    [routerLink]="(updateAvailable$ | async) ? 'update' : null"
  >
    <mat-icon class="material-icons">info_outline</mat-icon>
    Version: {{ version$ | async }}
  </button>

  <button
    mat-button
    class="toolbar-icon"
    [matMenuTriggerFor]="userMenu"
    *ngIf="loggedIn$ | async"
  >
    <mat-icon class="material-icons">account_circle</mat-icon>
    {{ username$ | async }}
  </button>

  <mat-menu #userMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="below">
    <button mat-menu-item (click)="showForm()">
      <mat-icon class="material-icons">person</mat-icon> Eigene Daten
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon class="material-icons">exit_to_app</mat-icon> Logout
    </button>
  </mat-menu>
</mat-toolbar>
