import { LoginGuard } from 'app/login/login.guard';
import { ResponsibilityFormComponent } from 'app/responsibility/form/responsibility-form.component';
import { ResponsibilityListComponent } from './list/responsibility-list.component';
import { AuthorityGuard } from 'app/login/authority.guard';

const ROUTE_PREFIX = 'traeger';
export const ROUTES = [
  {
    path: `${ROUTE_PREFIX}`,
    component: ResponsibilityListComponent,
    canActivate: [LoginGuard, AuthorityGuard],
    data: { requiredAuthority: 'ADMIN_BEREICH_LOKAL' }
  },
  {
    path: `${ROUTE_PREFIX}/neu`,
    component: ResponsibilityFormComponent,
    canActivate: [LoginGuard, AuthorityGuard],
    data: { requiredAuthority: 'ADMIN_BEREICH_LOKAL' },
    canDeactivate: ['CanDeactivateResponsibility']
  },
  {
    path: `${ROUTE_PREFIX}/:id`,
    component: ResponsibilityFormComponent,
    canActivate: [LoginGuard, AuthorityGuard],
    data: { requiredAuthority: 'ADMIN_BEREICH_LOKAL' },
    canDeactivate: ['CanDeactivateResponsibility']
  }
];
