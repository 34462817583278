import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { Observable } from 'rxjs/Observable';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { responsibilityActions, GetOwnID } from '../actions/responsibility.actions';
import { ActivatedRoute } from '@angular/router';
import { tap, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Responsibility, States } from '../responsibility';
import { responsibilitySelectors, selectOwnID } from '../reducers/responsibility.reducer';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-responsibility',
  templateUrl: './responsibility-form.component.html',
  styleUrls: ['./responsibility-form.component.scss']
})
export class ResponsibilityFormComponent implements OnDestroy {

  form: FormGroup;
  filter: any;
  curResponsibilityObject: any;
  states = States;
  object = Object;
  loading$: Observable<boolean>;
  responsibility$: Observable<Responsibility>;
  hasOwnID$: Observable<boolean>;
  subscription = new Subscription();

  constructor(
    private store: Store<State>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
  ) {
    this.form = this.fb.group({
      traegerID: ['', Validators.pattern('[1-9]+[0-9]*')],
      name: '',
      bundesland: ['', Validators.required],
      eigeneID: false,
      inaktiv: false,
    });

    this.route.params
      .pipe(
        tap(params => this.filter = params),
        filter(params => params.id),
        map(params => params.id),
        distinctUntilChanged()
      )
      .subscribe(id => this.store.dispatch(responsibilityActions.get(id)));

    this.route.params
      .pipe(
        filter(params => !params.id),
        distinctUntilChanged()
      )
      .subscribe(() => this.store.dispatch(responsibilityActions.set({})));

    this.responsibility$ = this.store.select(responsibilitySelectors.selectCurrent);


    this.responsibility$.pipe(
      filter(data => !!data))
    .subscribe(data => {
      this.form.reset(this.form.value);
      this.form.patchValue(data);
      this.curResponsibilityObject = _.cloneDeep(data);
    });

    this.store.dispatch(new GetOwnID());
    this.hasOwnID$ = this.store.select(selectOwnID);

    this.subscription.add(
      this.form.valueChanges.subscribe(() => this.markFormGroupTouched(this.form))
    );
  }

  markFormGroupTouched(group: FormGroup) {
    (<any>Object).values(group.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  onSave() {
    this.store.dispatch(responsibilityActions.save({ ...this.curResponsibilityObject, ...this.form.value }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
