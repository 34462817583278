import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatorOptions, ListHelperService } from '@kdo/ng-crud';
import * as _ from 'lodash';
import { SetRequestFilter, requestActions } from 'app/request/actions/request.actions';
import { requestSelectors } from 'app/request/reducers/request.reducer';
import { RequestDataSource } from 'app/request/request';
import { IntegrationPlanDataSource } from 'app/integration-plan/integration-plan';
import * as moment from 'moment';
import { integrationPlanActions, SetIntegrationPlanFilter } from 'app/integration-plan/actions/integration-plan.actions';
import { integrationPlanSelectors } from 'app/integration-plan/reducers/integration-plan.reducer';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  displayedColumns: string[] = ['thvbID', 'erstellDatum'];
  displayedColumnsThp: string[] = ['thvbID', 'erstellDatum'];
  form: FormGroup;
  toggleResults = false;
  filter: any;
  pageEvent: any;
  sortByRequest = {
    active: 'thvbID',
    direction: 'asc',
  };
  sortByIntegrationPlan = {
    active: 'thvbID',
    direction: 'asc',
  };
  currentSearchEvent: string;

  dataSourceRequest$: Observable<RequestDataSource>;
  paginatorOpsRequest$: Observable<PaginatorOptions>;
  dataSourceIntegrationPlan$: Observable<IntegrationPlanDataSource>;
  paginatorOpsIntegrationPlan$: Observable<PaginatorOptions>;
  sortByIntegrationPlan$: Observable<string>;

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    public list: ListHelperService,
  ) {
    this.form = this.fb.group({
      id: '',
      zeitraumVon: '',
      zeitraumBis: '',
    });

    this.route.params
    .subscribe(params => {
      this.filter = params;
      if (params.suche) {
        this.form.patchValue({
          id: params.suche
        });
      }
      if (params.request) {
        this.store.dispatch(new SetRequestFilter(_.omit(JSON.parse(params.request), ['id'])));
      }
      if (params.integrationPlan) {
        this.store.dispatch(new SetIntegrationPlanFilter(_.omit(JSON.parse(params.integrationPlan), ['id'])));
      }
      this.store.dispatch(requestActions.filter());
      this.store.dispatch(integrationPlanActions.filter());
    });

    // Antrag
    this.dataSourceRequest$ = this.store.select(requestSelectors.selectList);
    this.paginatorOpsRequest$ = this.store.select(requestSelectors.selectPaginatorOptions);
    this.store.select(requestSelectors.selectListSettings).pipe(
      filter(value => !!value),
      first()
    ).subscribe(listSettings => {
      this.form.patchValue({
        id: listSettings.id,
        zeitraumVon: listSettings.von ? moment(listSettings.von, 'DD.MM.YYYY') : '',
        zeitraumBis: listSettings.bis ? moment(listSettings.bis, 'DD.MM.YYYY') : ''
      });
    });

    // Teilhabeplan
    this.dataSourceIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectList);
    this.paginatorOpsIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectPaginatorOptions);
    this.sortByIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectListSettings).pipe(
      map(settings => JSON.parse(decodeURIComponent(settings.sort)))
    );
  }

  search(evt: any, enterKeyPressed: boolean, buttonPressed: boolean): void {
    this.currentSearchEvent = evt;
    if (!buttonPressed && (!evt || evt.which > 90 && evt.which < 48)) {
      // Wenn gedrückte Taste nicht Buchstabe, Zahl, Sonderzeichen oder Entertaste ist
      return;
    }
    if (enterKeyPressed) {
      evt.preventDefault();
    } else if (!buttonPressed && evt.target.value.length > 0 && evt.target.value.length < 3) {
      return;
    }
    this.reinitDataSource(true);
    this.reinitDataSource(false);
  }

  clearSearch(): void {
    this.form.reset();
    this.reinitDataSource(true);
    this.reinitDataSource(false);
    this.router.navigate(['/startseite']);
  }

  // Gibt aktuelles form-value im korrekten (Server-)Format zurück
  getSearchEntry() {
    return {
      id: this.form.getRawValue().id,
      von: this.formatDate(this.form.getRawValue().zeitraumVon),
      bis: this.formatDate(this.form.getRawValue().zeitraumBis)
    };
  }

  formatDate(date: String): String {
    if (!!date) {
      if (moment.isMoment(date)) {
        return date.format('DD.MM.YYYY');
      } else {
        return date;
      }
    } else {
      return '';
    }
  }

  toggleSort(isRequest: boolean, evt: any): void {
    if (isRequest) {
      this.sortByRequest = evt;
      this.reinitDataSource(isRequest, { sort: encodeURIComponent(JSON.stringify(this.sortByRequest)) });
    } else {
      this.sortByIntegrationPlan = evt;
      this.reinitDataSource(isRequest, { sort: encodeURIComponent(JSON.stringify(this.sortByIntegrationPlan)) });
    }
  }

  onPaginateChange(isRequest: boolean, evt: any): void {
    const currentParams = this.list.encodeStateForUrl({
      size: evt.pageSize ? evt.pageSize : {},
      page: evt.pageIndex ? evt.pageIndex : {},
      sort: encodeURIComponent(JSON.stringify(isRequest ? this.sortByRequest : this.sortByIntegrationPlan))
    });
    const newParams = {};
    newParams[isRequest ? 'request' : 'integrationPlan'] = JSON.stringify(currentParams);
    this.router.navigate(['startseite', newParams]);
    this.reinitDataSource(isRequest, newParams);
  }

  reinitDataSource(isRequest: boolean, newParams?: any) {
    if (isRequest) {
      this.store.dispatch(new SetRequestFilter({ ...newParams, ...this.getSearchEntry() }));
      this.store.dispatch(requestActions.filter());
      this.dataSourceRequest$ = this.store.select(requestSelectors.selectList);
      this.paginatorOpsRequest$ = this.store.select(requestSelectors.selectPaginatorOptions);
    } else {
      this.store.dispatch(new SetIntegrationPlanFilter({ ...newParams, ...this.getSearchEntry() }));
      this.store.dispatch(integrationPlanActions.filter());
      this.dataSourceIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectList);
      this.paginatorOpsIntegrationPlan$ = this.store.select(integrationPlanSelectors.selectPaginatorOptions);
    }
  }

}
