import { LoginGuard } from 'app/login/login.guard';
import { ImporterFormComponent } from 'app/importer/form/importer-form.component';

const ROUTE_PREFIX = 'import';
export const ROUTES = [
  {
    path: `${ROUTE_PREFIX}`,
    component: ImporterFormComponent,
    canActivate: [LoginGuard]
  }
];
