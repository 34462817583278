<mat-card class="title-card">
  <mat-card-header>
    <h1>Suche</h1>
  </mat-card-header>
</mat-card>

<mat-card class="search-card">
  <mat-card-content>
    <form [formGroup]="form">
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-form-field appearance="fill" fxFlex="60%">
          <input matInput
            placeholder="Suche"
            id="id"
            formControlName="id"
            [value]="filter.suche ? filter.suche : ''"
            (keyup)="search($event, false, false)"
            (keyup.enter)="search($event, true, false)"
            (keydown.enter)="$event.preventDefault()"
          >
          <mat-label>Antrags-ID / Teilhabeplan-ID</mat-label>
        </mat-form-field>
        <mat-form-field
          fxFlex="20%"
          appearance="fill"
          ngClass="small no-vertical-margin"
          ngClass.xs=""
          matTooltip="Bitte geben Sie den Beginn des gesuchten Zeitraums ein."
          matTooltipPosition="above"
        >
          <input matInput
            [matDatepicker]="zeitraumVonPicker"
            placeholder="TT.MM.JJJJ"
            id="zeitraumVon"
            formControlName="zeitraumVon"
            (keyup.enter)="search(currentSearchEvent, true, false)"
            (keydown.enter)="$event.preventDefault()"
          >
          <mat-label>Datum von</mat-label>
          <button mat-icon-button matSuffix
            class="delete-date"
            matTooltip="Formularfeld leeren"
            [disabled]="form.controls.zeitraumVon.disabled"
            (click)="form.controls.zeitraumVon.reset()"
          ><i class="material-icons">clear</i></button>
          <mat-datepicker-toggle matSuffix [for]="zeitraumVonPicker"></mat-datepicker-toggle>
          <mat-datepicker #zeitraumVonPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          fxFlex="20%"
          appearance="fill"
          ngClass="small no-vertical-margin"
          ngClass.xs=""
          matTooltip="Bitte geben Sie das Ende des gesuchten Zeitraums ein."
          matTooltipPosition="above"
        >
          <input matInput
            [matDatepicker]="zeitraumBisPicker"
            [min]="form.getRawValue().zeitraumVon"
            placeholder="TT.MM.JJJJ"
            id="zeitraumBis"
            formControlName="zeitraumBis"
            (keyup.enter)="search(currentSearchEvent, true, false)"
            (keydown.enter)="$event.preventDefault()"
          >
          <mat-label>Datum bis</mat-label>
          <button mat-icon-button matSuffix
            class="delete-date"
            matTooltip="Formularfeld leeren"
            [disabled]="form.controls.zeitraumBis.disabled"
            (click)="form.controls.zeitraumBis.reset()"
          ><i class="material-icons">clear</i></button>
          <mat-datepicker-toggle matSuffix [for]="zeitraumBisPicker"></mat-datepicker-toggle>
          <mat-datepicker #zeitraumBisPicker></mat-datepicker>
        </mat-form-field>
        <button mat-stroked-button
          (click)="clearSearch()"
          class="search-button"
          matTooltip="Leeren"
        >
          <mat-icon class="material-icons">clear</mat-icon>
        </button>
        <button mat-raised-button
          color="primary"
          (click)="search(currentSearchEvent, false, true)"
          class="search-button"
          matTooltip="Suchen"
        >
          <mat-icon class="material-icons">search</mat-icon>
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" class="mat-card title-card no-padding">
  <div class="half-width">
    <mat-card class="title-card no-margin">
      <mat-card-header>
        <h1>Anträge</h1>
      </mat-card-header>
    </mat-card>

    <mat-card class="no-margin" *ngIf="(dataSourceRequest$ | async).length">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="toggleResults"
      >
      </mat-progress-bar>
      <mat-card-content class="table">
        <mat-table #table
          [dataSource]="(dataSourceRequest$ | async)"
          matSort
          matSortActive="{{ sortByRequest.active }}"
          matSortDirection="{{ sortByRequest.direction }}"
          (matSortChange)="toggleSort(true, $event)"
          matSortDisableClear=true
        >
          <ng-container matColumnDef="thvbID">
            <mat-header-cell *matHeaderCellDef mat-sort-header aria-label="Sortierung für Antrags-ID"> Antrags-ID </mat-header-cell>
            <mat-cell *matCellDef="let row" class="clickable" [routerLink]="['/antrag/', row.id]">
              {{ row.thvbID }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="erstellDatum">
            <mat-header-cell *matHeaderCellDef mat-sort-header aria-label="Sortierung für Antragsdatum"> Antragsdatum </mat-header-cell>
            <mat-cell
              *matCellDef="let row"
              class="clickable"
              [routerLink]="['/antrag/', row.id]"
              [queryParams]="{ returnUrl: this.router.url }"
            >
              {{ row.erstellDatum | date }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </mat-card-content>
      <mat-card-footer>
        <mat-paginator
          [hidePageSize]="true"
          [length]="(paginatorOpsRequest$ | async).length"
          [pageIndex]="(paginatorOpsRequest$ | async).pageIndex"
          [pageSize]="(paginatorOpsRequest$ | async).pageSize"
          (page)="pageEvent = $event; onPaginateChange(true, $event)"
        >
        </mat-paginator>
      </mat-card-footer>
    </mat-card>

    <mat-card class="no-margin" *ngIf="!((dataSourceRequest$ | async).length > 0)">
      <p> Es konnten keine Einträge gefunden werden. </p>
    </mat-card>
  </div>

  <div class="half-width">
    <mat-card class="title-card no-margin">
      <mat-card-header>
        <h1>Teilhabepläne</h1>
      </mat-card-header>
    </mat-card>

    <mat-card class="no-margin" *ngIf="(dataSourceIntegrationPlan$ | async).length">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="toggleResults"
      >
      </mat-progress-bar>
      <mat-card-content class="table">
        <mat-table #table
          [dataSource]="dataSourceIntegrationPlan$"
          matSort
          matSortActive="{{ sortByIntegrationPlan.active }}"
          matSortDirection="{{ sortByIntegrationPlan.direction }}"
          (matSortChange)="toggleSort(false, $event)"
          matSortDisableClear=true
        >
          <ng-container matColumnDef="thvbID">
            <mat-header-cell *matHeaderCellDef mat-sort-header aria-label="Sortierung für Teilhabeplan-ID"> Teilhabeplan-ID </mat-header-cell>
            <mat-cell *matCellDef="let row" class="clickable" [routerLink]="['/teilhabeplan/', row.id]">
              {{ row.thvbID }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="erstellDatum">
            <mat-header-cell *matHeaderCellDef mat-sort-header  aria-label="Sortierung für Erstelldatum des THP"> Erstelldatum des THP </mat-header-cell>
            <mat-cell *matCellDef="let row" class="clickable" [routerLink]="['/teilhabeplan/', row.id]">
              {{ row.erstellDatum | date }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsThp"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsThp;"></mat-row>
        </mat-table>
      </mat-card-content>
      <mat-card-footer>
        <mat-paginator
          [hidePageSize]="true"
          [length]="(paginatorOpsIntegrationPlan$ | async).length"
          [pageIndex]="(paginatorOpsIntegrationPlan$ | async).pageIndex"
          [pageSize]="(paginatorOpsIntegrationPlan$ | async).pageSize"
          (page)="pageEvent = $event; onPaginateChange(false, $event)"
        >
        </mat-paginator>
      </mat-card-footer>
    </mat-card>

    <mat-card class="no-margin" *ngIf="!((dataSourceIntegrationPlan$ | async).length > 0)">
      <p> Es konnten keine Einträge gefunden werden. </p>
    </mat-card>
  </div>
</div>
