import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Login } from 'app/login/action/login.actions';
import {
  selectLoading,
  selectLoginError
} from 'app/login/reducers/login.reducer';
import { State } from 'app/reducers';
import { Observable } from 'rxjs';

import { FormErrorStateMatcher } from './../shared/components/form-error-state-matcher';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent {
  username = '';
  password = '';
  loginError$: Observable<string>;
  loading$: Observable<boolean>;

  form: FormGroup;
  matcher = new FormErrorStateMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<State>,
    private route: ActivatedRoute
  ) {
    this.loginError$ = store.select(selectLoginError);
    this.loading$ = store.select(selectLoading);

    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  login() {
    if (this.form.touched && this.form.valid) {
      this.store.dispatch(
        new Login({
          ...this.form.value,
          redirect: this.route.snapshot.queryParams.redirect
        })
      );
    }
  }
}
