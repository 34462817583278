import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ROUTES } from './users.routes';

import { usersReducer } from 'app/users/reducers/users.reducer';
import { UsersEffects } from 'app/users/effects/users.effects';
import { UsersListComponent } from 'app/users/list/users-list.component';
import { MaterialModule } from 'app/material.module';
import { UsersFormComponent } from './form/users-form.component';
import { FlexLayoutModule } from '@angular/flex-layout';

const components = [
  UsersListComponent,
  UsersFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule.forChild(ROUTES),
    RouterModule,
    StoreModule.forFeature('users', usersReducer),
    EffectsModule.forFeature([UsersEffects])
  ],
  declarations: [...components],
  exports: [...components],
  entryComponents: [UsersFormComponent]


})
export class UsersModule { }
