import { actionTypesFor } from 'app/actions/crud.actions';
import { Action } from '@ngrx/store';
import { ReportData } from 'app/report-data/report-data';
import { HttpResponse } from '@angular/common/http';

export const reportDataActions = actionTypesFor('reportData');

export const SET_SELECTED = '[ReportData] Set Selected';
export const PREVIEW_REPORT_DATA = '[ReportData] Preview Data';
export const PREVIEW_REPORT_DATA_DONE = '[ReportData] Preview Data Done';
export const PREVIEW_REPORT_DATA_ERROR = '[ReportData] Preview Data Error';
export const PREVIEW_ERROR_REPORT = '[ReportData] Preview Error Report';
export const PREVIEW_ERROR_REPORT_DONE = '[ReportData] Preview Error Report Done';
export const PREVIEW_ERROR_REPORT_ERROR = '[ReportData] Preview Error Report Error';
export const DOWNLOAD_ERROR_REPORT = '[ReportData] Download Error Report';
export const DOWNLOAD_ERROR_REPORT_DONE = '[ReportData] Download Error Report Done';
export const DOWNLOAD_ERROR_REPORT_ERROR = '[ReportData] Download Error Report Error';
export const CREATE_REPORT = '[ReportData] Create Report';
export const CREATE_REPORT_DONE = '[ReportData] Create Report Done';
export const CREATE_REPORT_ERROR = '[ReportData] Create Report Error';

export class SetSelectedReportData implements Action {
  readonly type = SET_SELECTED;
  constructor(public payload: ReportData) { }
}

export class PreviewReportData implements Action {
  readonly type = PREVIEW_REPORT_DATA;
  constructor(public payload: PreviewReportPayload) { }
}

export class PreviewReportDataDone implements Action {
  readonly type = PREVIEW_REPORT_DATA_DONE;
  constructor(public payload: any) { }
}

export class PreviewReportDataError implements Action {
  readonly type = PREVIEW_REPORT_DATA_ERROR;
  constructor(public res: HttpResponse<any>) { }
}

export class CreateReport implements Action {
  readonly type = CREATE_REPORT;
  constructor(public payload: any, public shouldTransmit: boolean) { }
}

export class CreateReportDone implements Action {
  readonly type = CREATE_REPORT_DONE;
  constructor(public file: Blob, public shouldDownload: boolean) { }
}

export class CreateReportError implements Action {
  readonly type = CREATE_REPORT_ERROR;
  constructor(public res: HttpResponse<any>) { }
}

export class PreviewErrorReport implements Action {
  readonly type = PREVIEW_ERROR_REPORT;
  constructor(public payload: PreviewReportPayload) { }
}

export class PreviewErrorReportDone implements Action {
  readonly type = PREVIEW_ERROR_REPORT_DONE;
  constructor(public payload: any) { }
}

export class PreviewErrorReportError implements Action {
  readonly type = PREVIEW_ERROR_REPORT_ERROR;
  constructor(public res: HttpResponse<any>) { }
}

export class DownloadErrorReport implements Action {
  readonly type = DOWNLOAD_ERROR_REPORT;
  constructor(public payload: PreviewReportPayload) { }
}

export class DownloadErrorReportDone implements Action {
  readonly type = DOWNLOAD_ERROR_REPORT_DONE;
  constructor(public file: Blob) { }
}

export class DownloadErrorReportError implements Action {
  readonly type = DOWNLOAD_ERROR_REPORT_ERROR;
  constructor(public res: HttpResponse<any>) { }
}

export interface PreviewReportPayload {
  strDate: string;
  endDate: string;
  traegerId: string;
}
