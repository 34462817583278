import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ObservableInput , of } from 'rxjs';
import { filter, map, switchMap, tap , catchError } from 'rxjs/operators';
import { LoginService } from '../../login/login.service';
import { NotifyService } from '../../notify/notify.service';
import {
  LOGIN,
  Login,
  LOGIN_DONE,
  LoginDone,
  LoginError,
} from '../action/login.actions';
import {
  LOGOUT,
  Logout,
  LOGOUT_DONE,
  LogoutDone
} from '../action/logout.actions';
import { CheckForUpdate } from 'app/update/actions/update.actions';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private notify: NotifyService,
    private login: LoginService
  ) { }

  readonly login$ = createEffect(() => this.actions$.pipe(
    ofType<Login>(LOGIN),
    map(action => action.payload),
    switchMap((payload) => this.login.token(payload.username, payload.password).pipe(
      map(res => res.access_token),
      switchMap(token => this.login.me(token).pipe(
        map(res => new LoginDone({
          username: payload.username,
          authorities: res,
          redirect: payload.redirect || '/',
          accessToken: token
        })),
        catchError(err => catchLoginError(err)),
      )),
      catchError(err => catchLoginError(err))
    ))
  ));

  readonly loginDone$ = createEffect(() => this.actions$.pipe(
    ofType<LoginDone>(LOGIN_DONE),
    map(action => action.payload),
    tap(payload => this.router.navigate([payload.redirect === '/update' ? '/' : payload.redirect])),
    filter(payload => payload.authorities.authorities.includes('ADMIN_BEREICH_LOKAL')),
    map(() => new CheckForUpdate())
  ));

  readonly logout$ = createEffect(() => this.actions$.pipe(
    ofType<Logout>(LOGOUT),
    switchMap(() => this.login.logout()),
    map(() => new LogoutDone())
  ));

  readonly logoutDone$ = createEffect(() => this.actions$.pipe(
    ofType<LogoutDone>(LOGOUT_DONE),
    tap(() => this.router.navigate(['/login'])),
    tap(() => this.notify.showNotification('Sie wurden erfolgreich ausgeloggt.'))
  ), { dispatch: false });
}

export function catchLoginError(err: any): ObservableInput<LoginError> {
  if (err instanceof HttpErrorResponse && err.status === 400) {
    return of(new LoginError('Ungültige Zugangsdaten.'));
  }
}
