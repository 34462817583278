import { Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginGuard } from './login/login.guard';
import { ErrorComponent } from './error/error.component';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'startseite',
    pathMatch: 'full',
  },
  {
    path: 'startseite',
    component: HomeComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'antrag',
    loadChildren: () => import('./request/request.module').then(m => m.RequestModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'teilhabeplan',
    loadChildren: () => import('./integration-plan/integration-plan.module').then(m => m.IntegrationPlanModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'traeger',
    loadChildren: () => import('./responsibility/responsibility.module').then(m => m.ResponsibilityModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'konfiguration',
    loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'import',
    loadChildren: () => import('./importer/importer.module').then(m => m.ImporterModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'meldedaten',
    loadChildren: () => import('./report-data/report-data.module').then(m => m.ReportDataModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'update',
    loadChildren: () => import('./update/update.module').then(m => m.UpdateModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'fehler',
    component: ErrorComponent
  }
];
