import { actionTypesFor } from 'app/actions/crud.actions';
import { Action } from '@ngrx/store';
import { Users } from 'app/users/users';
import { FilterOptions } from '@kdo/ng-crud';
import { HttpResponse } from '@angular/common/http';

export const usersActions = actionTypesFor('users');

export const SET_SELECTED = '[Users] Set Selected';
export const SET_USERS_FILTER = '[Users] SetFilter';
export const DELETE_USER = '[Users] Delete';
export const DELETE_USER_DONE = '[Users] Delete Done';
export const DELETE_USER_ERROR = '[Users] Delete Error';
export const CUSTOM_SAVE_USER = '[Users] Custom Save User';
export const CUSTOM_SAVE_USER_DONE = '[Users] Custom Save User Done';

export class CustomSaveUser implements Action {
  readonly type = CUSTOM_SAVE_USER;
  constructor(public payload: Users, public savingOwnUser: boolean) { }
}

export class CustomSaveUserDone implements Action {
  readonly type = CUSTOM_SAVE_USER_DONE;
  constructor(public savingOwnUser: boolean) { }
}

export class SetSelectedUsers implements Action {
  readonly type = SET_SELECTED;
  constructor(public payload: Users) { }
}

export class DeleteUser implements Action {
  readonly type = DELETE_USER;
  constructor(public id: string) { }
}

export class DeleteUserDone implements Action {
  readonly type = DELETE_USER_DONE;
  constructor(public payload: HttpResponse<any>) { }
}

export class DeleteUserError implements Action {
  readonly type = DELETE_USER_ERROR;
}

export class SetUsersFilter implements Action {
  readonly type = SET_USERS_FILTER;
  constructor(public payload: FilterOptions) {}
}

export type Actions =
    SetSelectedUsers
  | DeleteUser
  | DeleteUserDone
  | DeleteUserError
  | SetUsersFilter
  | CustomSaveUser
  | CustomSaveUserDone
;
