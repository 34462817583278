import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { FilterBackups, RestoreFromBackup } from 'app/configuration/actions/configuration.actions';
import { selectBackupList } from 'app/configuration/reducers/configuration.reducer';
import { DialogService } from 'app/dialog/dialog.service';
import { filter } from 'rxjs/operators';
import { Backup } from 'app/configuration/configuration';

@Component({
  selector: 'app-backup-list',
  templateUrl: './backup-list.component.html',
  styleUrls: ['./backup-list.component.scss'],
})
export class BackupListComponent {

  backups$: Observable<any>;
  pageEvent: any;

  constructor(
    public dialogRef: MatDialogRef<BackupListComponent>,
    public store: Store<State>,
    private dialog: DialogService,
  ) { }

  setContent() {
    this.store.dispatch(new FilterBackups());
    this.backups$ = this.store.select(selectBackupList);
  }

  selectBackup(backup: Backup): void {
    this.dialog.confirm(
        'Warnung',
        'Wenn Sie die Datenbank auf diesen Stand zurücksetzen, werden alle danach gemachten Änderungen gelöscht! '
        + 'Sind Sie sicher, dass Sie die Wiederherstellung durchführen möchten?'
      )
      .pipe(filter(response => !!response))
      .subscribe(() => {
        this.store.dispatch(new RestoreFromBackup(backup.backupName));
        this.dialogRef.close();
      });
  }

}
