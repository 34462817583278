<app-toolbar (toggleSidenav)="sidenav.toggle()" [hidden]="updating$ | async" role="banner"></app-toolbar>

<app-sidenav #sidenav [hidden]="updating$ | async">
  <div class="main" role="main">
    <div class="content-wrapper">
      <div class="accent-bg accent-gray"></div>
      <div class="center">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</app-sidenav>

<div *ngIf="updating$ | async">
  <router-outlet></router-outlet>
</div>
