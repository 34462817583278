import { Configuration, Backup } from 'app/configuration/configuration';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CrudState, selectorsFor, reducersFor, initialCrudState } from 'app/reducers/crud.reducer';
import {
  SET_SELECTED,
  FILTER_BACKUPS_DONE,
  FILTER_BACKUPS,
  FILTER_BACKUPS_ERROR,
  RESTORE_FROM_BACKUP,
  RESTORE_FROM_BACKUP_DONE,
  RESTORE_FROM_BACKUP_ERROR,
  CREATE_NEW_BACKUP,
  CREATE_NEW_BACKUP_DONE,
  CREATE_NEW_BACKUP_ERROR,
} from 'app/configuration/actions/configuration.actions';
import * as _ from 'lodash';

export interface ConfigurationState extends CrudState<Configuration> {
  selectedConfiguration: Configuration | null;
  backupList: Backup[];
  creatingBackup: boolean;
  restoring: boolean;
}

const initialState: ConfigurationState = {
  selectedConfiguration: null,
  backupList: [],
  restoring: false,
  creatingBackup: false,
  ...initialCrudState
};

export function configurationReducer(state = initialState, action) {
  const crudReduced = reducersFor<Configuration>('configuration', state, action);

  if (crudReduced !== false) {
    return crudReduced;
  }

  switch (action.type) {
    case CREATE_NEW_BACKUP:
      return { ..._.cloneDeep(state), creatingBackup: true };

    case CREATE_NEW_BACKUP_DONE:
    case CREATE_NEW_BACKUP_ERROR:
      return { ..._.cloneDeep(state), creatingBackup: false };

    case RESTORE_FROM_BACKUP:
      return { ..._.cloneDeep(state), restoring: true };

    case RESTORE_FROM_BACKUP_DONE:
    case RESTORE_FROM_BACKUP_ERROR:
      return { ..._.cloneDeep(state), restoring: false };

    case FILTER_BACKUPS:
      return { ..._.cloneDeep(state), loading: true };

    case FILTER_BACKUPS_ERROR:
      return { ..._.cloneDeep(state), loading: false };

    case FILTER_BACKUPS_DONE:
      return {
        ..._.cloneDeep(state),
        backupList: action.payload,
        loading: false
      };

      case SET_SELECTED:
        return { ..._.cloneDeep(state), selectedConfiguration: action.payload };
      default:
        return state;
  }
}

// Selectors
export const selectConfiguration = createFeatureSelector<ConfigurationState>('configuration');
export const configurationSelectors = selectorsFor<Configuration>(selectConfiguration);
export const selectSelectedConfiguration = createSelector(selectConfiguration, state => state.selectedConfiguration);
export const selectLoading = createSelector(selectConfiguration, state => state.loading);
export const selectBackupList = createSelector(selectConfiguration, state => state.backupList);
export const selectRestoring = createSelector(selectConfiguration, state => state.restoring);
export const selectCreatingBackup = createSelector(selectConfiguration, state => state.creatingBackup);
