<mat-card class="title-card">
  <mat-card-header>
    <h1>Login</h1>
  </mat-card-header>
</mat-card>

<form [formGroup]="form" (submit)="login()">
  <mat-card>
    <mat-progress-bar *ngIf="(loading$ | async)" mode="indeterminate"></mat-progress-bar>
    <mat-card-content>
      <div class="error" *ngIf="loginError$ | async">
        {{ loginError$ | async }}
      </div>
      <mat-form-field appearance="fill" class="center-form-field">
        <input
          required
          matInput
          autofocus
          placeholder="Benutzername"
          formControlName="username"
          [errorStateMatcher]="matcher"
        >
        <mat-error *ngIf="form.controls['username'].hasError('required')">
          Dieses Feld muss ausgefüllt werden
        </mat-error>
        <mat-label>Benutzername</mat-label>
      </mat-form-field>

      <mat-form-field appearance="fill" class="center-form-field">
        <input
          required
          type="password"
          matInput
          placeholder="Passwort"
          formControlName="password"
          [errorStateMatcher]="matcher"
        >
        <mat-error *ngIf="form.controls['password'].hasError('required')">
          Dieses Feld muss ausgefüllt werden
        </mat-error>
        <mat-label>Passwort</mat-label>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <mat-card class="button-card">
    <mat-card-content>
      <button mat-raised-button
        class="login-button"
        mat-button
        color="primary"
        type="submit"
        [disabled]="!form.valid || (loading$ | async)"
      >
        Abschicken
      </button>
    </mat-card-content>
  </mat-card>
</form>
