import { ReportData, ErrorMessage } from 'app/report-data/report-data';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CrudState, selectorsFor, reducersFor, initialCrudState } from 'app/reducers/crud.reducer';
import {
  SET_SELECTED,
  PREVIEW_REPORT_DATA_DONE,
  PREVIEW_ERROR_REPORT_DONE,
  DOWNLOAD_ERROR_REPORT,
  DOWNLOAD_ERROR_REPORT_DONE,
  DOWNLOAD_ERROR_REPORT_ERROR,
  PREVIEW_REPORT_DATA,
  CREATE_REPORT,
  CREATE_REPORT_DONE,
  CREATE_REPORT_ERROR
} from 'app/report-data/actions/report-data.actions';
import * as _ from 'lodash';

export interface ReportDataState extends CrudState<ReportData> {
  selectedReportData: ReportData | null;
  previewList: any;
  errorList: ErrorMessage[];
  downloading: boolean;
  transmitting: boolean;
}

const initialState: ReportDataState = {
  selectedReportData: null,
  previewList: {},
  errorList: [],
  downloading: false,
  transmitting: false,
  ...initialCrudState
};

export function reportDataReducer(state = initialState, action) {
  const crudReduced = reducersFor<ReportData>('reportData', state, action);

  if (crudReduced !== false) {
    return crudReduced;
  }

  switch (action.type) {
    case SET_SELECTED:
      return { ..._.cloneDeep(state), selectedReportData: action.payload };

    case PREVIEW_REPORT_DATA:
      return { ..._.cloneDeep(state), previewList: {} };

    case PREVIEW_REPORT_DATA_DONE:
      return { ..._.cloneDeep(state), previewList: action.payload };

    case PREVIEW_ERROR_REPORT_DONE:
      return { ..._.cloneDeep(state), errorList: action.payload };

    case DOWNLOAD_ERROR_REPORT:
      return { ..._.cloneDeep(state), downloading: true };

    case CREATE_REPORT:
      return { ..._.cloneDeep(state), transmitting: action.shouldTransmit };

    case CREATE_REPORT_DONE:
    case CREATE_REPORT_ERROR:
      return { ..._.cloneDeep(state), transmitting: false };

    case DOWNLOAD_ERROR_REPORT_DONE:
    case DOWNLOAD_ERROR_REPORT_ERROR:
      return { ..._.cloneDeep(state), downloading: false };

    default:
      return state;
  }
}

// Selectors
export const selectReportData = createFeatureSelector<ReportDataState>('reportData');
export const reportDataSelectors = selectorsFor<ReportData>(selectReportData);
export const selectSelectedReportData = createSelector(selectReportData, state => state.selectedReportData);
export const selectPreviewList = createSelector(selectReportData, state => state.previewList);
export const selectErrorList = createSelector(selectReportData, state => state.errorList);
export const selectDownloading = createSelector(selectReportData, state => state.downloading);
export const selectTransmitting = createSelector(selectReportData, state => state.transmitting);
