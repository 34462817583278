import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogService } from './dialog.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoDialogComponent } from 'app/dialog/info-dialog/info-dialog.component';
import { PreviewFormComponent } from 'app/report-data/form/preview-form/preview-form.component';
import { BackupListComponent } from 'app/configuration/form/backup-list/backup-list.component';
import { ErrorsFormComponent } from 'app/report-data/form/errors-form/errors-form.component';

const declarations = [
  ConfirmDialogComponent,
  InfoDialogComponent,
  PreviewFormComponent,
  ErrorsFormComponent,
  BackupListComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  declarations: [declarations],
  exports: [declarations],
  entryComponents: [declarations],
  providers: [DialogService]
})
export class DialogModule { }
