import { DataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Users {
  id?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  username: string;
  hashedClientPassword?: string;
  roles?: string;
  plainPassword?: string;
}

export enum UserRoles {
  ADMINISTRATOR = 'Administrator',
  SACHBEARBEITER = 'Sachbearbeiter',
  DATENUEBERMITTLER = 'Datenübermittler'
}

export class UsersDataSource extends DataSource<any> {
  constructor(public data) {
    super();
  }

  connect(): Observable<any[]> {
    return merge(this.data).pipe(
      map(() => this.data.slice())
    );
  }

  disconnect() {}
}
