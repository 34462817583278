<mat-card class="title-card">
  <mat-card-header>
    <h1>Benutzerdaten</h1>
  </mat-card-header>
</mat-card>

<mat-card>
  <mat-card-content>
    <form [formGroup]="form" autocomplete="off">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <h3 class="form-title">Allgemeine Informationen</h3>
          <p class="form-hint">Pflichtfelder sind mit einem * markiert.</p>
        </div>
        <div fxFlex="66%" fxLayout="column">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
            <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
              <input matInput
                id="firstname"
                placeholder="Vorname"
                formControlName="firstname"
              >
              <mat-label>Vorname</mat-label>
            </mat-form-field>

            <mat-form-field appearance="fill" ngClass="small" ngClass.xs="">
              <input matInput
                id="lastname"
                placeholder="Nachname"
                formControlName="lastname"
              >
              <mat-label>Nachname</mat-label>
            </mat-form-field>
          </div>

          <mat-form-field appearance="fill">
            <input matInput
              autocomplete="off"
              required
              id="username"
              placeholder="Benutzername"
              formControlName="username"
            >
            <mat-label>Benutzername</mat-label>
            <mat-error>Es muss ein Benutzername eingegeben werden.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <input matInput
              id="email"
              placeholder="E-Mail"
              formControlName="email"
            >
            <mat-label>E-Mail</mat-label>
            <mat-error>Bitte geben Sie eine gültige E-Mail-Adresse ein</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-divider">
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <h3 class="form-title">Rollen und Rechte</h3>
        </div>
        <div fxFlex="66%" fxLayout="column">
          <mat-form-field appearance="fill">
            <mat-select multiple required
              id="roles"
              placeholder="Rollen"
              formControlName="roles"
              [value]="form.get('roles').value"
              [compareWith]="matSelectHelper.compareByValue"
            >
              <mat-option *ngFor="let role of objKeys(roles)" [value]="role"> {{ roles[role] }} </mat-option>
            </mat-select>
            <mat-label>Rollen</mat-label>
            <mat-error>Es muss eine Rolle ausgewählt werden.</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-divider">
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <h3 class="form-title">Passwort</h3>
          <p class="form-hint">Muss Buchstaben, Ziffern und insgesamt mindestens 8 Zeichen enthalten.</p>
        </div>
        <div fxFlex="66%" fxLayout="column">
          <mat-form-field appearance="fill">
            <input matInput
              autocomplete="off"
              id="plainPassword"
              type="password"
              placeholder="Passwort"
              formControlName="plainPassword"
              [required]="isNewEntry()"
              (keyup)="onPasswordChange($event)"
            >
            <mat-label>Passwort</mat-label>
            <mat-error>Die Passwortrichtlinien geben vor, dass Ihr Passwort Buchstaben und Ziffern enthalten muss. Es muss mindestens aus 8 Zeichen bestehen.</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card class="button-card">
  <mat-card-content>
    <button mat-raised-button
      color="primary"
      (click)="onSave()"
      tabindex="2"
      [class.confirm]="form.valid"
      [disabled]="!form.valid"
    >
      Speichern
    </button>
  </mat-card-content>
</mat-card>
