import {
  createFeatureSelector,
  MetaReducer,
  ActionReducer,
  ActionReducerMap,
} from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from 'environments/environment';
import { localStorageSync } from 'ngrx-store-localstorage';
import { RequestState, requestReducer } from 'app/request/reducers/request.reducer';

export interface State {
  request: RequestState;
}

export const reducers: ActionReducerMap<State> = {
  request: requestReducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{login: ['loggedIn', 'username', 'authorities', 'ownId', 'accessToken']}],
    rehydrate: true
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [storeFreeze, localStorageSyncReducer]
  : [localStorageSyncReducer];

export const getServerState = createFeatureSelector<State>('server');
