<div class="dialog">
  <h2>{{ title }}</h2>
  <p>{{ message }}</p>

  <div mat-dialog-actions class="button-container">
    <button mat-button (click)="dialogRef.close()" tabindex="1" color="accent">
      Schließen
    </button>
  </div>
</div>
