import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from 'app/login/effects/login.effects';
import { loginReducer } from 'app/login/reducers/login.reducer';
import { LOGIN_ROUTES } from 'app/login/login.routes';
import { LoginGuard } from 'app/login/login.guard';
import { LoginService } from 'app/login/login.service';
import { AuthorityGuard } from './authority.guard';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    RouterModule.forChild(LOGIN_ROUTES),
    StoreModule.forFeature('login', loginReducer),
    EffectsModule.forFeature([LoginEffects])
  ],
  declarations: [LoginComponent],
  providers: [LoginGuard, AuthorityGuard, LoginService]
})
export class LoginModule { }
