import { Injectable } from '@angular/core';

/**
 * Helper für Material Select Felder.
 */
@Injectable()
export class MatSelectHelperService {

  compareById(element1: any, element2: any): boolean {
    return element1 && element2 && element1.id === element2.id;
  }

  compareByValue(element1: any, element2: any): boolean {
    return element1 && element2 && element1 === element2;
  }
}
