import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';

import { MaterialModule } from './material.module';
import { DialogModule } from './dialog/dialog.module';
import { ROUTES } from './app.routes';
import { HTTP_OPTIONS } from './shared/http-options.token';
import { HttpInterceptorService } from './shared/http-interceptor.service';
import { KDOAuthModule } from './shared/auth/auth.module';
import { KDOCrudModule } from '@kdo/ng-crud';
import { AppComponent } from './app.component';
import { NotifyService } from './notify/notify.service';
import { HomeComponent } from './home/home.component';
import { SidenavComponent } from 'app/sidenav/sidenav.component';
import { reducers, metaReducers } from 'app/reducers';
import { environment } from 'environments/environment';
import { LoginModule } from 'app/login/login.module';
import { baseCrudUrlGetter, tokenGetter } from 'app/config-getter';
import { HttpErrorInterceptorService } from 'app/shared/http-error-interceptor.service';
import { ToolbarComponent } from 'app/toolbar/toolbar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormArrayHelperService } from './shared/formArrayHelper.service';
import { IntegrationPlanModule } from './integration-plan/integration-plan.module';
import { RequestModule } from './request/request.module';
import { MatSelectHelperService } from './shared/matSelectHelper.service';
import { UpdateModule } from './update/update.module';
import { ResponsibilityModule } from './responsibility/responsibility.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ImporterModule } from './importer/importer.module';
import { UsersModule } from './users/users.module';
import { ErrorComponent } from './error/error.component';
import { UserDetailsComponent } from './toolbar/user-details/user-details.component';
registerLocaleData(localeDe);


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SidenavComponent,
    ToolbarComponent,
    ErrorComponent,
    UserDetailsComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(ROUTES),
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    DialogModule,
    ReactiveFormsModule,
    FormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    // App-Module
    LoginModule,
    IntegrationPlanModule,
    RequestModule,
    ResponsibilityModule,
    UpdateModule,
    ConfigurationModule,
    ImporterModule,
    UsersModule,

    // KDO-Module
    KDOCrudModule.forRoot({
      config: {
        baseUrl: baseCrudUrlGetter
      }
    }),
    KDOAuthModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
  ],
  exports: [
    DialogModule,
  ],
  providers: [
    {
      provide: HTTP_OPTIONS,
      useValue: {
        contentType: 'application/json'
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    NotifyService,
    FormArrayHelperService,
    MatSelectHelperService,
    { provide: LOCALE_ID, useValue: 'de-DE' },
  ],
  bootstrap: [AppComponent],
  entryComponents: [UserDetailsComponent]
})
export class AppModule {
}
