import { LoginGuard } from 'app/login/login.guard';
import { ConfigurationFormComponent } from 'app/configuration/form/configuration-form.component';
import { AuthorityGuard } from 'app/login/authority.guard';

const ROUTE_PREFIX = 'konfiguration';
export const ROUTES = [
  {
    path: `${ROUTE_PREFIX}`,
    component: ConfigurationFormComponent,
    canActivate: [LoginGuard, AuthorityGuard],
    data: { requiredAuthority: 'ADMIN_BEREICH_LOKAL' },
    canDeactivate: ['CanDeactivateConfiguration']
  }
];
