import { Action } from '@ngrx/store';
import { HttpResponse } from '@angular/common/http';

export const CHECK_FOR_UPDATE = '[Update] Check for Update';
export const CHECK_FOR_UPDATE_DONE = '[Update] Check for Update Done';
export const CHECK_FOR_UPDATE_ERROR = '[Update] Check for Update Error';
export const TRIGGER_UPDATE = '[Update] Trigger Update';
export const TRIGGER_UPDATE_DONE = '[Update] Trigger Update Done';
export const TRIGGER_UPDATE_ERROR = '[Update] Trigger Update Error';
export const FETCH_SERVER_STATUS = '[Update] Fetch Server Status';
export const FETCH_SERVER_STATUS_SUCCESS = '[Update] Fetch Server Status Success';

export class CheckForUpdate implements Action {
  readonly type = CHECK_FOR_UPDATE;
}

export interface CheckForUpdatePayload {
  updatesPresent: boolean;
}
export class CheckForUpdateDone implements Action {
  readonly type = CHECK_FOR_UPDATE_DONE;
  constructor(public payload: CheckForUpdatePayload) { }
}

export class CheckForUpdateError implements Action {
  readonly type = CHECK_FOR_UPDATE_ERROR;
}

export class TriggerUpdate implements Action {
  readonly type = TRIGGER_UPDATE;
}

export class TriggerUpdateDone implements Action {
  readonly type = TRIGGER_UPDATE_DONE;
}

export class TriggerUpdateError implements Action {
  readonly type = TRIGGER_UPDATE_ERROR;
  constructor(public res: HttpResponse<any>) { }
}

export class FetchServerStatus implements Action {
  readonly type = FETCH_SERVER_STATUS;
}

export class FetchServerStatusSuccess implements Action {
  readonly type = FETCH_SERVER_STATUS_SUCCESS;
}
