import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Injectable()
export class WiderspruecheValidators {
  form: FormGroup;

  constructor() { }

  setForm(form: FormGroup): void {
    this.form = form;
  }

  disableButtonAddWiderspruch(): boolean {
    return this.widersprueche
      && this.widersprueche.length > 0
        ? !this.widersprueche.value[this.widersprueche.length - 1].datumErledigung
          || !this.widersprueche.value[this.widersprueche.length - 1].erledigungsart
        : false;
  }

  get widersprueche(): FormArray {
    return this.form.get('widersprueche') as FormArray;
  }
}
