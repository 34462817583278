import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectorsFor, reducersFor } from 'app/reducers/crud.reducer';
import { IRequest, RequestFilterOptions } from '../request';
import * as _ from 'lodash';
import {
  SET_REQUEST_FILTER,
  SetRequestFilter,
  SAVE_AND_RELOAD_REQUEST,
  SAVE_AND_RELOAD_REQUEST_DONE,
  SAVE_AND_RELOAD_REQUEST_ERROR,
  GET_CORRESPONDING_INTEGRATIONPLAN_DONE,
  GET_CORRESPONDING_INTEGRATIONPLAN_ERROR,
  CLEAR_INTEGRATIONPLAN
} from '../actions/request.actions';
import { PaginatorOptions } from '@kdo/ng-crud';

export interface RequestState {
  integrationPlan: any;
  list: any[];
  currentEntity: any;
  listSettings: RequestFilterOptions;
  paginatorOptions: PaginatorOptions;
  loading: boolean;
}

const initialState: RequestState = {
  integrationPlan: {},
  list: [],
  currentEntity: {},
  listSettings: {
    page: 0,
    sort: encodeURIComponent(JSON.stringify({
      active: 'thvbID',
      direction: 'asc',
    })),
    size: 10,
    id: '',
    von: '',
    bis: '',
  },
  paginatorOptions: {
    length: 0,
    pageIndex: 0,
    pageSize: 0,
  },
  loading: false
};

export function requestReducer(state = initialState, action) {
  const crudReduced = reducersFor<IRequest>('request', state, action);

  if (crudReduced !== false) {
    return crudReduced;
  }

  switch (action.type) {
    case SET_REQUEST_FILTER:
      return setFilter(state, action);

    case SAVE_AND_RELOAD_REQUEST:
      return { ..._.cloneDeep(state), currentEntity: action.payload, loading: true };

    case SAVE_AND_RELOAD_REQUEST_DONE:
    case SAVE_AND_RELOAD_REQUEST_ERROR:
    case GET_CORRESPONDING_INTEGRATIONPLAN_ERROR:
      return { ..._.cloneDeep(state), loading: false };

    case CLEAR_INTEGRATIONPLAN:
      return { ..._.cloneDeep(state), integrationPlan: {} };

    case GET_CORRESPONDING_INTEGRATIONPLAN_DONE:
      return { ..._.cloneDeep(state), loading: false, integrationPlan: action.payload };

    default:
      return state;
  }
}

function setFilter(state: RequestState, action: SetRequestFilter): RequestState {

  const mergedSettings = _.merge({}, initialState.listSettings, action.payload);

  return {
    ..._.cloneDeep(state),
    listSettings: _.isEmpty(action.payload) ? initialState.listSettings : mergedSettings
  };
}

// Selectors
export const selectRequest = createFeatureSelector<RequestState>('request');
export const requestSelectors = selectorsFor<IRequest>(selectRequest);
export const selectCurrentEntity = createSelector(selectRequest, state => state.currentEntity);
export const selectLoading = createSelector(selectRequest, state => state.loading);
export const selectCorrespondingIntegrationplan = createSelector(selectRequest, state => state.integrationPlan);
