import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule, RouterStateSnapshot } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ROUTES } from './responsibility.routes';

import { responsibilityReducer } from 'app/responsibility/reducers/responsibility.reducer';
import { ResponsibilityEffects } from 'app/responsibility/effects/responsibility.effects';
import { ResponsibilityFormComponent } from 'app/responsibility/form/responsibility-form.component';
import { MaterialModule } from 'app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ResponsibilityListComponent } from './list/responsibility-list.component';

const components = [
  ResponsibilityListComponent,
  ResponsibilityFormComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule.forChild(ROUTES),
    RouterModule,
    StoreModule.forFeature('responsibility', responsibilityReducer),
    EffectsModule.forFeature([ResponsibilityEffects])
  ],
  declarations: [...components],
  exports: [...components],
  providers: [
    {
      provide: 'CanDeactivateResponsibility',
      useValue: (component: ResponsibilityFormComponent, nextState: RouterStateSnapshot) =>
        (component.form.dirty && nextState.url)
          ? window.confirm('Sind Sie sicher, dass Sie das Formular verlassen möchten?'
            + ' Ungesicherte Änderungen gehen dabei verloren.')
          : true
    },
  ]
})
export class ResponsibilityModule { }
