import { Injectable, Inject } from '@angular/core';
import { FilterOptions, CRUD_OPTIONS, CrudOptions } from '@kdo/ng-crud';
import { mergeMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { from, Observable } from 'rxjs';

@Injectable()
export class ThvbHelperService {

  private baseUrlGetter: () => Promise<string>;

  constructor(
    @Inject(CRUD_OPTIONS) config: CrudOptions,
    private http: HttpClient,
  ) {
    this.baseUrlGetter = config.baseUrl;
  }

  /**
   * Laden aller Objekte von `endpoint` unter Berücksichtigung von `filterOptions`
   * (Seite, Seitengröße, Filter und Sortierung).
   * @param endpoint Endpunkt zum Laden aller Objekte
   * @param options Details für die Seite, Seitengröße, Filter und Sortierung.
   * @param formatSort Funktion zum Formatieren der Suche
   */
  getAll<R>(
    endpoint: string,
    filterOptions?: FilterOptions,
    formatSort?: (string) => string
  ): Observable<R> {
    const options: any = { ...filterOptions } || {
      page: undefined,
      size: undefined,
      filter: [],
      sort: undefined
    };

    // Sortierung für API formatieren, wenn vorhanden
    if (options && options.sort && Object.keys(options.sort).length !== 0) {
      options.sort = formatSort ? formatSort(options.sort) : this.formatSort(options.sort);
    }

    const httpOptions: {
      params?: any,
      observe: 'body',
      responseType: 'json'
    } = {
      params: this.serializeSearchParams(options),
      observe: 'body',
      responseType: 'json'
    };

    return from(this.baseUrlGetter()).pipe(
      mergeMap(baseUrlAsync => this.http.get<R>(baseUrlAsync + endpoint, httpOptions))
    );
  }

  /**
   * Wandelt Key-Value-Paare des Objekts `options` in `HttpParams` um.
   * @param options Key-Value-Paare für die Request-Parameter
   */
  protected serializeSearchParams(options): HttpParams {
    let params = new HttpParams();
    Object.keys(options)
      .filter(key => !!options[key])
      .forEach(key => params = params.set(key, options[key]));
    return params;
  }


  /**
   * Sortierung der API entsprechend umformatieren.
   * @param sort Sortierungsinformationen
   */
  protected formatSort(sort: string): string {
    const parsedSort = JSON.parse(decodeURIComponent(sort));
    return parsedSort.active + ',' + parsedSort.direction;
  }
}
