import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { DownloadErrorReport } from 'app/report-data/actions/report-data.actions';
import { Observable } from 'rxjs';
import { selectDownloading } from 'app/report-data/reducers/report-data.reducer';

@Component({
  selector: 'app-errors-form',
  templateUrl: './errors-form.component.html',
  styleUrls: ['./errors-form.component.scss'],
})
export class ErrorsFormComponent {

  title: string;
  errorList$: Observable<any>;
  downloading$: Observable<boolean>;
  objKeys = Object.keys;
  content: any;

  constructor(
    private store: Store<State>,
    public dialogRef: MatDialogRef<ErrorsFormComponent>,
  ) { }

  setContent(content: any) {
    this.content = content.obj;
    this.errorList$ = content.observable;
    this.downloading$ = this.store.select(selectDownloading);
  }

  downloadErrorReport() {
    this.store.dispatch(new DownloadErrorReport(this.content));
  }
}
