import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseCrudUrlGetter } from 'app/config-getter';
import { Observable , from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class UpdateService {

  constructor(private http: HttpClient) { }

  public pingServer(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('X-KDO-NO-AUTH', 'true');
    return from(baseCrudUrlGetter()).pipe(
      mergeMap(baseUrlAsync => this.http.get(baseUrlAsync + 'ping', { headers, responseType: 'text' }))
    );
  }
}
