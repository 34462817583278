import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule, RouterStateSnapshot } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ROUTES } from './request.routes';

import { requestReducer } from 'app/request/reducers/request.reducer';
import { RequestEffects } from 'app/request/effects/request.effects';
import { RequestFormComponent } from 'app/request/form/request-form.component';
import { MaterialModule } from 'app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DisableControlDirective } from 'app/shared/disable-control.directive';
import { ThvbHelperService } from 'app/shared/thvb-helper.service';
import { KlagenValidators } from './validators/klagen.validators';
import { GutachtenValidators } from './validators/gutachten.validators';
import { AntragValidators } from './validators/antrag.validators';
import { ErstattungsverfahrenValidators } from './validators/erstattungsverfahren.validators';
import { ErstattungsantragValidators } from './validators/erstattungsantrag.validators';
import { WiderspruecheValidators } from './validators/widersprueche.validators';
import { EntscheidungValidators } from './validators/entscheidung.validators';

const components = [
  RequestFormComponent,
  DisableControlDirective,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule.forChild(ROUTES),
    RouterModule,
    StoreModule.forFeature('request', requestReducer),
    EffectsModule.forFeature([RequestEffects])
  ],
  declarations: [...components],
  exports: [...components],
  providers: [
    {
      provide: 'CanDeactivateRequest',
      useValue: (component: RequestFormComponent, nextState: RouterStateSnapshot) =>
        (component.form.dirty && nextState.url)
          ? window.confirm('Sind Sie sicher, dass Sie das Formular verlassen möchten?'
            + ' Ungesicherte Änderungen gehen dabei verloren.')
          : true
    },
    ThvbHelperService,
    KlagenValidators,
    GutachtenValidators,
    AntragValidators,
    EntscheidungValidators,
    ErstattungsverfahrenValidators,
    ErstattungsantragValidators,
    WiderspruecheValidators,
  ]
})
export class RequestModule { }
