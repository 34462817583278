<mat-card class="title-card">
  <mat-card-header>
    <h1>Träger-ID</h1>
  </mat-card-header>
</mat-card>

<mat-card>
  <mat-card-content>
    <form [formGroup]="form">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <h3 class="form-title">Träger-Identifikation</h3>
          <p class="form-hint">Pflichtfelder sind mit einem * markiert.</p>
        </div>
        <div fxFlex="66%" fxLayout="column">
          <mat-form-field
            appearance="fill"
            matTooltip="Bitte geben Sie eine Träger-ID ein."
            matTooltipDisabled="true"
            matTooltipPosition="left"
          >
            <input matInput
              required
              id="traegerID"
              formControlName="traegerID"
              placeholder="Träger-ID"
              type="text"
              pattern="[1-9]+[0-9]*"
            >
            <mat-label>Träger-ID</mat-label>
            <mat-error *ngIf="form.controls.traegerID.hasError('required')">Es muss eine Träger-ID eingegeben werden.</mat-error>
            <mat-error *ngIf="form.controls.traegerID.hasError('pattern')">Die Träger-ID darf nur aus Zahlen bestehen.</mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            matTooltip="Bitte geben Sie einen Kurznamen für den Träger ein."
            matTooltipDisabled="true"
            matTooltipPosition="left"
          >
            <input matInput
              required
              id="name"
              formControlName="name"
              placeholder="Kurzname"
            >
            <mat-label>Kurzname</mat-label>
            <mat-error>Es muss ein Kurzname für den Träger eingegeben werden.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-select
              required
              id="bundesland"
              formControlName="bundesland"
              placeholder="Bundesland"
            >
              <mat-option *ngFor="let state of object.keys(states)" [value]="state">{{ states[state] }}</mat-option>
            </mat-select>
            <mat-label>Bundesland</mat-label>
            <mat-error>Es muss ein Bundesland eingegeben werden.</mat-error>
          </mat-form-field>

          <div [hidden]="(hasOwnID$ | async) && !curResponsibilityObject.eigeneID">
            <mat-checkbox
              class="check-block"
              color="primary"
              id="eigeneID"
              formControlName="eigeneID"
            >
              Eigene ID
            </mat-checkbox>
          </div>

          <mat-checkbox
            class="check-block"
            color="primary"
            id="inaktiv"
            formControlName="inaktiv"
          >
            Inaktiv
          </mat-checkbox>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card class="button-card">
  <mat-card-content>
    <button mat-raised-button
      color="primary"
      (click)="onSave()"
      [disabled]="!form.valid"
    >
      Speichern
    </button>
  </mat-card-content>
</mat-card>
