import { Actions, ofType } from '@ngrx/effects';
import {
  switchMap,
  map,
  tap,
  withLatestFrom,
  catchError,
  concatMap
} from 'rxjs/operators';
import { of, from, Observable } from 'rxjs';
import { CrudSelectors } from 'app/reducers/crud.reducer';
import { CrudHelperService } from '@kdo/ng-crud';
import { Router } from '@angular/router';

export interface CrudEffects {
  get(helper: CrudHelperService): (source: Actions<any>) => Observable<any>;
  filterMe(store, helper: CrudHelperService): (source: Actions<any>) => Observable<any>;
  save(helper: CrudHelperService): (source: Actions<any>) => Observable<any>;
  saveDone(store, router: Router, notify): (source: Actions<any>) => Observable<any>;
  deleteMe(helper: CrudHelperService): (source: Actions<any>) => Observable<any>;
  deleteDone(notify): (source: Actions<any>) => Observable<any>;
}

export function effectsFor(
  entity: string,
  endpoint: string,
  url: string,
  selectors: CrudSelectors
): CrudEffects {
  const euc = entity.toUpperCase();

  const get = (helper: CrudHelperService) =>
    (source: Actions<any>): Observable<any> =>
      source
        .pipe(
          ofType(`GET_${euc}`),
          map(action => action.payload),
          switchMap(payload => helper.get<any>(endpoint + '/' + payload)),
          map(payload => ({ type: `GET_${euc}_DONE`, payload }))
        );

  const filterMe = (store, helper: CrudHelperService) =>
    (source: Actions<any>): Observable<any> =>
      source
        .pipe(
          ofType<any>('FILTER_' + euc),
          withLatestFrom(store.select(selectors.selectListSettings)),
          map(([_payload, options]) => options),
          switchMap(options =>
            helper.getAll<any>(endpoint, options)
              .pipe(
                map(payload => ({ type: 'FILTER_' + euc + '_DONE', payload})),
                catchError(() => of({ type: 'FILTER_' + euc + '_ERROR' }))
              )
          )
        );

    const save = (helper: CrudHelperService) =>
      (source: Actions<any>): Observable<any> =>
        source
          .pipe(
            ofType<any>('SAVE_' + euc),
            concatMap(res => helper.save(endpoint, res.payload).pipe(
              map(payload => ({ type: 'SAVE_' + euc + '_DONE', payload })),
              catchError(() => of({ type: 'SAVE_' + euc + '_ERROR' }))
            )),
          );

    const saveDone = (store, router: Router, notify) =>
      (source: Actions<any>): Observable<any> =>
        source
          .pipe(
            ofType<any>('SAVE_' + euc + '_DONE'),
            withLatestFrom(store.select(selectors.selectListSettings)),
            tap(() => notify.showNotification('Der Eintrag wurde erfolgreich gespeichert.')),
            switchMap(([_action, listSettings]) =>
              from(router.navigate([url, listSettings ]))
            ),
            map(routerResult => routerResult
              ? ({ type: 'NO_ACTION' })
              : ({ type: 'FILTER_' + euc })
            )
          );

    const deleteMe = (helper: CrudHelperService) =>
      (source: Actions<any>): Observable<any> =>
        source
          .pipe(
            ofType<any>('DELETE_' + euc),
            concatMap(res => helper.delete(endpoint + '/' + res.payload).pipe(
              map(payload => ({ type: 'DELETE_' + euc + '_DONE', payload })),
              catchError(() => of({ type: 'DELETE_' + euc + '_ERROR' }))
            )),
          );

    const deleteDone = (notify) =>
      (source: Actions<any>): Observable<any> =>
        source
          .pipe(
            ofType<any>('DELETE_' + euc + '_DONE'),
            tap(() => notify.showNotification('Der Eintrag wurde erfolgreich gelöscht.')),
            map(() => ({ type: 'FILTER_' + euc }))
          );

  return {
    get,
    filterMe,
    save,
    saveDone,
    deleteMe,
    deleteDone
  };
}
