import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from 'app/dialog/info-dialog/info-dialog.component';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) { }

  confirm(title: string, message: string, buttonLabelConfirm?: string, buttonLabelDeny?: string, color?: string): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;
    dialogRef = this.dialog.open(ConfirmDialogComponent);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.buttonTitleConfirm = buttonLabelConfirm ? buttonLabelConfirm : 'Bestätigen';
    dialogRef.componentInstance.buttonTitleDeny = buttonLabelDeny ? buttonLabelDeny : 'Abbrechen';
    dialogRef.componentInstance.color = color ? color : 'accent';

    return dialogRef.afterClosed();
  }

  info(title: string, message: string) {
    let dialogRef: MatDialogRef<InfoDialogComponent>;
    dialogRef = this.dialog.open(InfoDialogComponent);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
  }

  form<T>(component: any, content: any): Observable<false|T> {
    let dialogRef: MatDialogRef<typeof component>;
    dialogRef = this.dialog.open(component);

    dialogRef.componentInstance.setContent(content);

    return dialogRef.afterClosed();
  }
}
