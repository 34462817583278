import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterModule, RouterStateSnapshot } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ROUTES } from './integration-plan.routes';

import { integrationPlanReducer } from 'app/integration-plan/reducers/integration-plan.reducer';
import { IntegrationPlanEffects } from 'app/integration-plan/effects/integration-plan.effects';
import { IntegrationPlanFormComponent } from 'app/integration-plan/form/integration-plan-form.component';
import { MaterialModule } from 'app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IntegrationPlanInnerFormComponent } from './form/inner-form/integration-plan-inner-form.component';
import { IntegrationPlanPopupFormComponent } from './form/popup/integration-plan-popup-form.component';

const components = [
  IntegrationPlanFormComponent,
  IntegrationPlanPopupFormComponent,
  IntegrationPlanInnerFormComponent,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule.forChild(ROUTES),
    RouterModule,
    StoreModule.forFeature('integrationPlan', integrationPlanReducer),
    EffectsModule.forFeature([IntegrationPlanEffects])
  ],
  declarations: [...components],
  exports: [...components],
  entryComponents: [IntegrationPlanPopupFormComponent],
  providers: [
    {
      provide: 'CanDeactivateIntegrationPlan',
      useValue: (component: IntegrationPlanFormComponent, nextState: RouterStateSnapshot) => {
        return (component.innerForm.form.dirty && nextState.url)
          ? window.confirm('Sind Sie sicher, dass Sie das Formular verlassen möchten?'
            + ' Ungesicherte Änderungen gehen dabei verloren.')
          : true;
      }
    },
  ]
})
export class IntegrationPlanModule { }
