<mat-card class="title-card">
  <mat-card-header>
    <h1>Konfiguration</h1>
  </mat-card-header>
</mat-card>

<mat-card>
  <mat-card-content>
    <form [formGroup]="form">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <h3 class="form-title">Meldebericht</h3>
        </div>
        <div fxFlex="66%" fxLayout="column">
          <mat-form-field appearance="fill">
            <input matInput
              id="webDavUrl"
              formControlName="webDavUrl"
              placeholder="URL für Meldeberichte"
            >
            <mat-label>URL für Meldeberichte</mat-label>
          </mat-form-field>
        </div>
      </div>

      <div class="form-divider">
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <h3 class="form-title">Automatische Updates</h3>
        </div>
        <div fxFlex="66%" fxLayout="column">
          <mat-form-field appearance="fill">
            <input matInput
              id="updateBaseUrl"
              formControlName="updateBaseUrl"
              placeholder="URL für Anwendungsupdates"
            >
            <mat-label>URL für Anwendungsupdates</mat-label>
          </mat-form-field>
        </div>
      </div>

      <div class="form-divider">
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="33%" class="form-hint-area">
          <h3 class="form-title">Datensicherungen</h3>
        </div>
        <div fxFlex="66%" fxLayout="column">
          <mat-form-field appearance="fill">
            <input matInput
              id="backupsQuantity"
              formControlName="backupsQuantity"
              placeholder="Maximale Anzahl Sicherungen"
              type="number"
              min="0"
              max="1000"
            >
            <mat-label>Maximale Anzahl Sicherungen</mat-label>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <input matInput
              id="backupLocalTime"
              formControlName="backupLocalTime"
              placeholder="Zeitpunkt der Sicherung"
              type="time"
            >
            <mat-label>Zeitpunkt der Sicherung</mat-label>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <input matInput
              id="backupPath"
              formControlName="backupPath"
              placeholder="Ordner für Sicherungen"
            >
            <mat-label>Ordner für Sicherungen</mat-label>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-select multiple
              id="backupWeekDays"
              formControlName="backupWeekDays"
              placeholder="Tag der Sicherung"
              [value]="form.getRawValue().backupWeekDays"
              [compareWith]="matSelectHelper.compareByValue"
            >
              <mat-option *ngFor="let day of days" [value]="day"> {{ day.charAt(0).toUpperCase() + day.substr(1).toLowerCase() }} </mat-option>
            </mat-select>
            <mat-label>Tag der Sicherung</mat-label>
          </mat-form-field>

        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card class="button-card">
  <mat-card-content>
    <button mat-stroked-button
      color="primary"
      class="loading-button"
      (click)="createNewBackup()"
    >
      <mat-spinner *ngIf="creatingBackup$ | async" mode="indeterminate" diameter="15"></mat-spinner>
      Jetzt Datensicherung durchführen
    </button>
    <button mat-stroked-button
      color="primary"
      class="loading-button"
      (click)="showBackups()"
    >
      <mat-spinner *ngIf="restoring$ | async" mode="indeterminate" diameter="15"></mat-spinner>
      Alten Stand wiederherstellen
    </button>
    <button mat-raised-button
      color="primary"
      [disabled]="!this.form.valid"
      (click)="onSave()"
    >
      Speichern
    </button>
  </mat-card-content>
</mat-card>
