<div class="dialog">
  <h2>Prüfprotokoll für Träger: {{ (errorList$ | async).traegerID }}</h2>
  <p>Berichtszeitraum von: {{ (errorList$ | async).von | date }} bis {{ (errorList$ | async).bis | date }}</p>
  <mat-divider></mat-divider>

  <div *ngFor="let error of (errorList$ | async).fehler">
    <h5>
      {{ error.antrag === true ? 'Antrags-ID:' : 'Teilhabeplan-ID: ' }}
      {{ error.id }}
    </h5>
    <ul>
      <li *ngFor="let message of error.nachrichten">{{ message }}</li>
    </ul>
  </div>

  <div mat-dialog-actions class="button-container pull-right sticky-buttons">
    <button mat-stroked-button (click)="dialogRef.close()" color="accent">
      Schließen
    </button>
    <button mat-stroked-button
      class="loading-button"
      (click)="downloadErrorReport()"
      color="primary"
    >
      <mat-spinner *ngIf="downloading$ | async" mode="indeterminate" diameter="15"></mat-spinner>
      <i class="material-icons" *ngIf="!(downloading$ | async)">file_download</i>Als PDF exportieren
    </button>
  </div>
</div>
