import { DataSource } from '@angular/cdk/collections';
import { merge } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

export interface Responsibility {
  id?: string;
}

export enum States {
  BADEN_WUERTTEMBERG = 'Baden-Württemberg',
  BAYERN = 'Bayern',
  BERLIN = 'Berlin',
  BRANDENBURG = 'Brandenburg',
  BREMEN = 'Bremen',
  HAMBURG = 'Hamburg',
  HESSEN = 'Hessen',
  MECKLENBURG_VORPOMMERN = 'Mecklenburg-Vorpommern',
  NIEDERSACHSEN = 'Niedersachsen',
  NORDRHEIN_WESTFALEN = 'Nordrhein-Westfalen',
  RHEINLAND_PFALZ = 'Rheinland-Pfalz',
  SAARLAND = 'Saarland',
  SACHSEN = 'Sachsen',
  SACHSEN_ANHALT = 'Sachsen-Anhalt',
  SCHLESWIG_HOLSTEIN = 'Schleswig-Holstein',
  THUERINGEN = 'Thüringen'
}

export class ResponsibilityDataSource extends DataSource<any> {
  constructor(private data) {
    super();
  }

  connect(): Observable<any[]> {
    return merge(this.data).pipe(
      map(() => {
        return this.data.slice();
      })
    );
  }

  disconnect() {}
}
