import { LoginGuard } from 'app/login/login.guard';
import { UpdateFormComponent } from 'app/update/form/update-form.component';

const ROUTE_PREFIX = 'update';
export const ROUTES = [
  {
    path: `${ROUTE_PREFIX}`,
    component: UpdateFormComponent,
    canActivate: [LoginGuard]
  },
];
