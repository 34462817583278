import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  title: string;
  message: string;
  buttonTitleConfirm = 'Bestätigen';
  buttonTitleDeny = 'Abbrechen';
  color = 'accent';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {

  }
}
