import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { Observable } from 'rxjs/Observable';
import { configurationActions, CreateNewBackup } from '../actions/configuration.actions';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { configurationSelectors, selectLoading, selectCreatingBackup, selectRestoring } from '../reducers/configuration.reducer';
import * as _ from 'lodash';
import { MatSelectHelperService } from 'app/shared/matSelectHelper.service';
import { DialogService } from 'app/dialog/dialog.service';
import { BackupListComponent } from './backup-list/backup-list.component';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration-form.component.html',
  styleUrls: ['./configuration-form.component.scss']
})
export class ConfigurationFormComponent implements OnInit {
  form: FormGroup;
  filter: any;
  loading$: Observable<boolean>;
  configuration$: Observable<Configuration>;
  creatingBackup$: Observable<boolean>;
  restoring$: Observable<boolean>;
  curConfigurationObject: any;
  days = ['MONTAG', 'DIENSTAG', 'MITTWOCH', 'DONNERSTAG', 'FREITAG', 'SAMSTAG', 'SONNTAG'];

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private dialog: DialogService,
    public matSelectHelper: MatSelectHelperService,
  ) {
    this.form = this.fb.group({
      // Automatische Updates
      updateBaseUrl: '',
      // Datensicherungen
      backupsQuantity: ['', Validators.compose(
        [Validators.min(0), Validators.max(1000)]
      )],
      backupLocalTime: '',
      backupPath: '',
      backupWeekDays: '',
      webDavUrl: '',
    });
  }

  ngOnInit() {
    this.store.dispatch(configurationActions.get('unique'));
    this.configuration$ = this.store.select(configurationSelectors.selectCurrent);

    this.configuration$.pipe(
      filter(data => !!data))
      .subscribe(data => {
        this.form.reset(this.form.value);
        this.form.patchValue(data);
        this.curConfigurationObject = _.cloneDeep(data);
      });

    this.loading$ = this.store.select(selectLoading);
    this.creatingBackup$ = this.store.select(selectCreatingBackup);
    this.restoring$ = this.store.select(selectRestoring);
  }

  showBackups() {
    this.dialog.form(BackupListComponent, {});
  }

  onSave() {
    this.store.dispatch(configurationActions.save({ ...this.curConfigurationObject, ...this.form.value }));
  }

  createNewBackup(): void {
    this.store.dispatch(new CreateNewBackup());
  }

}
