<mat-card>
  <mat-progress-bar *ngIf="updating$ | async" mode="indeterminate"></mat-progress-bar>

  <mat-card-content>
    <p *ngIf="!(updating$ | async)">Wollen Sie die Anwendung jetzt aktualisieren?</p>
    <p *ngIf="updating$ | async">Warte auf Serververbindung. Bitte haben Sie einen Moment Geduld.</p>
  </mat-card-content>
</mat-card>

<mat-card class="button-card" *ngIf="!(updating$ | async)">
  <mat-card-content>
    <button mat-raised-button
      color="primary"
      (click)="triggerUpdate()"
    >
      Update starten
    </button>
  </mat-card-content>
</mat-card>
