import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'app/reducers';
import { selectLoggedIn, selectAuthorities } from 'app/login/reducers/login.reducer';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {

  @ViewChild('sidenav') sidenav: MatSidenav;
  public loggedIn$: Observable<boolean>;
  authorities$: Observable<string[]>;
  mode = 'side';
  opened = true;

  constructor(
    private store: Store<State>,
    private mediaObserver: MediaObserver
  ) {
    this.loggedIn$ = store.select(selectLoggedIn);
    this.mediaObserver.asObservable().subscribe(() => {
      this.mode = this.setSidenavMode();
      this.opened = this.setSidenavOpened();
    });
    this.authorities$ = this.store.select(selectAuthorities);
  }

  public isSmallScreen() {
    return (!this.mediaObserver.isActive('gt-md'));
  }

  private setSidenavMode(): string {
    return (this.isSmallScreen()) ? 'over' : 'side';
  }

  private setSidenavOpened() {
    return (this.isSmallScreen()) ? false : true;
  }

  public toggle() {
    this.sidenav.toggle();
  }

}
