<mat-card class="title-card">
  <mat-card-header>
    <h1>Teilhabepläne</h1>
  </mat-card-header>
</mat-card>

<mat-card>
  <mat-card-content>
    <app-integration-plan-inner></app-integration-plan-inner>
  </mat-card-content>
</mat-card>
