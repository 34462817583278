import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Gibt zurück, ob in der Form ein Fehler vorhanden ist.
 * Dabei wird überprüft, ob die Form die Eigenschaft invalid besitzt und
 * entweder dirty, touched or submitted ist
 *
 * @export
 * @class FormErrorStateMatcher
 * @implements {ErrorStateMatcher}
 */
export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
